import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const baseUrl: string = `${process.env.REACT_APP_API_URL}/api/v1`;
type Params = {
  start_date?: string | Date;
  end_date?: string | Date;
  priority: number | undefined;
  type: number | undefined;
};

export const alarmsApi = createApi({
  reducerPath: "alarm",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.user?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAlarms: builder.query<Alarms, Params>({
      query: ({ start_date, end_date, priority, type }) => ({
        url: `/alarms`,
        params: {
          start_date,
          end_date,
          priority,
          type,
        },
      }),
    }),
  }),
});

export const { useGetAlarmsQuery, useLazyGetAlarmsQuery } = alarmsApi;
