import {
  BellIcon,
  ChartBarIcon,
  ClipboardIcon,
  CloudIcon,
  CogIcon,
  DatabaseIcon,
  FlagIcon,
  FolderIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";

function Welcome() {
  return (
    <div className="min-h-screen  bg-gray-100 px-7 py-6">
      <h2 className="text-center text-3xl mb-10 font-semibold ">Logo</h2>
      <p className="text-4xl text-center mb-20">
        Welcome to Green Energy Park <br />
        <span className="text-green-600">SCADA</span> Platform
      </p>
      <div className="flex items-center  flex-grow px-10 flex-wrap gap-6 justify-center">
        <Card
          Icon={ClipboardIcon}
          style={"bg-orange-100 text-orange-600 p-5 h-20 rounded-full"}
          text="Plant's overview"
        />
        <Card
          Icon={CloudIcon}
          style={"bg-blue-100 text-blue-600 p-5 h-20 rounded-full"}
          text="Weather Station"
          isActive
          border="border-blue-600 rounded-md"
        />
        <Card
          Icon={BellIcon}
          style={"bg-green-100 text-green-600 p-5 h-20 rounded-full"}
          text="Alarm managment"
        />
        <Card
          Icon={CogIcon}
          style={"bg-purple-100 text-purple-600 p-5 h-20 rounded-full"}
          text="Plant's overview"
        />
        <Card
          Icon={ViewGridIcon}
          style={"bg-red-100 text-red-600 p-5 h-20 rounded-full"}
          text="Dashboard"
        />
        <Card
          Icon={DatabaseIcon}
          style={"bg-indigo-100 text-indigo-600 p-5 h-20 rounded-full"}
          text="Data analytics"
        />

        <Card
          Icon={FlagIcon}
          style={"bg-pink-100 text-pink-600 p-5 h-20 rounded-full"}
          text="Reporting"
        />
        <Card
          Icon={FolderIcon}
          style={"bg-yellow-100 text-yellow-600 p-5 h-20 rounded-full"}
          text="Data managment"
        />
        <Card
          Icon={ChartBarIcon}
          style={"bg-cyan-100 text-cyan-600 p-5 h-20 rounded-full"}
          text="Forcasting"
        />
      </div>
    </div>
  );
}

function Card({
  Icon,
  style,
  isActive,
  border,
  text,
}: {
  Icon: React.ElementType<any>;
  style: React.HTMLAttributes<HTMLDivElement>["className"];
  isActive?: boolean;
  border?: string;
  text: string;
}) {
  return (
    <div
      className={`bg-white py-4 cursor-pointer px-5 w-40 h-40 space-y-3 grid place-items-center ${
        isActive && "border-b-4  " + border
      }  `}>
      <Icon className={` h-24 ${style} `} />
      <p className="text-sm font-semibold">{text}</p>
    </div>
  );
}

export default Welcome;
