export const data = [
  {
    id: "667d73b1-4583-4080-ab6b-5759f25440bb",
    shape: "poly",
    name: "1",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5567, 3203, 5680, 3356, 5731, 3327, 5610, 3179],
    // 5567, 3203, 5680, 3356, 5731, 3327, 5610, 3179
  },
  {
    id: "a87203cb-3916-48ea-856f-2bacab8b7eda",
    title: "Floor",
    shape: "poly",
    name: "2",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5518, 3230, 5634, 3381, 5685, 3352, 5564, 3204],
  },
  {
    id: "37ed1569-1e68-4816-9033-1a88c53b39df",
    title: "Electrical Fixture",
    shape: "poly",
    name: "3",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5470, 3258, 5586, 3409, 5637, 3380, 5516, 3232],
  },
  {
    id: "ce471cbe-4103-45cc-899c-2be6497dc79a",
    title: "Electrical Fixture",
    shape: "poly",
    name: "4",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5424, 3286, 5540, 3437, 5591, 3408, 5470, 3260],
  },
  {
    id: "5fde0edd-4e1c-4130-9ee5-4ec6dfd34f46",
    title: "Electrical Fixture",
    shape: "poly",
    name: "5",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5375, 3313, 5491, 3464, 5542, 3435, 5421, 3287],
  },
  {
    id: "976082e0-0653-4e5d-8094-cc351e482e72",
    title: "Electrical Fixture",
    shape: "poly",
    name: "6",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5326, 3343, 5442, 3494, 5493, 3465, 5372, 3317],
  },
  {
    id: "cc3c2799-ce62-4236-b4f6-6f4b50e7b666",
    title: "GWB",
    shape: "poly",
    name: "7",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5278, 3371, 5394, 3522, 5445, 3493, 5324, 3345],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "8",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5225, 3399, 5341, 3550, 5392, 3521, 5271, 3373],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "9",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5174, 3431, 5290, 3582, 5341, 3553, 5220, 3405],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "10",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5122, 3461, 5238, 3612, 5289, 3583, 5168, 3435],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "11",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5069, 3492, 5185, 3643, 5236, 3614, 5115, 3466],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "12",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5009, 3521, 5129, 3674, 5181, 3645, 5063, 3493],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "13",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4955, 3553, 5075, 3706, 5127, 3677, 5009, 3525],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "14",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4897, 3586, 5017, 3739, 5069, 3710, 4951, 3558],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "15",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4837, 3619, 4957, 3772, 5012, 3742, 4893, 3589],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "16",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4782, 3654, 4902, 3807, 4957, 3777, 4838, 3624],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "17",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4721, 3687, 4841, 3840, 4896, 3810, 4777, 3657],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "18",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4662, 3716, 4784, 3881, 4842, 3847, 4718, 3686],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "19",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4597, 3751, 4719, 3916, 4777, 3882, 4653, 3721],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "20",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4536, 3788, 4658, 3953, 4716, 3919, 4592, 3758],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "21",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4471, 3825, 4593, 3990, 4651, 3956, 4532, 3789],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "22",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4391, 3861, 4466, 3820, 4598, 4003, 4515, 4044],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "23",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4323, 3885, 4399, 3851, 4529, 4028, 4453, 4069],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "24",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4267, 3934, 4343, 3900, 4473, 4077, 4397, 4118],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "25",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4194, 3966, 4269, 3932, 4403, 4119, 4327, 4160],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "26",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4124, 4008, 4199, 3974, 4333, 4161, 4257, 4202],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "27",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4054, 4050, 4129, 4016, 4263, 4203, 4187, 4244],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "28",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3982, 4085, 4057, 4044, 4187, 4249, 4105, 4297],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "29",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3914, 4126, 3989, 4085, 4126, 4290, 4030, 4311],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "30",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3839, 4167, 3914, 4126, 4035, 4304, 3948, 4331],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "31",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3722, 3983, 3801, 3931, 3921, 4133, 3845, 4181],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "32",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3792, 3941, 3871, 3889, 3991, 4091, 3915, 4139],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "33",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3862, 3899, 3941, 3853, 4061, 4049, 3985, 4097],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "34",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3932, 3864, 4011, 3818, 4131, 4014, 4055, 4062],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "35",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4009, 3822, 4088, 3776, 4208, 3972, 4132, 4020],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "36",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4072, 3787, 4153, 3749, 4271, 3937, 4195, 3985],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "37",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4142, 3752, 4223, 3714, 4341, 3902, 4265, 3950],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "38",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4212, 3717, 4293, 3679, 4411, 3867, 4335, 3915],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    title: "GWB",
    shape: "poly",
    name: "39",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4290, 3679, 4365, 3638, 4481, 3818, 4405, 3866],
  },
  {
    id: `cc3c2799-ce62-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "40",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4346, 3651, 4421, 3610, 4537, 3790, 4461, 3838],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "41",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4409, 3609, 4484, 3568, 4600, 3748, 4524, 3796],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "42",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4472, 3581, 4547, 3540, 4663, 3720, 4587, 3768],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "43",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4535, 3553, 4610, 3512, 4726, 3692, 4650, 3740],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "44",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4598, 3518, 4673, 3477, 4789, 3657, 4713, 3705],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "45",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4654, 3483, 4729, 3442, 4845, 3622, 4775, 3662],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "46",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4710, 3455, 4785, 3414, 4901, 3594, 4831, 3634],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "47",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4773, 3427, 4848, 3386, 4964, 3566, 4894, 3606],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "48",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4829, 3392, 4904, 3351, 5020, 3531, 4950, 3571],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "49",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4898, 3367, 4960, 3326, 5062, 3483, 5001, 3517],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "50",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4934, 3336, 5009, 3295, 5124, 3456, 5056, 3490],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "51",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4996, 3304, 5062, 3271, 5172, 3428, 5110, 3463],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "52",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5051, 3277, 5117, 3244, 5227, 3401, 5165, 3436],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "53",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5107, 3249, 5173, 3216, 5283, 3373, 5221, 3408],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "54",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5156, 3221, 5222, 3188, 5332, 3345, 5270, 3380],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "55",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5213, 3196, 5268, 3162, 5370, 3305, 5315, 3346],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "56",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5262, 3168, 5317, 3134, 5419, 3277, 5364, 3318],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "57",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5304, 3147, 5359, 3113, 5461, 3256, 5406, 3297, 5411, 3298],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "58",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5353, 3119, 5408, 3085, 5510, 3228, 5455, 3269, 5460, 3270],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "59",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5402, 3091, 5457, 3057, 5559, 3200, 5504, 3241, 5509, 3242],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "60",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [5444, 3070, 5499, 3036, 5601, 3179, 5546, 3220, 5551, 3221],
  },

  // * Avancis 2
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "61",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4659, 2757, 4721, 2729, 4837, 2873, 4775, 2907],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "62",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4610, 2771, 4672, 2743, 4788, 2887, 4726, 2921],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "63",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4554, 2786, 4616, 2758, 4732, 2902, 4670, 2936],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "64",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4512, 2814, 4574, 2786, 4690, 2930, 4628, 2964],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "65",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4463, 2842, 4525, 2814, 4641, 2958, 4579, 2992],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "66",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4414, 2863, 4476, 2835, 4592, 2979, 4530, 3013],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "67",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4372, 2877, 4434, 2849, 4550, 2993, 4488, 3027],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "68",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4316, 2898, 4378, 2870, 4494, 3014, 4432, 3048],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "69",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4268, 2918, 4330, 2890, 4446, 3034, 4384, 3068],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "70",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4212, 2939, 4274, 2911, 4390, 3055, 4328, 3089],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "71",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4156, 2960, 4218, 2932, 4334, 3076, 4272, 3110],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "72",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4100, 2988, 4162, 2960, 4278, 3104, 4216, 3138],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "73",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4051, 3009, 4113, 2981, 4229, 3125, 4167, 3159],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "74",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4002, 3037, 4064, 3009, 4180, 3153, 4118, 3187],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "75",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3939, 3058, 4001, 3030, 4117, 3174, 4055, 3208],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "76",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3876, 3086, 3948, 3056, 4054, 3202, 3992, 3236],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "77",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3821, 3107, 3893, 3077, 3999, 3223, 3937, 3257],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "78",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3758, 3135, 3830, 3105, 3936, 3251, 3874, 3285],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "79",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3703, 3152, 3765, 3127, 3871, 3273, 3805, 3302],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "80",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3639, 3191, 3711, 3161, 3817, 3307, 3755, 3341],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "81",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3586, 3207, 3648, 3182, 3754, 3328, 3688, 3357],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "82",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3521, 3236, 3583, 3211, 3689, 3357, 3623, 3386],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "83",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3458, 3264, 3524, 3230, 3626, 3385, 3560, 3414],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "84",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3395, 3292, 3461, 3258, 3563, 3413, 3497, 3442],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "85",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3325, 3313, 3391, 3279, 3493, 3434, 3427, 3463],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "86",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3255, 3341, 3321, 3307, 3423, 3462, 3353, 3504],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "87",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3192, 3369, 3258, 3335, 3360, 3490, 3290, 3532],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "88",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3122, 3397, 3188, 3363, 3290, 3518, 3220, 3560],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "89",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3052, 3418, 3118, 3384, 3220, 3539, 3150, 3581],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "90",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2989, 3453, 3055, 3419, 3157, 3574, 3087, 3616],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "91",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2895, 3298, 2970, 3271, 3066, 3428, 2991, 3463, 2991, 3463],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "92",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2963, 3271, 3045, 3237, 3134, 3408, 3052, 3442],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "93",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3026, 3243, 3108, 3209, 3197, 3380, 3115, 3414],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "94",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3107, 3210, 3177, 3188, 3271, 3353, 3196, 3381],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "95",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3169, 3182, 3239, 3160, 3333, 3325, 3258, 3353],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "96",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3232, 3154, 3302, 3132, 3396, 3297, 3321, 3325],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "97",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3302, 3126, 3372, 3104, 3466, 3269, 3391, 3297],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "98",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3372, 3105, 3442, 3083, 3536, 3248, 3461, 3276],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "99",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3442, 3088, 3505, 3055, 3599, 3220, 3531, 3245],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "100",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3498, 3060, 3561, 3027, 3655, 3192, 3587, 3217],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "101",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3554, 3039, 3620, 3010, 3715, 3153, 3643, 3196],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "102",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3617, 3018, 3683, 2989, 3778, 3132, 3706, 3175],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "103",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3680, 2990, 3746, 2961, 3841, 3104, 3777, 3137],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "104",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3736, 2962, 3802, 2933, 3897, 3076, 3833, 3109],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "105",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3792, 2948, 3858, 2919, 3953, 3062, 3889, 3095],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "106",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3854, 2920, 3920, 2891, 4015, 3034, 3951, 3067],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "107",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3910, 2906, 3976, 2877, 4071, 3020, 4007, 3053],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "108",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3959, 2885, 4025, 2856, 4120, 2999, 4056, 3032],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "109",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4022, 2864, 4088, 2835, 4183, 2978, 4119, 3011],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "110",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4071, 2843, 4137, 2814, 4232, 2957, 4168, 2990],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "111",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4127, 2815, 4193, 2786, 4288, 2929, 4224, 2962],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "112",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4183, 2794, 4249, 2765, 4344, 2908, 4280, 2941],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "113",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4238, 2773, 4304, 2744, 4399, 2887, 4335, 2920],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "114",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4280, 2752, 4346, 2723, 4441, 2866, 4377, 2899],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "115",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4335, 2731, 4401, 2702, 4496, 2845, 4432, 2878],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "116",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4399, 2718, 4461, 2691, 4545, 2824, 4481, 2857],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "117",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4434, 2697, 4496, 2670, 4591, 2796, 4529, 2831],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "118",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4476, 2676, 4538, 2649, 4633, 2775, 4571, 2810],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "119",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4525, 2655, 4587, 2628, 4682, 2754, 4620, 2789],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "120",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [4577, 2632, 4629, 2614, 4724, 2740, 4673, 2769],
  },

  // * Avancis 3
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "121",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3962, 2379, 4016, 2359, 4112, 2482, 4057, 2509],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "122",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3920, 2407, 3974, 2387, 4070, 2510, 4015, 2537],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "123",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3871, 2414, 3925, 2394, 4021, 2517, 3966, 2544],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "124",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3822, 2428, 3876, 2408, 3972, 2531, 3917, 2558],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "125",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3780, 2449, 3834, 2429, 3930, 2552, 3875, 2579],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "126",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3724, 2470, 3778, 2450, 3874, 2573, 3819, 2600],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "127",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3682, 2484, 3736, 2464, 3832, 2587, 3777, 2614],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "128",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3633, 2505, 3687, 2485, 3783, 2608, 3728, 2635],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "129",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3577, 2519, 3631, 2499, 3727, 2622, 3672, 2649],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "130",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3528, 2547, 3582, 2527, 3678, 2650, 3623, 2677],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "131",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3476, 2561, 3533, 2541, 3629, 2664, 3572, 2691],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "132",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3420, 2575, 3477, 2555, 3573, 2678, 3516, 2705],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "133",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3371, 2589, 3428, 2569, 3524, 2692, 3467, 2719],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "134",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3322, 2603, 3379, 2583, 3475, 2706, 3418, 2733],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "135",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3264, 2635, 3323, 2604, 3419, 2727, 3353, 2758],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "136",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3208, 2656, 3267, 2625, 3363, 2748, 3297, 2779],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "137",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3153, 2670, 3212, 2639, 3308, 2762, 3242, 2793],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "138",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3097, 2698, 3156, 2667, 3252, 2790, 3186, 2821],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "139",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3041, 2712, 3100, 2681, 3196, 2804, 3130, 2835],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "140",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2978, 2733, 3037, 2702, 3133, 2825, 3067, 2856],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "141",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2922, 2751, 2981, 2723, 3077, 2846, 3011, 2874],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "142",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2866, 2772, 2925, 2744, 3004, 2874, 2936, 2902],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "143",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2810, 2793, 2869, 2765, 2948, 2895, 2880, 2923],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "144",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2747, 2821, 2806, 2793, 2885, 2923, 2817, 2951],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "145",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2683, 2840, 2744, 2814, 2833, 2956, 2758, 2984],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "146",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2627, 2861, 2688, 2835, 2777, 2977, 2702, 3005],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "147",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2560, 2881, 2625, 2856, 2714, 2998, 2639, 3026],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "148",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2490, 2902, 2555, 2877, 2644, 3019, 2569, 3047],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "149",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2423, 2922, 2492, 2895, 2561, 3033, 2498, 3052],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "150",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2367, 2950, 2436, 2923, 2505, 3061, 2442, 3080],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "151",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2290, 2817, 2359, 2790, 2428, 2928, 2362, 2951],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "152",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2352, 2796, 2421, 2769, 2490, 2907, 2424, 2930],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "153",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2422, 2775, 2491, 2748, 2560, 2886, 2494, 2909],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "154",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2478, 2754, 2547, 2727, 2616, 2865, 2550, 2888],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "155",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2541, 2733, 2610, 2706, 2679, 2844, 2613, 2867],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "156",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2611, 2712, 2680, 2685, 2749, 2823, 2683, 2846],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "157",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2674, 2691, 2743, 2664, 2812, 2802, 2746, 2825],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "158",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2737, 2677, 2806, 2650, 2875, 2788, 2809, 2811],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "159",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2793, 2649, 2862, 2622, 2931, 2760, 2865, 2783],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "160",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2849, 2635, 2918, 2608, 2987, 2746, 2921, 2769],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "161",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2912, 2607, 2981, 2580, 3050, 2718, 2984, 2741],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "162",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2968, 2586, 3037, 2559, 3106, 2697, 3040, 2720],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "163",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3024, 2572, 3093, 2545, 3162, 2683, 3096, 2706],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "164",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3093, 2551, 3162, 2524, 3231, 2662, 3165, 2685],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "165",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3142, 2537, 3211, 2510, 3280, 2648, 3214, 2671],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "166",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3191, 2516, 3260, 2489, 3329, 2627, 3263, 2650],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "167",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3251, 2499, 3316, 2479, 3374, 2595, 3312, 2623],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "168",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3300, 2485, 3365, 2465, 3423, 2581, 3361, 2609],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "169",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3356, 2464, 3421, 2444, 3479, 2560, 3417, 2588],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "170",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3405, 2450, 3470, 2430, 3545, 2539, 3483, 2567],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "171",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3454, 2436, 3519, 2416, 3594, 2525, 3532, 2553],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "172",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3503, 2415, 3568, 2395, 3643, 2504, 3581, 2532],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "173",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3559, 2401, 3624, 2381, 3699, 2490, 3637, 2518],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "174",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3600, 2380, 3665, 2360, 3740, 2469, 3678, 2497],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "175",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3649, 2366, 3714, 2346, 3789, 2455, 3727, 2483],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "176",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3698, 2352, 3763, 2332, 3838, 2441, 3776, 2469],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "177",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3747, 2331, 3812, 2311, 3887, 2420, 3825, 2448],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "178",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3789, 2317, 3854, 2297, 3929, 2406, 3867, 2434],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "179",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3852, 2300, 3903, 2283, 3978, 2392, 3927, 2416],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "180",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3894, 2286, 3945, 2269, 4020, 2378, 3969, 2402],
  },

  // * Trina 1
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "181",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3394, 2101, 3449, 2081, 3524, 2183, 3469, 2204],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "182",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3345, 2122, 3400, 2102, 3475, 2204, 3420, 2225],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "183",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3281, 2136, 3336, 2116, 3411, 2218, 3356, 2239],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "184",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3232, 2157, 3287, 2137, 3362, 2239, 3307, 2260],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "185",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3175, 2163, 3237, 2142, 3305, 2252, 3244, 2272],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "186",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3126, 2184, 3188, 2163, 3256, 2273, 3195, 2293],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "187",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3063, 2205, 3125, 2184, 3193, 2294, 3132, 2314],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "188",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3000, 2219, 3062, 2198, 3130, 2308, 3069, 2328],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "189",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2944, 2233, 3006, 2212, 3074, 2322, 3013, 2342],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "190",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2888, 2251, 2950, 2233, 3018, 2343, 2957, 2363],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "191",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2825, 2265, 2887, 2247, 2955, 2357, 2894, 2377],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "192",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2769, 2286, 2831, 2268, 2899, 2378, 2838, 2398],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "193",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2699, 2300, 2761, 2282, 2829, 2392, 2768, 2412],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "194",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2636, 2321, 2698, 2303, 2766, 2413, 2705, 2433],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "195",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2580, 2342, 2642, 2324, 2710, 2434, 2649, 2454],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "196",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2517, 2363, 2579, 2345, 2647, 2455, 2586, 2475],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "197",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2447, 2377, 2509, 2359, 2577, 2469, 2516, 2489],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "198",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2377, 2398, 2439, 2380, 2507, 2490, 2446, 2510],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "199",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2314, 2412, 2376, 2394, 2451, 2513, 2382, 2534],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "200",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2252, 2315, 2321, 2294, 2389, 2411, 2314, 2431],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "201",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2315, 2294, 2384, 2273, 2452, 2390, 2377, 2410],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "202",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2385, 2280, 2454, 2259, 2522, 2376, 2447, 2396],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "203",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2448, 2259, 2517, 2238, 2585, 2355, 2510, 2375],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "204",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2518, 2238, 2587, 2217, 2655, 2334, 2580, 2354],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "205",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2574, 2224, 2643, 2203, 2710, 2313, 2636, 2340],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "206",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2637, 2203, 2706, 2182, 2773, 2292, 2699, 2319],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "207",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2700, 2189, 2769, 2168, 2836, 2278, 2762, 2305],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "208",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2763, 2168, 2832, 2147, 2899, 2257, 2825, 2284],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "209",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2817, 2157, 2888, 2138, 2950, 2227, 2884, 2252],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "210",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2882, 2133, 2951, 2112, 3018, 2222, 2944, 2249],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "211",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2938, 2119, 3007, 2098, 3074, 2208, 3000, 2235],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "212",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3001, 2105, 3070, 2084, 3134, 2192, 3066, 2212],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "213",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3050, 2091, 3119, 2070, 3183, 2178, 3115, 2198],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "214",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3113, 2070, 3182, 2049, 3246, 2157, 3178, 2177],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "215",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3169, 2056, 3237, 2038, 3302, 2143, 3234, 2163],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "216",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3230, 2038, 3298, 2018, 3351, 2122, 3283, 2142],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "217",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3279, 2024, 3339, 2010, 3408, 2099, 3346, 2119],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "218",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3321, 2010, 3394, 1995, 3456, 2084, 3388, 2105],
  },

  // * Trina 2
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "219",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2922, 1863, 2991, 1846, 3055, 1933, 2989, 1958],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "220",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2873, 1877, 2942, 1860, 3006, 1947, 2940, 1972],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "221",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2827, 1888, 2886, 1874, 2950, 1961, 2888, 1977],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "222",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2785, 1902, 2846, 1888, 2908, 1975, 2846, 1991],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "223",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2729, 1916, 2790, 1902, 2852, 1989, 2790, 2005],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "224",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2673, 1930, 2731, 1915, 2792, 2004, 2734, 2019, 2738, 2018],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "225",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2610, 1944, 2669, 1922, 2738, 2018, 2671, 2033, 2675, 2032],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "226",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2561, 1958, 2620, 1936, 2689, 2032, 2622, 2047, 2626, 2046],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "227",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2505, 1972, 2564, 1950, 2633, 2046, 2566, 2061, 2570, 2060],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "228",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2442, 1986, 2501, 1964, 2570, 2060, 2503, 2075, 2507, 2074],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "229",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2389, 1995, 2457, 1981, 2512, 2070, 2437, 2090],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "230",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2333, 2009, 2401, 1995, 2456, 2084, 2381, 2104],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "231",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2270, 2023, 2338, 2009, 2393, 2098, 2318, 2118],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "232",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2214, 2044, 2286, 2023, 2334, 2119, 2262, 2139],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "233",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2151, 2058, 2223, 2037, 2271, 2133, 2199, 2153],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "234",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2088, 2072, 2160, 2051, 2208, 2147, 2136, 2167],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "235",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2025, 2093, 2097, 2072, 2157, 2170, 2088, 2190],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "236",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1962, 2107, 2034, 2086, 2094, 2184, 2025, 2204],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "237",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1906, 2121, 1978, 2100, 2038, 2198, 1969, 2218],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "238",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1850, 2030, 1922, 2009, 1982, 2107, 1913, 2127],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "239",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1913, 2016, 1985, 1995, 2045, 2093, 1976, 2113],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "240",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1976, 2002, 2048, 1981, 2108, 2079, 2039, 2099],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "241",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2039, 1981, 2111, 1960, 2171, 2058, 2102, 2078],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "242",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2102, 1967, 2177, 1952, 2234, 2044, 2165, 2064],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "243",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2158, 1953, 2233, 1938, 2290, 2030, 2221, 2050],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "244",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2218, 1945, 2282, 1924, 2339, 2016, 2270, 2036],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "245",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2281, 1931, 2345, 1910, 2402, 2002, 2333, 2022],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "246",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2337, 1917, 2401, 1896, 2458, 1988, 2389, 2008],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "247",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2400, 1903, 2464, 1882, 2521, 1974, 2452, 1994],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "248",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2449, 1889, 2513, 1868, 2570, 1960, 2501, 1980],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "249",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2505, 1875, 2569, 1854, 2626, 1946, 2557, 1966],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "250",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2568, 1861, 2632, 1840, 2689, 1932, 2620, 1952],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "251",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2617, 1847, 2681, 1826, 2738, 1918, 2669, 1938],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "252",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2673, 1833, 2738, 1822, 2794, 1904, 2725, 1924],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "253",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2722, 1819, 2787, 1808, 2843, 1890, 2774, 1910],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "254",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2778, 1805, 2843, 1794, 2899, 1876, 2830, 1896],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "255",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2827, 1798, 2892, 1787, 2948, 1869, 2879, 1889],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "256",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2876, 1785, 2941, 1774, 2997, 1856, 2936, 1867],
  },

  // * Trina 3
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "257",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2546, 1662, 2608, 1649, 2663, 1731, 2608, 1744],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "258",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2490, 1676, 2552, 1663, 2607, 1745, 2552, 1758],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "259",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2448, 1690, 2510, 1677, 2565, 1759, 2510, 1772],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "260",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2392, 1704, 2454, 1691, 2509, 1773, 2451, 1783],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "261",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2343, 1711, 2405, 1698, 2460, 1780, 2402, 1790],
  },
  // * ---- Bug ------
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "262",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2293, 1724, 2355, 1703, 2416, 1792, 2355, 1806],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "263",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2245, 1739, 2307, 1726, 2362, 1808, 2304, 1818],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "264",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2189, 1753, 2251, 1740, 2306, 1822, 2248, 1832],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "265",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2133, 1767, 2195, 1754, 2250, 1836, 2192, 1846],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "266",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2081, 1769, 2149, 1755, 2208, 1843, 2143, 1851],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "267",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2025, 1783, 2088, 1768, 2152, 1857, 2087, 1865],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "268",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1969, 1797, 2032, 1782, 2096, 1871, 2031, 1879],
  },
  // * ------ Bug 2 --------
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "269",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1922, 1815, 1984, 1794, 2040, 1882, 1972, 1896],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "270",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1857, 1825, 1920, 1810, 1984, 1899, 1919, 1907],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "271",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1794, 1832, 1857, 1817, 1921, 1906, 1856, 1922],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "272",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1738, 1846, 1808, 1826, 1865, 1920, 1800, 1936],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "273",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1682, 1860, 1752, 1840, 1809, 1934, 1744, 1950],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "274",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1626, 1874, 1696, 1854, 1753, 1948, 1688, 1964],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "275",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1570, 1888, 1640, 1868, 1697, 1962, 1623, 1977],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "276",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1533, 1808, 1596, 1792, 1644, 1874, 1582, 1888],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "277",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1589, 1801, 1652, 1785, 1700, 1867, 1638, 1881],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "278",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1645, 1787, 1708, 1771, 1756, 1853, 1694, 1867],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "279",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1701, 1773, 1764, 1757, 1812, 1839, 1750, 1853],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "280",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1757, 1759, 1820, 1743, 1868, 1825, 1806, 1839],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "281",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1820, 1745, 1883, 1729, 1931, 1811, 1869, 1825],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "282",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1869, 1738, 1932, 1722, 1980, 1804, 1918, 1818],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "283",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1925, 1724, 1988, 1708, 2036, 1790, 1974, 1804],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "284",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1981, 1710, 2044, 1694, 2092, 1776, 2030, 1790],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "285",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2037, 1696, 2100, 1680, 2148, 1762, 2086, 1776],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "286",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2093, 1682, 2156, 1666, 2204, 1748, 2142, 1762],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "287",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2142, 1675, 2205, 1659, 2253, 1741, 2192, 1758],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "288",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2191, 1661, 2254, 1645, 2302, 1727, 2241, 1744],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "289",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2247, 1647, 2310, 1631, 2358, 1713, 2297, 1730],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "290",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2296, 1640, 2359, 1624, 2407, 1706, 2346, 1723],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "291",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2345, 1626, 2408, 1610, 2456, 1692, 2395, 1709],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "292",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2394, 1619, 2457, 1603, 2505, 1685, 2444, 1702],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "293",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2450, 1605, 2513, 1589, 2561, 1671, 2500, 1688],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "294",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2506, 1598, 2560, 1586, 2615, 1661, 2560, 1675],
  },

  // * Trina Half cut 1
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "295",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1939, 1518, 2000, 1505, 2034, 1559, 1973, 1573],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "296",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1882, 1526, 1943, 1512, 1984, 1574, 1916, 1587],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "297",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1826, 1540, 1887, 1526, 1928, 1588, 1860, 1601],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "298",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1756, 1554, 1817, 1540, 1858, 1602, 1790, 1615],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "299",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1693, 1561, 1754, 1547, 1795, 1609, 1727, 1622],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "300",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1644, 1575, 1705, 1561, 1746, 1623, 1678, 1636],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "301",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1610, 1525, 1671, 1512, 1705, 1566, 1644, 1580],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "302",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1670, 1512, 1727, 1498, 1768, 1552, 1704, 1567],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "303",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1733, 1505, 1790, 1491, 1831, 1545, 1767, 1560],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "304",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1791, 1490, 1846, 1477, 1887, 1531, 1819, 1546],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "305",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1854, 1476, 1909, 1463, 1950, 1517, 1882, 1532],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "306",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1910, 1469, 1965, 1456, 2006, 1510, 1938, 1524],
  },

  // * Trina Half cut 1
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "307",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2232, 1297, 2293, 1283, 2341, 1365, 2280, 1379],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "308",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2183, 1304, 2244, 1290, 2292, 1372, 2231, 1386],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "309",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2127, 1311, 2188, 1297, 2236, 1379, 2175, 1393],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "310",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2064, 1325, 2125, 1311, 2173, 1393, 2112, 1407],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "311",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2001, 1332, 2062, 1318, 2110, 1400, 2049, 1414],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "312",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1945, 1339, 2006, 1325, 2054, 1407, 1993, 1421],
  },
  // * Bug -----
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "313",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1972, 1186, 2033, 1172, 2081, 1254, 2020, 1268],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "314",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1924, 1199, 1985, 1185, 2033, 1267, 1972, 1281],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "315",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1868, 1206, 1929, 1192, 1977, 1274, 1916, 1288],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "316",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1812, 1213, 1873, 1199, 1921, 1281, 1860, 1295],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "317",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1756, 1220, 1817, 1206, 1865, 1288, 1804, 1302],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "318",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1700, 1227, 1761, 1213, 1809, 1295, 1748, 1309],
  },

  // * Weird 1
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "319",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1793, 1067, 1752, 1074, 1799, 1163, 1840, 1149],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "320",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1744, 1081, 1703, 1088, 1750, 1177, 1791, 1163],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "321",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1702, 1095, 1661, 1102, 1708, 1191, 1749, 1177],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "322",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1653, 1102, 1612, 1109, 1659, 1198, 1700, 1184],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "323",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1604, 1102, 1563, 1109, 1610, 1198, 1651, 1184, 1608, 1104],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "324",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1562, 1109, 1521, 1116, 1568, 1205, 1609, 1191, 1566, 1111],
  },

  // * Weird 2
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "325",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1575, 969, 1534, 975, 1596, 1071, 1630, 1058, 1630, 1058],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "326",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1526, 976, 1485, 982, 1547, 1078, 1581, 1065, 1581, 1065],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "327",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1491, 990, 1450, 996, 1512, 1092, 1546, 1079, 1546, 1079],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "328",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1449, 997, 1408, 1003, 1470, 1099, 1504, 1086, 1504, 1086],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "329",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1400, 1004, 1359, 1010, 1421, 1106, 1455, 1093],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "330",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1358, 1004, 1317, 1010, 1379, 1106, 1413, 1093],
  },

  // * Calyxo 1

  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "331",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2472, 1368, 2432, 1379, 2446, 1407, 2486, 1394],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "332",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2551, 1355, 2498, 1366, 2512, 1394, 2563, 1381],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "333",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2603, 1342, 2550, 1353, 2565, 1381, 2616, 1368],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "334",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2669, 1329, 2616, 1340, 2631, 1368, 2681, 1355],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "335",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2722, 1315, 2669, 1327, 2683, 1355, 2734, 1342],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "336",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2788, 1315, 2734, 1327, 2749, 1355, 2800, 1342],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "337",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2840, 1302, 2787, 1314, 2802, 1342, 2852, 1328],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "338",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2906, 1289, 2853, 1300, 2867, 1328, 2918, 1315],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "339",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2958, 1276, 2905, 1287, 2920, 1315, 2971, 1302],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "340",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3009, 1263, 2958, 1274, 2973, 1302, 3023, 1289], // 3024,1276 2971,1287 2986,1315 3036,1302
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "341",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3077, 1250, 3024, 1261, 3038, 1289, 3089, 1276],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "342",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3129, 1250, 3076, 1261, 3091, 1289, 3142, 1276],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "343",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3182, 1236, 3129, 1248, 3144, 1276, 3194, 1263],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "344",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3235, 1223, 3181, 1235, 3196, 1263, 3247, 1249],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "345",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3287, 1223, 3234, 1235, 3249, 1263, 3300, 1249],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "346",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3340, 1210, 3287, 1221, 3301, 1250, 3352, 1236],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "347",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3406, 1197, 3352, 1208, 3367, 1236, 3418, 1223],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "348",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3432, 1184, 3379, 1195, 3393, 1223, 3444, 1210],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "349",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3485, 1184, 3431, 1195, 3446, 1223, 3497, 1210],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "350",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3537, 1171, 3484, 1182, 3499, 1210, 3549, 1197],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "351",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3590, 1158, 3537, 1169, 3551, 1197, 3602, 1184],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "352",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3591, 1171, 3630, 1157, 3643, 1184, 3600, 1195],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "353",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3641, 1148, 3667, 1183, 3715, 1174, 3687, 1137],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "354",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3670, 1118, 3617, 1131, 3630, 1157, 3670, 1144],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "355",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3617, 1131, 3578, 1144, 3591, 1171, 3630, 1157],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "356",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3564, 1131, 3525, 1144, 3538, 1171, 3578, 1157],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "357",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3511, 1144, 3472, 1157, 3485, 1184, 3525, 1171],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "358",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3459, 1157, 3420, 1171, 3433, 1197, 3472, 1184],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "359",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3406, 1171, 3367, 1184, 3380, 1210, 3420, 1197],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "360",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3367, 1171, 3328, 1184, 3341, 1210, 3380, 1197],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "361",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3314, 1184, 3275, 1197, 3288, 1223, 3328, 1210],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "362",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3261, 1197, 3222, 1210, 3236, 1236, 3275, 1223],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "363",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3209, 1210, 3170, 1223, 3183, 1250, 3222, 1236],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "364",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3156, 1210, 3117, 1223, 3130, 1250, 3170, 1236],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "365",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3104, 1223, 3065, 1236, 3078, 1263, 3117, 1250],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "366",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3051, 1236, 3012, 1250, 3025, 1276, 3065, 1263],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "367",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2985, 1250, 2946, 1263, 2959, 1289, 2999, 1276],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "368",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2933, 1263, 2894, 1276, 2907, 1302, 2946, 1289],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "369",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2880, 1263, 2841, 1276, 2854, 1302, 2894, 1289],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "370",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2827, 1276, 2788, 1289, 2802, 1315, 2841, 1302],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "371",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2762, 1289, 2723, 1302, 2736, 1328, 2775, 1315],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "372",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2709, 1302, 2670, 1315, 2683, 1342, 2723, 1328],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "373",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2643, 1315, 2604, 1328, 2617, 1355, 2657, 1342],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "374",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2577, 1315, 2538, 1328, 2552, 1355, 2591, 1342],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "375",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2525, 1328, 2486, 1342, 2499, 1368, 2538, 1355],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "376",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2459, 1342, 2420, 1355, 2433, 1381, 2473, 1368],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "377",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2446, 1315, 2407, 1328, 2420, 1355, 2460, 1342],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "378",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2512, 1302, 2473, 1315, 2486, 1342, 2525, 1328],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "379",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2564, 1289, 2525, 1302, 2538, 1328, 2578, 1315],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "380",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2630, 1289, 2591, 1302, 2604, 1328, 2644, 1315],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "381",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2683, 1276, 2644, 1289, 2657, 1315, 2696, 1302],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "382",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2748, 1263, 2709, 1276, 2723, 1302, 2762, 1289],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "383",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2801, 1250, 2762, 1263, 2775, 1289, 2815, 1276],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "384",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2854, 1236, 2815, 1250, 2828, 1276, 2867, 1263],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "385",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2919, 1236, 2880, 1250, 2894, 1276, 2933, 1263],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "386",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2972, 1223, 2933, 1236, 2946, 1263, 2986, 1250],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "387",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3025, 1210, 2986, 1223, 2999, 1250, 3038, 1236],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "388",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3077, 1210, 3038, 1223, 3051, 1250, 3091, 1236],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "389",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3143, 1197, 3104, 1210, 3117, 1236, 3157, 1223],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "390",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3196, 1184, 3157, 1197, 3170, 1223, 3209, 1210],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "391",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3248, 1171, 3209, 1184, 3222, 1210, 3262, 1197],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "392",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3301, 1157, 3262, 1171, 3275, 1197, 3314, 1184],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "393",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3340, 1157, 3301, 1171, 3314, 1197, 3354, 1184],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "394",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3393, 1144, 3354, 1157, 3367, 1184, 3407, 1171],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "395",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3446, 1131, 3407, 1144, 3420, 1171, 3459, 1157],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "396",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3498, 1131, 3459, 1144, 3472, 1171, 3512, 1157],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "397",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3551, 1118, 3512, 1131, 3525, 1157, 3564, 1144],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "398",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3590, 1105, 3551, 1118, 3564, 1144, 3604, 1131],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "399",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3643, 1105, 3604, 1118, 3617, 1144, 3656, 1131],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "400",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3630, 1079, 3591, 1092, 3604, 1118, 3643, 1105],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "401",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3590, 1092, 3551, 1105, 3564, 1131, 3604, 1118],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "402",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3538, 1105, 3499, 1118, 3512, 1144, 3551, 1131],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "403",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3485, 1105, 3446, 1118, 3459, 1144, 3499, 1131],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "404",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3432, 1118, 3393, 1131, 3407, 1157, 3446, 1144],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "405",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3393, 1131, 3354, 1144, 3367, 1171, 3407, 1157],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "406",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3340, 1131, 3301, 1144, 3314, 1171, 3354, 1157],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "407",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3288, 1144, 3249, 1157, 3262, 1184, 3301, 1171],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "408",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3235, 1157, 3196, 1171, 3209, 1197, 3249, 1184],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "409",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3183, 1171, 3142, 1182, 3157, 1210, 3196, 1197],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "410",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3130, 1171, 3091, 1184, 3104, 1210, 3144, 1197],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "411",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3077, 1184, 3038, 1197, 3051, 1223, 3091, 1210],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "412",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3025, 1197, 2986, 1210, 2999, 1236, 3038, 1223],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "413",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2972, 1197, 2933, 1210, 2946, 1236, 2986, 1223],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "414",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2906, 1210, 2866, 1221, 2880, 1250, 2920, 1236],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "415",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2854, 1223, 2813, 1235, 2828, 1263, 2867, 1250],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "416",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2788, 1236, 2747, 1248, 2762, 1276, 2802, 1263],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "417",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2735, 1250, 2695, 1261, 2709, 1289, 2749, 1276],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "418",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2670, 1250, 2629, 1261, 2644, 1289, 2683, 1276],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "419",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2617, 1263, 2576, 1274, 2591, 1302, 2631, 1289],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "420",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2551, 1276, 2511, 1287, 2525, 1315, 2565, 1302],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "421",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2485, 1289, 2445, 1300, 2460, 1328, 2499, 1315],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "422",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2420, 1302, 2379, 1314, 2394, 1342, 2433, 1328],
  },

  // * CH_Uncleaned
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "423",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2169, 1274, 2215, 1267, 2227, 1290, 2182, 1297],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "424",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2220, 1266, 2266, 1259, 2278, 1282, 2233, 1289],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "425",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2267, 1257, 2313, 1250, 2325, 1273, 2280, 1280],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "426",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2314, 1247, 2360, 1240, 2372, 1263, 2327, 1270],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "427",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2363, 1239, 2409, 1232, 2421, 1255, 2376, 1262],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "428",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2411, 1231, 2457, 1224, 2469, 1247, 2424, 1254],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "429",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2457, 1221, 2503, 1214, 2515, 1237, 2470, 1244],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "430",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2519, 1204, 2565, 1197, 2577, 1220, 2532, 1227],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "431",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2575, 1196, 2621, 1189, 2633, 1212, 2588, 1219],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "432",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2633, 1186, 2679, 1179, 2691, 1202, 2646, 1209],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "433",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2685, 1178, 2731, 1171, 2743, 1194, 2698, 1201],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "434",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2740, 1169, 2786, 1162, 2798, 1185, 2753, 1192],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "435",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2793, 1161, 2839, 1154, 2851, 1177, 2806, 1184],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "436",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2844, 1151, 2890, 1144, 2902, 1167, 2857, 1174],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "437",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2897, 1143, 2943, 1136, 2955, 1159, 2910, 1166],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "438",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2948, 1135, 2994, 1128, 3006, 1151, 2961, 1158],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "439",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2999, 1125, 3045, 1118, 3057, 1141, 3012, 1148],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "440",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3051, 1116, 3097, 1109, 3109, 1132, 3064, 1139],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "441",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3096, 1109, 3142, 1102, 3154, 1125, 3109, 1132],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "442",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3146, 1101, 3192, 1094, 3204, 1117, 3159, 1124],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "443",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3195, 1092, 3241, 1085, 3253, 1108, 3208, 1115],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "444",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3245, 1085, 3291, 1078, 3303, 1101, 3258, 1108],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "445",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3290, 1074, 3336, 1067, 3348, 1090, 3303, 1097],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "446",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3341, 1067, 3388, 1060, 3399, 1083, 3354, 1090],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "447",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3321, 1047, 3368, 1040, 3382, 1063, 3334, 1070],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "448",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3274, 1056, 3321, 1049, 3335, 1072, 3287, 1079],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "449",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3228, 1063, 3275, 1056, 3289, 1079, 3241, 1086],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "450",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3179, 1071, 3226, 1064, 3240, 1087, 3192, 1094],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "451",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3131, 1078, 3178, 1071, 3192, 1094, 3144, 1101],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "452",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3082, 1086, 3129, 1079, 3143, 1102, 3095, 1109],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "453",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3032, 1093, 3079, 1086, 3093, 1109, 3045, 1116],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "454",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2981, 1105, 3028, 1098, 3042, 1121, 2994, 1128],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "455",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2933, 1113, 2980, 1106, 2994, 1129, 2946, 1136],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "456",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2880, 1123, 2927, 1116, 2941, 1139, 2893, 1146],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "457",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2826, 1129, 2873, 1122, 2887, 1145, 2839, 1152],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "458",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2775, 1137, 2822, 1130, 2836, 1153, 2788, 1160],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "459",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2723, 1146, 2770, 1139, 2784, 1162, 2736, 1169],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "460",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2669, 1156, 2716, 1149, 2730, 1172, 2682, 1179],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "461",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2615, 1164, 2662, 1157, 2676, 1180, 2628, 1187],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "462",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2558, 1172, 2605, 1165, 2619, 1188, 2571, 1195],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "463",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2505, 1182, 2552, 1175, 2566, 1198, 2518, 1205],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "464",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2443, 1198, 2490, 1191, 2504, 1214, 2456, 1221],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "465",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2394, 1207, 2441, 1200, 2455, 1223, 2407, 1230],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "466",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2348, 1216, 2395, 1209, 2409, 1232, 2361, 1239],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "467",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2299, 1226, 2346, 1219, 2360, 1242, 2312, 1249],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "468",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2251, 1235, 2298, 1228, 2312, 1251, 2264, 1258],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "469",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2201, 1241, 2248, 1234, 2262, 1257, 2214, 1264],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "470",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2154, 1250, 2201, 1243, 2215, 1266, 2167, 1273],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "471",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2139, 1226, 2186, 1219, 2200, 1242, 2152, 1249],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "472",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2188, 1216, 2235, 1209, 2249, 1232, 2201, 1239],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "473",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2237, 1208, 2284, 1201, 2298, 1224, 2250, 1231],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "474",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2286, 1201, 2333, 1194, 2347, 1217, 2299, 1224],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "475",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2334, 1192, 2381, 1185, 2395, 1208, 2347, 1215],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "476",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2383, 1185, 2430, 1178, 2444, 1201, 2396, 1208],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "477",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2429, 1174, 2476, 1167, 2490, 1190, 2442, 1197],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "478",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2492, 1160, 2539, 1153, 2553, 1176, 2505, 1183],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "479",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2545, 1152, 2592, 1145, 2606, 1168, 2558, 1175],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "480",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2602, 1143, 2649, 1136, 2663, 1159, 2615, 1166],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "481",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2653, 1133, 2700, 1126, 2714, 1149, 2666, 1156],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "482",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2709, 1126, 2756, 1119, 2770, 1142, 2722, 1149],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "483",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2761, 1118, 2808, 1111, 2822, 1134, 2774, 1141],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "484",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2815, 1109, 2862, 1102, 2876, 1125, 2828, 1132],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "485",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2864, 1101, 2911, 1094, 2925, 1117, 2877, 1124],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "486",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2918, 1092, 2965, 1085, 2979, 1108, 2931, 1115],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "487",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2967, 1084, 3014, 1077, 3028, 1100, 2980, 1107],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "488",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3019, 1075, 3066, 1068, 3080, 1091, 3032, 1098],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "489",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3065, 1066, 3112, 1059, 3126, 1082, 3078, 1089],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "490",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3118, 1058, 3165, 1051, 3179, 1074, 3131, 1081],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "491",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3169, 1052, 3216, 1045, 3230, 1068, 3182, 1075],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "492",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3215, 1042, 3262, 1035, 3276, 1058, 3228, 1065],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "493",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3264, 1036, 3311, 1029, 3325, 1052, 3277, 1059],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "494",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3310, 1028, 3357, 1021, 3371, 1044, 3323, 1051],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "495",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3294, 1009, 3341, 1002, 3355, 1025, 3307, 1032],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "496",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3248, 1014, 3295, 1007, 3309, 1030, 3261, 1037],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "497",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3199, 1021, 3246, 1014, 3260, 1037, 3212, 1044],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "498",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3152, 1028, 3199, 1021, 3213, 1044, 3165, 1051],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "499",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3100, 1037, 3147, 1030, 3161, 1053, 3113, 1060],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "500",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3052, 1044, 3099, 1037, 3113, 1060, 3065, 1067],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "501",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3004, 1052, 3051, 1045, 3065, 1068, 3017, 1075],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "502",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2955, 1061, 3002, 1054, 3016, 1077, 2968, 1084],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "503",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2902, 1069, 2949, 1062, 2963, 1085, 2915, 1092],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "504",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2849, 1077, 2896, 1070, 2910, 1093, 2862, 1100],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "505",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2800, 1086, 2847, 1079, 2861, 1102, 2813, 1109],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "506",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2748, 1095, 2795, 1088, 2809, 1111, 2761, 1118],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "507",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2694, 1104, 2741, 1097, 2755, 1120, 2707, 1127],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "508",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2638, 1111, 2685, 1104, 2699, 1127, 2651, 1134],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "509",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2588, 1120, 2635, 1113, 2649, 1136, 2601, 1143],
  },

  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "510",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2529, 1129, 2576, 1122, 2590, 1145, 2542, 1152],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "511",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2478, 1138, 2525, 1131, 2539, 1154, 2491, 1161],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "512",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2421, 1149, 2468, 1142, 2482, 1165, 2434, 1172],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "513",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2370, 1161, 2417, 1154, 2431, 1177, 2383, 1184],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "514",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2324, 1169, 2371, 1162, 2385, 1185, 2337, 1192],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "515",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2272, 1171, 2319, 1164, 2333, 1187, 2285, 1194],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "516",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2225, 1181, 2272, 1174, 2286, 1197, 2238, 1204],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "517",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2173, 1187, 2220, 1180, 2234, 1203, 2186, 1210],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "518",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2119, 1195, 2166, 1188, 2180, 1211, 2132, 1218],
  },

  // * CH Water cleaned
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "519",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1932, 1159, 1976, 1153, 1990, 1176, 1945, 1183],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "520",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1982, 1154, 2026, 1148, 2040, 1171, 1995, 1178],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "521",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2028, 1148, 2072, 1142, 2086, 1165, 2041, 1172],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "522",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2077, 1142, 2121, 1136, 2135, 1159, 2090, 1166],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "523",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2122, 1133, 2166, 1127, 2180, 1150, 2135, 1157],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "524",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2169, 1124, 2213, 1118, 2227, 1141, 2182, 1148],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "525",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2219, 1118, 2263, 1112, 2277, 1135, 2232, 1142],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "526",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2279, 1107, 2323, 1101, 2337, 1124, 2292, 1131],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "527",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2333, 1098, 2377, 1092, 2391, 1115, 2346, 1122],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "528",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2385, 1088, 2429, 1082, 2443, 1105, 2398, 1112],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "529",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2439, 1081, 2483, 1075, 2497, 1098, 2452, 1105],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "530",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2491, 1071, 2535, 1065, 2549, 1088, 2504, 1095],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "531",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2543, 1064, 2587, 1058, 2601, 1081, 2556, 1088],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "532",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2592, 1058, 2636, 1052, 2650, 1075, 2605, 1082],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "533",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2644, 1050, 2688, 1044, 2702, 1067, 2657, 1074],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "534",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2691, 1042, 2735, 1036, 2749, 1059, 2704, 1066],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "535",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2741, 1034, 2785, 1028, 2799, 1051, 2754, 1058],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "536",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2792, 1027, 2836, 1021, 2850, 1044, 2805, 1051],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "537",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2838, 1019, 2882, 1013, 2896, 1036, 2851, 1043],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "538",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2890, 1012, 2934, 1006, 2948, 1029, 2903, 1036],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "539",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2940, 1004, 2984, 998, 2998, 1021, 2953, 1028],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "540",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2985, 1000, 3029, 994, 3043, 1017, 2998, 1024],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "541",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3032, 992, 3076, 986, 3090, 1009, 3045, 1016],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "542",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3077, 983, 3121, 977, 3135, 1000, 3090, 1007],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "543",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3056, 967, 3101, 961, 3113, 981, 3068, 988],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "544",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3013, 973, 3058, 967, 3070, 987, 3025, 994],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "545",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2970, 980, 3015, 974, 3027, 994, 2982, 1001],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "546",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2924, 988, 2969, 982, 2981, 1002, 2936, 1009],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "547",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2874, 993, 2919, 987, 2931, 1007, 2886, 1014],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "548",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2824, 1002, 2869, 996, 2881, 1016, 2836, 1023],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "549",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2779, 1007, 2824, 1001, 2836, 1021, 2791, 1028],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "550",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2730, 1015, 2775, 1009, 2787, 1029, 2742, 1036],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "551",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2679, 1021, 2724, 1015, 2736, 1035, 2691, 1042],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "552",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2631, 1031, 2676, 1025, 2688, 1045, 2643, 1052],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "553",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2580, 1037, 2625, 1031, 2637, 1051, 2592, 1058],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "554",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2530, 1046, 2575, 1040, 2587, 1060, 2542, 1067],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "555",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2477, 1054, 2522, 1048, 2534, 1068, 2489, 1075],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "556",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2424, 1062, 2469, 1056, 2481, 1076, 2436, 1083],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "557",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2372, 1071, 2417, 1065, 2429, 1085, 2384, 1092],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "558",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2318, 1077, 2363, 1071, 2375, 1091, 2330, 1098],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "559",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2265, 1085, 2310, 1079, 2322, 1099, 2277, 1106],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "560",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2202, 1099, 2247, 1093, 2259, 1113, 2214, 1120],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "561",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2156, 1107, 2201, 1101, 2213, 1121, 2168, 1128],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "562",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2109, 1113, 2154, 1107, 2166, 1127, 2121, 1134],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "563",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2065, 1121, 2110, 1115, 2122, 1135, 2077, 1142],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "564",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2016, 1129, 2061, 1123, 2073, 1143, 2028, 1150],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "565",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1967, 1136, 2012, 1130, 2024, 1150, 1979, 1157],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "566",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1924, 1141, 1969, 1135, 1981, 1155, 1936, 1162],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "567",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1912, 1119, 1957, 1113, 1969, 1133, 1924, 1140],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "568",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1959, 1116, 2004, 1110, 2016, 1130, 1971, 1137],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "569",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2009, 1105, 2054, 1099, 2066, 1119, 2021, 1126],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "570",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2052, 1100, 2097, 1094, 2109, 1114, 2064, 1121],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "571",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2099, 1092, 2144, 1086, 2156, 1106, 2111, 1113],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "572",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2145, 1085, 2190, 1079, 2202, 1099, 2157, 1106],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "573",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2200, 1076, 2245, 1070, 2257, 1090, 2212, 1097],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "574",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2252, 1065, 2297, 1059, 2309, 1079, 2264, 1086],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "575",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2305, 1057, 2350, 1051, 2362, 1071, 2317, 1078],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "576",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2360, 1049, 2405, 1043, 2417, 1063, 2372, 1070],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "577",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2410, 1041, 2455, 1035, 2467, 1055, 2422, 1062],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "578",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2463, 1033, 2508, 1027, 2520, 1047, 2475, 1054],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "579",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2515, 1025, 2560, 1019, 2572, 1039, 2527, 1046],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "580",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2566, 1018, 2611, 1012, 2623, 1032, 2578, 1039],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "581",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2618, 1011, 2663, 1005, 2675, 1025, 2630, 1032],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "582",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2668, 1003, 2713, 997, 2725, 1017, 2680, 1024],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "583",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2715, 997, 2760, 991, 2772, 1011, 2727, 1018],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "584",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2764, 990, 2809, 984, 2821, 1004, 2776, 1011],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "585",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2814, 983, 2859, 977, 2871, 997, 2826, 1004],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "586",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2861, 975, 2906, 969, 2918, 989, 2873, 996],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "587",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2912, 969, 2957, 963, 2969, 983, 2924, 990],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "588",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2961, 961, 3006, 955, 3018, 975, 2973, 982],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "589",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3002, 956, 3047, 950, 3059, 970, 3014, 977],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "590",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3047, 947, 3092, 941, 3104, 961, 3059, 968],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "591",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [3035, 929, 3080, 923, 3092, 943, 3047, 950],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "592",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2987, 935, 3032, 929, 3044, 949, 2999, 956],
  },
  // * ---
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "593",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2947, 942, 2992, 936, 3004, 956, 2959, 963],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "594",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2896, 948, 2941, 942, 2953, 962, 2908, 969],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "595",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2846, 954, 2891, 948, 2903, 968, 2858, 975],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "596",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2797, 962, 2842, 956, 2854, 976, 2809, 983],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "597",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2752, 968, 2797, 962, 2809, 982, 2764, 989],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "598",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2703, 975, 2748, 969, 2760, 989, 2715, 996],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "599",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2651, 984, 2696, 978, 2708, 998, 2663, 1005],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "600",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2604, 992, 2649, 986, 2661, 1006, 2616, 1013],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "601",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2554, 998, 2599, 992, 2611, 1012, 2566, 1019],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "602",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2503, 1006, 2548, 1000, 2560, 1020, 2515, 1027],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "603",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2451, 1013, 2496, 1007, 2508, 1027, 2463, 1034],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "604",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2397, 1022, 2442, 1016, 2454, 1036, 2409, 1043],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "605",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2347, 1031, 2392, 1025, 2404, 1045, 2359, 1052],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "606",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2292, 1035, 2337, 1029, 2349, 1049, 2304, 1056],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "607",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2240, 1046, 2285, 1040, 2297, 1060, 2252, 1067],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "608",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2185, 1053, 2230, 1047, 2242, 1067, 2197, 1074],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "609",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2137, 1061, 2182, 1055, 2194, 1075, 2149, 1082],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "610",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2086, 1068, 2131, 1062, 2143, 1082, 2098, 1089],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "611",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2042, 1076, 2087, 1070, 2099, 1090, 2054, 1097],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "612",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1996, 1082, 2041, 1076, 2053, 1096, 2008, 1103],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "613",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1947, 1088, 1992, 1082, 2004, 1102, 1959, 1109],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "614",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1899, 1095, 1944, 1089, 1956, 1109, 1911, 1116],
  },

  // * Solar Wash protect
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "615",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1752, 1059, 1794, 1053, 1805, 1071, 1763, 1078],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "616",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1795, 1058, 1837, 1052, 1848, 1070, 1806, 1077],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "617",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1845, 1055, 1887, 1049, 1898, 1067, 1856, 1074],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "618",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1892, 1047, 1934, 1041, 1945, 1059, 1903, 1066],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "619",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1934, 1043, 1976, 1037, 1987, 1055, 1945, 1062],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "620",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1980, 1039, 2022, 1033, 2033, 1051, 1991, 1058],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "621",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2028, 1033, 2070, 1027, 2081, 1045, 2039, 1052],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "622",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2076, 1024, 2118, 1018, 2129, 1036, 2087, 1043],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "623",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2120, 1017, 2162, 1011, 2173, 1029, 2131, 1036],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "624",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2163, 1012, 2205, 1006, 2216, 1024, 2174, 1031],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "625",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2207, 1008, 2249, 1002, 2260, 1020, 2218, 1027],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "626",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2251, 1004, 2293, 998, 2304, 1016, 2262, 1023],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "627",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2292, 997, 2334, 991, 2345, 1009, 2303, 1016],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "628",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2334, 989, 2376, 983, 2387, 1001, 2345, 1008],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "629",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2374, 983, 2416, 977, 2427, 995, 2385, 1002],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "630",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2416, 979, 2458, 973, 2469, 991, 2427, 998],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "631",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2458, 973, 2500, 967, 2511, 985, 2469, 992],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "632",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2499, 966, 2541, 960, 2552, 978, 2510, 985],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "633",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2537, 962, 2579, 956, 2590, 974, 2548, 981],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "634",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2579, 958, 2621, 952, 2632, 970, 2590, 977],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "635",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2619, 954, 2661, 948, 2672, 966, 2630, 973],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "636",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2657, 948, 2699, 942, 2710, 960, 2668, 967],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "637",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2695, 943, 2737, 937, 2748, 955, 2706, 962],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "638",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2735, 937, 2777, 931, 2788, 949, 2746, 956],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "639",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2774, 934, 2816, 928, 2827, 946, 2785, 953],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "640",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2808, 925, 2850, 919, 2861, 937, 2819, 944],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "641",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2850, 920, 2892, 914, 2903, 932, 2861, 939],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "642",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2829, 893, 2871, 887, 2882, 905, 2840, 912],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "643",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2790, 900, 2832, 894, 2843, 912, 2801, 919],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "644",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2755, 906, 2797, 900, 2808, 918, 2766, 925],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "645",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2718, 914, 2760, 908, 2771, 926, 2729, 933],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "646",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2679, 919, 2721, 913, 2732, 931, 2690, 938],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "647",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2640, 925, 2682, 919, 2693, 937, 2651, 944],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "648",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2601, 930, 2643, 924, 2654, 942, 2612, 949],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "649",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2562, 936, 2604, 930, 2615, 948, 2573, 955],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "650",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2525, 942, 2567, 936, 2578, 954, 2536, 961],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "651",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2482, 948, 2524, 942, 2535, 960, 2493, 967],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "652",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2443, 955, 2485, 949, 2496, 967, 2454, 974],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "653",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2405, 961, 2447, 955, 2458, 973, 2416, 980],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "654",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2363, 968, 2405, 962, 2416, 980, 2374, 987],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "655",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2322, 973, 2364, 967, 2375, 985, 2333, 992],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "656",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2280, 980, 2322, 974, 2333, 992, 2291, 999],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "657",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2237, 986, 2279, 980, 2290, 998, 2248, 1005],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "658",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2195, 991, 2237, 985, 2248, 1003, 2206, 1010],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "659",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2154, 998, 2196, 992, 2207, 1010, 2165, 1017],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "660",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2110, 1002, 2152, 996, 2163, 1014, 2121, 1021],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "661",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2065, 1009, 2107, 1003, 2118, 1021, 2076, 1028],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "662",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2016, 1014, 2058, 1008, 2069, 1026, 2027, 1033],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "663",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1970, 1021, 2012, 1015, 2023, 1033, 1981, 1040],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "664",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1926, 1029, 1968, 1023, 1979, 1041, 1937, 1048],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "665",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1880, 1033, 1922, 1027, 1933, 1045, 1891, 1052],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "666",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1834, 1039, 1876, 1033, 1887, 1051, 1845, 1058],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "667",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1789, 1047, 1831, 1041, 1842, 1059, 1800, 1066],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "668",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1742, 1049, 1784, 1043, 1795, 1061, 1753, 1068],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "669",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1735, 1028, 1778, 1023, 1788, 1041, 1746, 1047],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "670",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1778, 1022, 1821, 1017, 1831, 1035, 1789, 1041],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "671",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1824, 1014, 1867, 1009, 1877, 1027, 1835, 1033],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "672",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1869, 1009, 1912, 1004, 1922, 1022, 1880, 1028],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "673",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1913, 1003, 1956, 998, 1966, 1016, 1924, 1022],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "674",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1958, 997, 2001, 992, 2011, 1010, 1969, 1016],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "675",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2004, 991, 2047, 986, 2057, 1004, 2015, 1010],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "676",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2048, 979, 2091, 974, 2101, 992, 2059, 998],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "677",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2091, 973, 2134, 968, 2144, 986, 2102, 992],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "678",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2134, 966, 2177, 961, 2187, 979, 2145, 985],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "679",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2176, 960, 2219, 955, 2229, 973, 2187, 979],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "680",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2221, 957, 2264, 952, 2274, 970, 2232, 976],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "681",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2261, 950, 2304, 945, 2314, 963, 2272, 969],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "682",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2304, 944, 2347, 939, 2357, 957, 2315, 963],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "683",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2344, 937, 2387, 932, 2397, 950, 2355, 956],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "684",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2384, 931, 2427, 926, 2437, 944, 2395, 950],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "685",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2425, 925, 2468, 920, 2478, 938, 2436, 944],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "686",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2466, 921, 2509, 916, 2519, 934, 2477, 940],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "687",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2509, 917, 2552, 912, 2562, 930, 2520, 936],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "688",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2547, 911, 2590, 906, 2600, 924, 2558, 930],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "689",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2585, 906, 2628, 901, 2638, 919, 2596, 925],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "690",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2624, 901, 2667, 896, 2677, 914, 2635, 920],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "691",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2664, 895, 2707, 890, 2717, 908, 2675, 914],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "692",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2701, 890, 2744, 885, 2754, 903, 2712, 909],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "693",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2740, 884, 2783, 879, 2793, 897, 2751, 903],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "694",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2776, 880, 2819, 875, 2829, 893, 2787, 899],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "695",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2818, 877, 2861, 872, 2871, 890, 2829, 896],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "696",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2802, 854, 2845, 849, 2855, 867, 2813, 873],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "697",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2764, 860, 2807, 855, 2817, 873, 2775, 879],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "698",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2726, 866, 2769, 861, 2779, 879, 2737, 885],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "699",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2687, 869, 2730, 864, 2740, 882, 2698, 888],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "700",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2648, 874, 2691, 869, 2701, 887, 2659, 893],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "701",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2609, 878, 2652, 873, 2662, 891, 2620, 897],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "702",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2571, 882, 2614, 877, 2624, 895, 2582, 901],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "703",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2531, 888, 2574, 883, 2584, 901, 2542, 907],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "704",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2491, 895, 2534, 890, 2544, 908, 2502, 914],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "705",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2451, 902, 2494, 897, 2504, 915, 2462, 921],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "706",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2414, 909, 2457, 904, 2467, 922, 2425, 928],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "707",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2374, 916, 2417, 911, 2427, 929, 2385, 935],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "708",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2334, 923, 2377, 918, 2387, 936, 2345, 942],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "709",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2292, 927, 2335, 922, 2345, 940, 2303, 946],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "710",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2250, 933, 2293, 928, 2303, 946, 2261, 952],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "711",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2210, 938, 2253, 933, 2263, 951, 2221, 957],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "712",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2167, 947, 2210, 942, 2220, 960, 2178, 966],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "713",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2123, 949, 2166, 944, 2176, 962, 2134, 968],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "714",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2080, 955, 2123, 950, 2133, 968, 2091, 974],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "715",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2037, 958, 2080, 953, 2090, 971, 2048, 977],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "716",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1991, 966, 2034, 961, 2044, 979, 2002, 985],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "717",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1948, 971, 1991, 966, 2001, 984, 1959, 990],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "718",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1906, 978, 1949, 973, 1959, 991, 1917, 997],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "719",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1858, 988, 1901, 983, 1911, 1001, 1869, 1007],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "720",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1816, 993, 1859, 988, 1869, 1006, 1827, 1012],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "721",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1767, 1000, 1810, 995, 1820, 1013, 1778, 1019],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "722",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1722, 1004, 1765, 999, 1775, 1017, 1733, 1023],
  },

  // * D-Solar defender
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "723",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1563, 977, 1606, 972, 1616, 990, 1574, 996],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "724",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1609, 974, 1652, 969, 1662, 987, 1620, 993],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "725",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1654, 970, 1697, 965, 1707, 983, 1665, 989],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "726",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1698, 965, 1741, 960, 1751, 978, 1709, 984],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "727",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1743, 960, 1786, 955, 1796, 973, 1754, 979],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "728",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1786, 957, 1829, 952, 1839, 970, 1797, 976],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "729",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1829, 948, 1872, 943, 1882, 961, 1840, 967],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "730",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1876, 939, 1919, 934, 1929, 952, 1887, 958],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "731",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1919, 935, 1962, 930, 1972, 948, 1930, 954],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "732",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1962, 932, 2005, 927, 2015, 945, 1973, 951],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "733",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2004, 927, 2047, 922, 2057, 940, 2015, 946],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "734",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2043, 921, 2086, 916, 2096, 934, 2054, 940],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "735",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2084, 917, 2127, 912, 2137, 930, 2095, 936],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "736",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2123, 910, 2166, 905, 2176, 923, 2134, 929],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "737",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2165, 905, 2208, 900, 2218, 918, 2176, 924],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "738",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2204, 900, 2247, 895, 2257, 913, 2215, 919],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "739",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2242, 895, 2285, 890, 2295, 908, 2253, 914],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "740",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2283, 891, 2326, 886, 2336, 904, 2294, 910],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "741",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2320, 886, 2363, 881, 2373, 899, 2331, 905],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "742",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2360, 880, 2403, 875, 2413, 893, 2371, 899],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "743",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2397, 875, 2440, 870, 2450, 888, 2408, 894],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "744",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2436, 870, 2479, 865, 2489, 883, 2447, 889],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "745",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2475, 866, 2518, 861, 2528, 879, 2486, 885],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "746",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2512, 862, 2555, 857, 2565, 875, 2523, 881],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "747",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2551, 858, 2594, 853, 2604, 871, 2562, 877],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "748",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2584, 853, 2627, 848, 2637, 866, 2595, 872],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "749",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2624, 847, 2667, 842, 2677, 860, 2635, 866],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "750",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2611, 830, 2654, 825, 2664, 843, 2622, 849],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "751",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2574, 837, 2617, 832, 2627, 850, 2585, 856],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "752",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2536, 841, 2579, 836, 2589, 854, 2547, 860],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "753",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2498, 845, 2541, 840, 2551, 858, 2509, 864],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "754",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2460, 850, 2503, 845, 2513, 863, 2471, 869],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "755",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2422, 853, 2465, 848, 2475, 866, 2433, 872],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "756",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2384, 858, 2427, 853, 2437, 871, 2395, 877],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "757",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2347, 863, 2390, 858, 2400, 876, 2358, 882],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "758",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2308, 866, 2351, 861, 2361, 879, 2319, 885],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "759",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2270, 871, 2313, 866, 2323, 884, 2281, 890],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "760",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2231, 876, 2274, 871, 2284, 889, 2242, 895],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "761",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2191, 881, 2234, 876, 2244, 894, 2202, 900],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "762",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2150, 887, 2193, 882, 2203, 900, 2161, 906],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "763",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2112, 892, 2155, 887, 2165, 905, 2123, 911],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "764",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2070, 896, 2113, 891, 2123, 909, 2081, 915],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "765",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2030, 900, 2073, 895, 2083, 913, 2041, 919],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "766",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1988, 904, 2031, 899, 2041, 917, 1999, 923],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "767",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1947, 909, 1990, 904, 2000, 922, 1958, 928],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "768",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1904, 914, 1947, 909, 1957, 927, 1915, 933],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "769",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1863, 920, 1906, 915, 1916, 933, 1874, 939],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "770",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1817, 927, 1860, 922, 1870, 940, 1828, 946],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "771",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1774, 933, 1817, 928, 1827, 946, 1785, 952],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "772",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1732, 938, 1775, 933, 1785, 951, 1743, 957],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "773",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1687, 944, 1730, 939, 1740, 957, 1698, 963],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "774",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1642, 948, 1685, 943, 1695, 961, 1653, 967],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "775",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1597, 953, 1640, 948, 1650, 966, 1608, 972],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "776",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1557, 958, 1600, 953, 1610, 971, 1568, 977],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "777",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1552, 943, 1595, 938, 1605, 956, 1563, 962],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "778",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1591, 937, 1634, 932, 1644, 950, 1602, 956],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "779",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1635, 931, 1678, 926, 1688, 944, 1646, 950],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "780",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1679, 929, 1722, 924, 1732, 942, 1690, 948],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "781",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1724, 923, 1767, 918, 1777, 936, 1735, 942],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "782",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1766, 918, 1809, 913, 1819, 931, 1777, 937],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "783",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1810, 912, 1853, 907, 1863, 925, 1821, 931],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "784",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1852, 899, 1895, 894, 1905, 912, 1863, 918],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "785",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1894, 893, 1937, 888, 1947, 906, 1905, 912],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "786",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1936, 891, 1979, 886, 1989, 904, 1947, 910],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "787",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1975, 883, 2018, 878, 2028, 896, 1986, 902],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "788",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2016, 877, 2059, 872, 2069, 890, 2027, 896],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "789",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2058, 873, 2101, 868, 2111, 886, 2069, 892],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "790",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2098, 869, 2141, 864, 2151, 882, 2109, 888],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "791",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2137, 864, 2180, 859, 2190, 877, 2148, 883],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "792",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2179, 860, 2222, 855, 2232, 873, 2190, 879],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "793",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2218, 855, 2261, 850, 2271, 868, 2229, 874],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "794",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2256, 850, 2299, 845, 2309, 863, 2267, 869],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "795",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2296, 845, 2339, 840, 2349, 858, 2307, 864],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "796",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2332, 841, 2375, 836, 2385, 854, 2343, 860],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "798",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2372, 838, 2415, 833, 2425, 851, 2383, 857],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "799",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2411, 834, 2454, 829, 2464, 847, 2422, 853],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "800",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2449, 829, 2492, 824, 2502, 842, 2460, 848],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "801",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2486, 824, 2529, 819, 2539, 837, 2497, 843],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "802",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2522, 820, 2565, 815, 2575, 833, 2533, 839],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "801",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2560, 815, 2603, 810, 2613, 828, 2571, 834],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "803",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2597, 812, 2640, 807, 2650, 825, 2608, 831],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "804",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2585, 796, 2628, 791, 2638, 809, 2596, 815],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "805",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2549, 799, 2592, 794, 2602, 812, 2560, 818],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "806",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2511, 802, 2554, 797, 2564, 815, 2522, 821],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "807",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2474, 806, 2517, 801, 2527, 819, 2485, 825],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "808",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2435, 810, 2478, 805, 2488, 823, 2446, 829],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "809",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2399, 817, 2442, 812, 2452, 830, 2410, 836],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "810",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2361, 823, 2404, 818, 2414, 836, 2372, 842],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "811",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2323, 827, 2366, 822, 2376, 840, 2334, 846],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "812",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2285, 831, 2328, 826, 2338, 844, 2296, 850],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "813",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2246, 837, 2289, 832, 2299, 850, 2257, 856],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "814",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2207, 841, 2250, 836, 2260, 854, 2218, 860],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "815",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2167, 844, 2210, 839, 2220, 857, 2178, 863],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "816",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2127, 848, 2170, 843, 2180, 861, 2138, 867],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "817",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2087, 853, 2130, 848, 2140, 866, 2098, 872],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "818",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2047, 858, 2090, 853, 2100, 871, 2058, 877],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "819",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2006, 862, 2049, 857, 2059, 875, 2017, 881],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "820",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1965, 867, 2008, 862, 2018, 880, 1976, 886],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "821",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1923, 872, 1966, 867, 1976, 885, 1934, 891],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "822",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1882, 876, 1925, 871, 1935, 889, 1893, 895],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "823",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1843, 881, 1886, 876, 1896, 894, 1854, 900],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "824",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1799, 888, 1842, 883, 1852, 901, 1810, 907],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "825",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1756, 891, 1799, 886, 1809, 904, 1767, 910],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "826",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1710, 897, 1753, 892, 1763, 910, 1721, 916],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "827",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1666, 902, 1709, 897, 1719, 915, 1677, 921],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "828",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1626, 907, 1669, 902, 1679, 920, 1637, 926],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "829",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1580, 912, 1623, 907, 1633, 925, 1591, 931],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "830",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1533, 918, 1576, 913, 1586, 931, 1544, 937],
  },

  // * IGP
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "831",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1404, 912, 1447, 907, 1457, 925, 1415, 931],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "832",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1442, 910, 1485, 905, 1495, 923, 1453, 929],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "833",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1487, 905, 1530, 900, 1540, 918, 1498, 924],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "834",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1530, 901, 1573, 896, 1583, 914, 1541, 920],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "835",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1573, 896, 1616, 891, 1626, 909, 1584, 915],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "836",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1611, 892, 1654, 887, 1664, 905, 1622, 911],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "837",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1652, 887, 1695, 882, 1705, 900, 1663, 906],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "838",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1698, 878, 1741, 873, 1751, 891, 1709, 897],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "839",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1739, 872, 1782, 867, 1792, 885, 1750, 891],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "840",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1780, 868, 1823, 863, 1833, 881, 1791, 887],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "841",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1821, 864, 1864, 859, 1874, 877, 1832, 883],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "842",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1860, 858, 1903, 853, 1913, 871, 1871, 877],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "843",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1900, 855, 1943, 850, 1953, 868, 1911, 874],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "844",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1940, 850, 1983, 845, 1993, 863, 1951, 869],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "845",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1979, 846, 2022, 841, 2032, 859, 1990, 865],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "846",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2017, 840, 2060, 835, 2070, 853, 2028, 859],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "847",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2055, 837, 2098, 832, 2108, 850, 2066, 856],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "848",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2093, 832, 2136, 827, 2146, 845, 2104, 851],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "849",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2130, 826, 2173, 821, 2183, 839, 2141, 845],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "850",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2169, 823, 2212, 818, 2222, 836, 2180, 842],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "851",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2206, 819, 2249, 814, 2259, 832, 2217, 838],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "852",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2242, 814, 2285, 809, 2295, 827, 2253, 833],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "853",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2278, 809, 2321, 804, 2331, 822, 2289, 828],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "854",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2316, 806, 2359, 801, 2369, 819, 2327, 825],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "855",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2352, 802, 2395, 797, 2405, 815, 2363, 821],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "856",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2388, 798, 2431, 793, 2441, 811, 2399, 817],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "857",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2425, 794, 2468, 789, 2478, 807, 2436, 813],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "858",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2417, 774, 2460, 769, 2470, 787, 2428, 793],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "859",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2374, 778, 2417, 773, 2427, 791, 2385, 797],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "860",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2338, 783, 2381, 778, 2391, 796, 2349, 802],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "861",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2303, 786, 2346, 781, 2356, 799, 2314, 805],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "862",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2266, 791, 2309, 786, 2319, 804, 2277, 810],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "863",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2229, 795, 2272, 790, 2282, 808, 2240, 814],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "864",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2193, 799, 2236, 794, 2246, 812, 2204, 818],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "865",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2156, 803, 2199, 798, 2209, 816, 2167, 822],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "866",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2117, 807, 2160, 802, 2170, 820, 2128, 826],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "867",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2080, 811, 2123, 806, 2133, 824, 2091, 830],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "868",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2042, 815, 2085, 810, 2095, 828, 2053, 834],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "869",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2004, 819, 2047, 814, 2057, 832, 2015, 838],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "870",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1964, 824, 2007, 819, 2017, 837, 1975, 843],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "871",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1925, 827, 1968, 822, 1978, 840, 1936, 846],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "872",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1885, 832, 1928, 827, 1938, 845, 1896, 851],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "873",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1846, 835, 1889, 830, 1899, 848, 1857, 854],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "874",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1806, 840, 1849, 835, 1859, 853, 1817, 859],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "875",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1766, 844, 1809, 839, 1819, 857, 1777, 863],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "876",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1726, 851, 1769, 846, 1779, 864, 1737, 870],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "877",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1685, 854, 1728, 849, 1738, 867, 1696, 873],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "878",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1642, 859, 1685, 854, 1695, 872, 1653, 878],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "879",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1602, 864, 1645, 859, 1655, 877, 1613, 883],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "880",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1557, 869, 1600, 864, 1610, 882, 1568, 888],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "881",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1519, 875, 1562, 870, 1572, 888, 1530, 894],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "882",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1475, 880, 1518, 875, 1528, 893, 1486, 899],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "883",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1433, 885, 1476, 880, 1486, 898, 1444, 904],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "884",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1392, 890, 1435, 885, 1445, 903, 1403, 909],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "885",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1386, 870, 1429, 865, 1439, 883, 1397, 889],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "886",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1424, 868, 1465, 862, 1475, 880, 1436, 887],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "887",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1468, 863, 1509, 857, 1519, 875, 1480, 882],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "888",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1513, 858, 1554, 852, 1564, 870, 1525, 877],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "889",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1554, 853, 1595, 847, 1605, 865, 1566, 872],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "890",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1594, 850, 1635, 844, 1645, 862, 1606, 869],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "891",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1633, 844, 1674, 838, 1684, 856, 1645, 863],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "892",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1675, 833, 1716, 827, 1726, 845, 1687, 852],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "893",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1713, 826, 1755, 821, 1765, 840, 1725, 845],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "894",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1754, 823, 1796, 818, 1806, 837, 1766, 842],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "895",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1793, 818, 1835, 813, 1845, 832, 1805, 837],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "896",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1834, 816, 1876, 811, 1886, 830, 1846, 835],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "897",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1874, 810, 1916, 805, 1926, 824, 1886, 829],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "898",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1912, 806, 1954, 801, 1964, 820, 1924, 825],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "899",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1951, 802, 1993, 797, 2003, 816, 1963, 821],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "900",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1991, 799, 2033, 794, 2043, 813, 2003, 818],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "901",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2029, 795, 2071, 790, 2081, 809, 2041, 814],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "902",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2067, 790, 2109, 785, 2119, 804, 2079, 809],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "903",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2107, 787, 2149, 782, 2159, 801, 2119, 806],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "904",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2142, 782, 2184, 777, 2194, 796, 2154, 801],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "905",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2180, 779, 2222, 774, 2232, 793, 2192, 798],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "906",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2217, 774, 2259, 769, 2269, 788, 2229, 793],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "907",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2254, 771, 2296, 766, 2306, 785, 2266, 790],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "908",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2291, 767, 2333, 762, 2343, 781, 2303, 786],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "909",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2326, 763, 2368, 758, 2378, 777, 2338, 782],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "910",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2363, 760, 2405, 755, 2415, 774, 2375, 779],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "911",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2401, 757, 2443, 752, 2453, 771, 2413, 776],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "912",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2388, 738, 2430, 733, 2440, 752, 2400, 757],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "913",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2352, 741, 2394, 736, 2404, 755, 2364, 760],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "914",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2316, 743, 2358, 738, 2368, 757, 2328, 762],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "915",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2281, 747, 2323, 742, 2333, 761, 2293, 766],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "916",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2245, 751, 2287, 746, 2297, 765, 2257, 770],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "917",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2206, 754, 2248, 749, 2258, 768, 2218, 773],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "918",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2170, 758, 2212, 753, 2222, 772, 2182, 777],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "919",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2132, 763, 2174, 758, 2184, 777, 2144, 782],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "920",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2095, 768, 2137, 763, 2147, 782, 2107, 787],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "921",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2057, 772, 2099, 767, 2109, 786, 2069, 791],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "922",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [2020, 776, 2062, 771, 2072, 790, 2032, 795],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "923",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1980, 779, 2022, 774, 2032, 793, 1992, 798],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "924",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1942, 785, 1984, 780, 1994, 799, 1954, 804],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "925",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1902, 788, 1944, 783, 1954, 802, 1914, 807],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "926",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1862, 791, 1904, 786, 1914, 805, 1874, 810],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "927",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1823, 795, 1865, 790, 1875, 809, 1835, 814],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "928",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1783, 800, 1825, 795, 1835, 814, 1795, 819],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "929",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1743, 803, 1785, 798, 1795, 817, 1755, 822],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "930",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1703, 807, 1745, 802, 1755, 821, 1715, 826],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "931",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1664, 812, 1706, 807, 1716, 826, 1676, 831],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "932",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1617, 817, 1659, 812, 1669, 831, 1629, 836],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "933",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1584, 821, 1626, 816, 1636, 835, 1596, 840],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "934",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1542, 826, 1584, 821, 1594, 840, 1554, 845],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "935",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1498, 833, 1540, 828, 1550, 847, 1510, 852],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "936",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1458, 840, 1500, 835, 1510, 854, 1470, 859],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "937",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1414, 845, 1456, 840, 1466, 859, 1426, 864],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "938",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1374, 851, 1416, 846, 1426, 865, 1386, 870],
  },

  // * Circulaire
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "939",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1805, 710, 1840, 706, 1858, 749, 1824, 755],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "940",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1768, 713, 1803, 709, 1821, 752, 1787, 758],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "941",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1730, 717, 1765, 713, 1783, 756, 1749, 762],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "942",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1692, 720, 1727, 716, 1745, 759, 1711, 765],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "943",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1654, 723, 1689, 719, 1707, 762, 1673, 768],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "944",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1616, 728, 1651, 724, 1669, 767, 1635, 773],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "945",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1577, 730, 1612, 726, 1630, 769, 1596, 775],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "946",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1538, 737, 1573, 733, 1591, 776, 1557, 782],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "947",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1497, 740, 1532, 736, 1550, 779, 1516, 785],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "948",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1461, 748, 1496, 744, 1514, 787, 1480, 793],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "949",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 10,
    coords: [1423, 754, 1458, 750, 1476, 793, 1442, 799],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "950",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1437, 723, 1469, 721, 1480, 742, 1445, 745],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "951",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1473, 719, 1505, 717, 1516, 738, 1481, 741],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "952",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1512, 714, 1544, 712, 1555, 733, 1520, 736],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "953",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1550, 710, 1582, 708, 1593, 729, 1558, 732],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "954",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1588, 706, 1620, 704, 1631, 725, 1596, 728],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "955",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1626, 703, 1658, 701, 1669, 722, 1634, 725],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "956",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1665, 699, 1697, 697, 1708, 718, 1673, 721],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "957",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1701, 694, 1733, 692, 1744, 713, 1709, 716],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "958",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1738, 691, 1770, 689, 1781, 710, 1746, 713],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "959",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1775, 687, 1807, 685, 1818, 706, 1783, 709],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "960",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1812, 684, 1844, 682, 1855, 703, 1820, 706],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "961",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1776, 662, 1808, 660, 1819, 681, 1784, 684],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "962",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1738, 664, 1770, 662, 1781, 683, 1746, 686],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "963",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1702, 668, 1734, 666, 1745, 687, 1710, 690],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "964",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1665, 673, 1697, 671, 1708, 692, 1673, 695],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "965",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1628, 677, 1660, 675, 1671, 696, 1636, 699],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "966",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1591, 680, 1623, 678, 1634, 699, 1599, 702],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "967",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1556, 685, 1588, 683, 1599, 704, 1564, 707],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "968",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1515, 690, 1547, 688, 1558, 709, 1523, 712],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "969",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1478, 694, 1510, 692, 1521, 713, 1486, 716],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "970",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1439, 697, 1471, 695, 1482, 716, 1447, 719],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "971",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1401, 700, 1433, 698, 1444, 719, 1409, 722],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "972",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1379, 682, 1411, 680, 1422, 701, 1387, 704],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "973",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1414, 678, 1446, 676, 1457, 697, 1422, 700],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "974",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1447, 675, 1479, 673, 1490, 694, 1455, 697],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "975",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1483, 672, 1515, 670, 1526, 691, 1491, 694],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "976",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1520, 667, 1552, 665, 1563, 686, 1528, 689],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "977",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1556, 662, 1588, 660, 1599, 681, 1564, 684],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "978",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1594, 658, 1626, 656, 1637, 677, 1602, 680],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "979",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1629, 653, 1661, 651, 1672, 672, 1637, 675],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "980",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1666, 650, 1698, 648, 1709, 669, 1674, 672],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "981",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1703, 646, 1735, 644, 1746, 665, 1711, 668],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "982",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1739, 642, 1771, 640, 1782, 661, 1747, 664],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "983",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1734, 622, 1766, 619, 1776, 637, 1741, 640],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "984",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1701, 625, 1733, 622, 1743, 640, 1708, 643],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "985",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1667, 627, 1699, 624, 1709, 642, 1674, 645],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "986",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1631, 630, 1663, 627, 1673, 645, 1638, 648],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "987",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1594, 634, 1626, 631, 1636, 649, 1601, 652],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "988",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1559, 639, 1591, 636, 1601, 654, 1566, 657],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "989",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1523, 644, 1555, 641, 1565, 659, 1530, 662],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "990",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1486, 648, 1518, 645, 1528, 663, 1493, 666],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "991",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1450, 652, 1482, 649, 1492, 667, 1457, 670],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "992",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1412, 656, 1444, 653, 1454, 671, 1419, 674],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "993",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [1374, 659, 1406, 656, 1416, 674, 1381, 677],
  },

  // * 2nd interface
  // ! 1st line
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "994",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2473, 588, 2502, 583, 2534, 628, 2505, 631],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "995",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2502, 586, 2531, 581, 2563, 626, 2534, 629],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "996",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2532, 583, 2561, 578, 2593, 623, 2564, 626],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "997",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2562, 579, 2591, 574, 2623, 619, 2594, 622],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "998",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2592, 576, 2621, 571, 2653, 616, 2624, 619],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "999",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2620, 572, 2649, 567, 2681, 612, 2652, 615],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1000",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2652, 568, 2682, 564, 2708, 606, 2677, 611],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1001",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2679, 566, 2708, 561, 2740, 606, 2711, 609],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1002",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2707, 563, 2736, 558, 2768, 603, 2739, 606],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1003",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2737, 561, 2766, 556, 2798, 601, 2769, 604],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1004",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2766, 557, 2795, 552, 2827, 597, 2798, 600],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1005",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2793, 553, 2822, 548, 2854, 593, 2825, 596],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1006",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2825, 548, 2855, 544, 2881, 586, 2850, 591],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1007",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2855, 546, 2884, 541, 2916, 586, 2887, 589],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1008",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2887, 544, 2916, 539, 2948, 584, 2919, 587],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1009",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2919, 541, 2948, 536, 2980, 581, 2951, 584],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1010",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2950, 538, 2979, 533, 3011, 578, 2982, 581],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1011",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2981, 535, 3010, 530, 3042, 575, 3013, 578],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1012",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3014, 531, 3044, 527, 3070, 569, 3039, 574],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1013",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3042, 528, 3071, 523, 3103, 568, 3074, 571],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1014",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3072, 524, 3101, 519, 3133, 564, 3104, 567],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1015",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3103, 521, 3132, 517, 3162, 559, 3133, 564],
  },

  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1016",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3131, 518, 3160, 513, 3192, 558, 3163, 561],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1017",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3157, 517, 3186, 512, 3218, 557, 3189, 560],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1018",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3183, 514, 3212, 509, 3244, 554, 3215, 557],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1019",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3208, 510, 3237, 505, 3269, 550, 3240, 553],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1020",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3233, 508, 3262, 503, 3294, 548, 3265, 551],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1021",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3259, 506, 3288, 501, 3320, 546, 3291, 549],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1022",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3284, 504, 3313, 499, 3345, 544, 3316, 547],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1023",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3310, 501, 3339, 496, 3371, 541, 3342, 544],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1024",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3334, 499, 3363, 494, 3395, 539, 3366, 542],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1025",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3359, 495, 3388, 490, 3420, 535, 3391, 538],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1026",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3383, 494, 3412, 489, 3444, 534, 3415, 537],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1027",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3408, 491, 3437, 486, 3469, 531, 3440, 534],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1028",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3433, 489, 3462, 484, 3494, 529, 3465, 532],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1029",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3458, 487, 3487, 482, 3519, 527, 3490, 530],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1030",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3484, 484, 3513, 479, 3545, 524, 3516, 527],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1031",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3514, 481, 3542, 478, 3571, 518, 3542, 520],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1032",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3545, 478, 3573, 475, 3602, 515, 3573, 517],
  },
  // ! 2nd line
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1033",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2648, 628, 2677, 624, 2707, 666, 2678, 671],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1034",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2677, 624, 2706, 620, 2736, 662, 2707, 667],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1035",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2710, 622, 2739, 618, 2769, 660, 2740, 665],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1036",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2740, 619, 2769, 615, 2799, 657, 2769, 660],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1037",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2770, 616, 2799, 612, 2829, 654, 2799, 657],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1038",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2800, 613, 2829, 609, 2859, 651, 2829, 654],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1039",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2830, 610, 2859, 606, 2889, 648, 2859, 651],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1040",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2859, 606, 2888, 602, 2918, 644, 2888, 647],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1041",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2888, 602, 2917, 598, 2947, 640, 2917, 643],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1042",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2916, 598, 2945, 594, 2975, 636, 2945, 639],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1043",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2944, 595, 2973, 591, 3003, 633, 2973, 636],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1044",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2971, 591, 3000, 587, 3030, 629, 3000, 632],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1045",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3000, 587, 3029, 583, 3059, 625, 3029, 628],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1046",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3028, 583, 3057, 579, 3087, 621, 3057, 624],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1047",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3056, 579, 3085, 575, 3115, 617, 3085, 620],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1048",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3083, 575, 3112, 571, 3142, 613, 3112, 616],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1049",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3111, 571, 3140, 567, 3170, 609, 3140, 612],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1050",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3139, 568, 3168, 564, 3198, 606, 3168, 609],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1051",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3165, 564, 3194, 560, 3224, 602, 3194, 605],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1052",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3194, 563, 3223, 559, 3253, 601, 3223, 604],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1053",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3219, 559, 3248, 555, 3278, 597, 3248, 600],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1054",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3244, 555, 3273, 551, 3303, 593, 3273, 596],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1055",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3271, 552, 3300, 548, 3330, 590, 3300, 593],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1056",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3298, 550, 3327, 546, 3357, 588, 3327, 591],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1057",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3323, 546, 3352, 542, 3382, 584, 3352, 587],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1058",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3349, 543, 3378, 539, 3408, 581, 3378, 584],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1059",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3377, 541, 3406, 537, 3436, 579, 3406, 582],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1060",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3403, 538, 3432, 534, 3462, 576, 3432, 579],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1061",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3431, 534, 3457, 531, 3487, 573, 3460, 574],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1062",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3456, 532, 3485, 528, 3515, 570, 3485, 573],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1063",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3479, 529, 3508, 525, 3538, 567, 3508, 570],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1064",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3506, 526, 3532, 523, 3562, 565, 3533, 567],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1065",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3530, 523, 3556, 520, 3586, 562, 3559, 563],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1066",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3555, 521, 3581, 518, 3611, 560, 3584, 561],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1067",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3580, 518, 3606, 515, 3636, 557, 3609, 558],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1068",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3606, 516, 3632, 513, 3662, 555, 3635, 556],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1069",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3630, 513, 3656, 510, 3686, 552, 3659, 553],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1070",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3656, 511, 3684, 508, 3713, 546, 3684, 548],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1071",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3688, 508, 3716, 505, 3745, 543, 3716, 545],
  },

  // ! 3rd line
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1072",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2829, 671, 2855, 668, 2883, 708, 2858, 711],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1073",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2857, 668, 2883, 665, 2911, 705, 2886, 708],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1074",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2888, 665, 2914, 662, 2942, 702, 2917, 705],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1075",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2919, 660, 2945, 657, 2973, 697, 2948, 700],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1076",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2949, 656, 2975, 653, 3003, 693, 2978, 696],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1077",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [2980, 652, 3006, 649, 3034, 689, 3009, 692],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1078",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3009, 648, 3035, 645, 3063, 685, 3038, 688],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1079",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3040, 646, 3066, 643, 3094, 683, 3069, 686],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1080",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3068, 642, 3094, 639, 3122, 679, 3097, 682],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1081",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3099, 638, 3125, 635, 3153, 675, 3128, 678],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1082",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3128, 634, 3154, 631, 3182, 671, 3157, 674],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1083",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3155, 630, 3181, 627, 3209, 667, 3184, 670],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1084",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3184, 627, 3210, 624, 3238, 664, 3213, 667],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1085",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3213, 624, 3239, 621, 3267, 661, 3242, 664],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1086",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3243, 621, 3269, 618, 3297, 658, 3272, 661],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1087",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3271, 617, 3297, 614, 3325, 654, 3300, 657],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1088",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3299, 614, 3325, 611, 3353, 651, 3328, 654],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1089",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3327, 610, 3353, 607, 3381, 647, 3356, 650],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1090",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3355, 606, 3381, 603, 3409, 643, 3384, 646],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1091",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3382, 603, 3408, 600, 3436, 640, 3411, 643],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1092",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3407, 600, 3433, 597, 3461, 637, 3436, 640],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1093",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3435, 597, 3461, 594, 3489, 634, 3464, 637],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1094",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3462, 593, 3488, 590, 3516, 630, 3491, 633],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1095",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3489, 589, 3515, 586, 3543, 626, 3518, 629],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1096",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3515, 586, 3541, 583, 3569, 623, 3544, 626],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1097",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3542, 583, 3568, 580, 3596, 620, 3571, 623],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1098",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3568, 579, 3594, 576, 3622, 616, 3597, 619],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1099",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3595, 576, 3621, 573, 3649, 613, 3624, 616],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1100",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3621, 573, 3647, 570, 3675, 610, 3650, 613],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1101",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3646, 570, 3672, 567, 3700, 607, 3675, 610],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1102",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3671, 567, 3697, 564, 3725, 604, 3700, 607],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1103",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3695, 565, 3721, 562, 3749, 602, 3724, 605],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1104",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3723, 562, 3749, 559, 3777, 599, 3752, 602],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1105",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3747, 558, 3773, 555, 3801, 595, 3776, 598],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1106",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3771, 554, 3797, 551, 3825, 591, 3800, 594],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1107",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3797, 552, 3823, 549, 3851, 589, 3826, 592],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1108",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3822, 548, 3848, 545, 3876, 585, 3851, 588],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1109",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3845, 545, 3871, 542, 3899, 582, 3874, 585],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1110",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3871, 542, 3897, 539, 3925, 579, 3900, 582],
  },

  // ! 4th line
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1111",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3038, 719, 3064, 715, 3084, 745, 3057, 748],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1112",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3066, 715, 3092, 711, 3112, 741, 3085, 744],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1113",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3095, 711, 3121, 707, 3141, 737, 3114, 740],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1114",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3123, 708, 3149, 704, 3169, 734, 3142, 737],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1115",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3151, 704, 3177, 700, 3197, 730, 3170, 733],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1116",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3180, 700, 3206, 696, 3226, 726, 3199, 729],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1117",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3208, 697, 3234, 693, 3254, 723, 3227, 726],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1118",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3235, 693, 3261, 689, 3281, 719, 3254, 722],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1119",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3264, 688, 3290, 684, 3310, 714, 3283, 717],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1120",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3292, 684, 3318, 680, 3338, 710, 3311, 713],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1121",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3324, 680, 3350, 676, 3370, 706, 3343, 709],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1122",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3351, 676, 3377, 672, 3397, 702, 3370, 705],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1123",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3378, 672, 3404, 668, 3424, 698, 3397, 701],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1124",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3405, 668, 3431, 664, 3451, 694, 3424, 697],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1125",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3434, 665, 3460, 661, 3480, 691, 3453, 694],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1126",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3462, 661, 3488, 657, 3508, 687, 3481, 690],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1127",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3490, 657, 3516, 653, 3536, 683, 3509, 686],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1128",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3516, 653, 3542, 649, 3562, 679, 3535, 682],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1129",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3543, 650, 3569, 646, 3589, 676, 3562, 679],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1130",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3571, 646, 3597, 642, 3617, 672, 3590, 675],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1131",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3597, 642, 3623, 638, 3643, 668, 3616, 671],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1132",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3625, 638, 3651, 634, 3671, 664, 3644, 667],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1133",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3653, 635, 3679, 631, 3699, 661, 3672, 664],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1134",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3681, 631, 3707, 627, 3727, 657, 3700, 660],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1135",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3709, 627, 3735, 623, 3755, 653, 3728, 656],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1136",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3737, 624, 3763, 620, 3783, 650, 3756, 653],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1137",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3766, 620, 3792, 616, 3812, 646, 3785, 649],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1138",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3795, 616, 3821, 612, 3841, 642, 3814, 645],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1139",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3824, 612, 3850, 608, 3870, 638, 3843, 641],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1140",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3851, 608, 3877, 604, 3897, 634, 3870, 637],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1141",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3879, 604, 3905, 600, 3925, 630, 3898, 633],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1142",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3906, 600, 3932, 596, 3952, 626, 3925, 629],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1143",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3935, 595, 3961, 591, 3981, 621, 3954, 624],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1144",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3963, 591, 3989, 587, 4009, 617, 3982, 620],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1145",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3991, 587, 4017, 583, 4037, 613, 4010, 616],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1146",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4020, 583, 4046, 579, 4066, 609, 4039, 612],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1147",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4049, 579, 4075, 575, 4095, 605, 4068, 608],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1148",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4078, 575, 4104, 571, 4124, 601, 4097, 604],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1149",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4108, 572, 4134, 568, 4154, 598, 4127, 601],
  },

  // ! 5th line
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1150",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3208, 777, 3234, 773, 3254, 803, 3227, 806],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1151",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3236, 773, 3262, 769, 3282, 799, 3255, 802],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1152",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3265, 769, 3291, 765, 3311, 795, 3284, 798],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1153",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3293, 765, 3319, 761, 3339, 791, 3312, 794],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1154",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3322, 761, 3348, 757, 3368, 787, 3341, 790],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1155",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3351, 757, 3377, 753, 3397, 783, 3370, 786],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1156",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3380, 753, 3406, 749, 3426, 779, 3399, 782],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1157",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3409, 749, 3435, 745, 3455, 775, 3428, 778],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1158",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3466, 741, 3492, 737, 3512, 767, 3485, 770],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1159",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3494, 738, 3520, 734, 3540, 764, 3513, 767],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1160",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3523, 734, 3549, 730, 3569, 760, 3542, 763],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1161",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3551, 729, 3577, 725, 3597, 755, 3570, 758],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1162",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3580, 725, 3606, 721, 3626, 751, 3599, 754],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1163",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3608, 721, 3634, 717, 3654, 747, 3627, 750],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1164",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3637, 717, 3663, 713, 3683, 743, 3656, 746],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1165",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3661, 713, 3687, 709, 3707, 739, 3680, 742],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1166",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3689, 709, 3715, 705, 3735, 735, 3708, 738],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1167",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3718, 705, 3744, 701, 3764, 731, 3737, 734],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1168",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3746, 701, 3772, 697, 3792, 727, 3765, 730],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1169",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3776, 696, 3802, 692, 3822, 722, 3795, 725],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1170",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3805, 692, 3831, 688, 3851, 718, 3824, 721],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1171",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3834, 688, 3860, 684, 3880, 714, 3853, 717],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1172",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3862, 684, 3888, 680, 3908, 710, 3881, 713],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1173",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3889, 680, 3915, 676, 3935, 706, 3908, 709],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1174",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3918, 676, 3944, 672, 3964, 702, 3937, 705],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1175",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3945, 671, 3971, 667, 3991, 697, 3964, 700],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1176",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3974, 666, 4000, 662, 4020, 692, 3993, 695],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1177",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4000, 662, 4026, 658, 4046, 688, 4019, 691],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1178",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4028, 658, 4054, 654, 4074, 684, 4047, 687],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1179",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4056, 654, 4082, 650, 4102, 680, 4075, 683],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1180",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4084, 650, 4110, 646, 4130, 676, 4103, 679],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1181",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4112, 646, 4138, 642, 4158, 672, 4131, 675],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1182",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4141, 642, 4167, 638, 4187, 668, 4160, 671],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1183",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4169, 638, 4195, 634, 4215, 664, 4188, 667],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1184",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4197, 634, 4223, 630, 4243, 660, 4216, 663],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1185",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4226, 630, 4252, 626, 4272, 656, 4245, 659],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1186",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4254, 624, 4280, 620, 4300, 650, 4273, 653],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1187",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4283, 620, 4309, 616, 4329, 646, 4302, 649],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1188",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4312, 616, 4338, 612, 4358, 642, 4331, 645],
  },

  // ! 6th line
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1189",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3402, 814, 3434, 808, 3465, 850, 3431, 854],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1190",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3435, 811, 3467, 805, 3498, 847, 3464, 851],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1191",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3467, 805, 3499, 799, 3530, 841, 3496, 845],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1192",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3501, 802, 3533, 796, 3564, 838, 3530, 842],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1193",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3531, 796, 3563, 790, 3594, 832, 3560, 836],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1194",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3564, 792, 3596, 786, 3627, 828, 3593, 832],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1195",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3595, 787, 3627, 781, 3658, 823, 3625, 827],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1196",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3626, 782, 3658, 776, 3689, 818, 3656, 822],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1197",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3656, 777, 3688, 771, 3719, 813, 3686, 817],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1198",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3687, 772, 3719, 766, 3750, 808, 3717, 812],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1199",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3717, 767, 3749, 761, 3780, 803, 3747, 807],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1200",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3747, 762, 3779, 756, 3810, 798, 3777, 802],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1201",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3777, 758, 3809, 752, 3840, 794, 3807, 798],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1202",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3806, 753, 3838, 747, 3869, 789, 3836, 793],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1203",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3836, 748, 3867, 742, 3899, 784, 3867, 787],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1204",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3865, 744, 3896, 738, 3928, 780, 3896, 783],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1205",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3894, 739, 3925, 733, 3957, 775, 3925, 778],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1206",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3922, 734, 3953, 728, 3985, 770, 3953, 773],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1207",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3952, 731, 3983, 725, 4015, 767, 3983, 770],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1208",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3982, 727, 4013, 721, 4047, 761, 4015, 765],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1209",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4010, 724, 4041, 718, 4075, 758, 4043, 762],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1210",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4037, 718, 4068, 712, 4102, 752, 4070, 756],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1211",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4065, 714, 4096, 708, 4130, 748, 4098, 752],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1212",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4093, 710, 4124, 704, 4158, 744, 4126, 748],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1213",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4119, 705, 4150, 699, 4184, 739, 4152, 743],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1214",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4146, 700, 4177, 694, 4211, 734, 4179, 738],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1215",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4174, 697, 4205, 691, 4239, 731, 4207, 735],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1216",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4200, 692, 4231, 686, 4265, 726, 4233, 730],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1217",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4227, 688, 4258, 682, 4292, 722, 4260, 726],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1218",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4253, 684, 4284, 678, 4318, 718, 4286, 722],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1219",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4280, 680, 4311, 674, 4345, 714, 4313, 718],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1220",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4306, 676, 4337, 670, 4371, 710, 4339, 714],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1221",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4331, 672, 4362, 666, 4396, 706, 4364, 710],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1222",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4358, 669, 4389, 663, 4423, 703, 4391, 707],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1223",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4384, 665, 4415, 659, 4449, 699, 4417, 703],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1224",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4410, 661, 4441, 655, 4475, 695, 4443, 699],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1225",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4435, 656, 4466, 650, 4500, 690, 4468, 694],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1226",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4459, 652, 4490, 646, 4524, 686, 4492, 690],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1227",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4484, 648, 4515, 642, 4549, 682, 4517, 686],
  },

  // ! 7th line
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1226",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3602, 869, 3631, 864, 3660, 903, 3631, 908],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1227",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3634, 863, 3663, 858, 3692, 897, 3663, 902],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1228",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3663, 855, 3691, 849, 3725, 889, 3694, 893],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1229",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3696, 852, 3724, 846, 3758, 886, 3727, 890],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1230",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3728, 847, 3756, 841, 3790, 881, 3759, 885],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1231",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3760, 842, 3788, 836, 3822, 876, 3791, 880],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1232",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3792, 837, 3820, 831, 3854, 871, 3823, 875],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1233",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3824, 831, 3852, 825, 3886, 865, 3855, 869],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1234",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3855, 827, 3883, 821, 3917, 861, 3886, 865],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1235",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3886, 822, 3914, 816, 3948, 856, 3917, 860],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1236",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3918, 817, 3946, 811, 3980, 851, 3949, 855],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1237",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3948, 811, 3976, 805, 4010, 845, 3979, 849],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1238",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3978, 806, 4006, 800, 4040, 840, 4009, 844],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1239",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4007, 800, 4035, 794, 4069, 834, 4038, 838],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1240",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4038, 796, 4066, 790, 4100, 830, 4069, 834],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1241",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4067, 791, 4095, 785, 4129, 825, 4098, 829],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1242",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4097, 787, 4125, 781, 4159, 821, 4128, 825],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1243",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4127, 783, 4155, 777, 4189, 817, 4158, 821],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1244",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4156, 778, 4184, 772, 4218, 812, 4187, 816],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1245",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4185, 773, 4213, 767, 4247, 807, 4216, 811],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1246",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4214, 769, 4242, 763, 4276, 803, 4245, 807],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1247",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4239, 762, 4267, 756, 4301, 796, 4270, 800],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1248",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4268, 758, 4296, 752, 4330, 792, 4299, 796],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1249",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4297, 753, 4325, 747, 4359, 787, 4328, 791],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1250",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4324, 749, 4352, 743, 4386, 783, 4355, 787],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1251",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4352, 744, 4380, 738, 4414, 778, 4383, 782],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1252",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4380, 739, 4408, 733, 4442, 773, 4411, 777],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1253",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4407, 735, 4436, 729, 4467, 767, 4438, 772],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1254",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4434, 732, 4463, 726, 4494, 764, 4465, 769],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1255",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4460, 728, 4489, 722, 4520, 760, 4491, 765],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1256",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4486, 723, 4515, 717, 4546, 755, 4517, 760],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1257",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4513, 719, 4542, 713, 4573, 751, 4544, 756],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1258",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4540, 715, 4569, 709, 4600, 747, 4571, 752],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1259",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4567, 711, 4596, 705, 4627, 743, 4598, 748],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1260",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4592, 706, 4621, 700, 4652, 738, 4623, 743],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1261",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4616, 702, 4645, 696, 4676, 734, 4647, 739],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1262",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4642, 698, 4671, 692, 4702, 730, 4673, 735],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1263",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4668, 693, 4697, 687, 4728, 725, 4699, 730],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1264",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4693, 689, 4722, 683, 4753, 721, 4724, 726],
  },
  // ! 8th line
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1265",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3734, 955, 3768, 949, 3804, 995, 3772, 1000],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1266",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3768, 949, 3802, 943, 3838, 989, 3806, 994],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1267",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3802, 943, 3836, 937, 3872, 983, 3840, 988],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1268",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3836, 937, 3870, 931, 3906, 977, 3874, 982],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1269",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3870, 931, 3904, 925, 3940, 971, 3908, 976],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1270",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3902, 926, 3936, 920, 3972, 966, 3940, 971],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1271",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3932, 921, 3966, 915, 4002, 961, 3970, 966],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1272",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3963, 916, 3997, 910, 4033, 956, 4001, 961],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1273",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [3994, 910, 4028, 904, 4064, 950, 4032, 955],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1274",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4025, 905, 4059, 899, 4095, 945, 4063, 950],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1275",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4056, 900, 4090, 894, 4126, 940, 4094, 945],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1276",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4086, 895, 4120, 889, 4156, 935, 4124, 940],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1277",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4117, 889, 4151, 883, 4187, 929, 4155, 934],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1278",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4149, 881, 4183, 875, 4222, 925, 4188, 929],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1279",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4183, 876, 4217, 870, 4256, 920, 4222, 924],
  },

  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1280",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4216, 870, 4250, 864, 4289, 914, 4255, 918],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1281",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4249, 865, 4283, 859, 4322, 909, 4288, 913],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1282",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4281, 860, 4315, 854, 4354, 904, 4320, 908],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1283",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4313, 854, 4347, 848, 4386, 898, 4352, 902],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1284",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4344, 849, 4378, 843, 4417, 893, 4383, 897],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1285",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4376, 844, 4410, 838, 4449, 888, 4415, 892],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1286",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4408, 839, 4442, 833, 4481, 883, 4447, 887],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1287",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4439, 834, 4473, 828, 4512, 878, 4478, 882],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1288",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4472, 829, 4506, 823, 4545, 873, 4511, 877],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1289",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4505, 824, 4539, 818, 4578, 868, 4544, 872],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1290",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4538, 819, 4572, 813, 4611, 863, 4577, 867],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1291",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4572, 814, 4606, 808, 4645, 858, 4611, 862],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1292",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4604, 809, 4638, 803, 4677, 853, 4643, 857],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1293",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4637, 803, 4671, 797, 4710, 847, 4676, 851],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1294",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4671, 797, 4705, 791, 4744, 841, 4710, 845],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1295",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4705, 792, 4739, 786, 4778, 836, 4744, 840],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1296",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4737, 786, 4771, 780, 4810, 830, 4776, 834],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1297",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4769, 781, 4803, 775, 4842, 825, 4808, 829],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1298",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4804, 776, 4838, 770, 4877, 820, 4843, 824],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1299",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4835, 771, 4869, 765, 4908, 815, 4874, 819],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1300",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4867, 767, 4901, 761, 4940, 811, 4906, 815],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1301",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4900, 762, 4934, 756, 4973, 806, 4939, 810],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1302",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4934, 757, 4968, 751, 5007, 801, 4973, 805],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1303",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4967, 752, 5001, 746, 5040, 796, 5006, 800],
  },

  // ! 9th line
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1304",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4154, 1056, 4188, 1050, 4229, 1100, 4196, 1107],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1305",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4186, 1049, 4220, 1043, 4261, 1093, 4228, 1100],
  },
  // {
  //   id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
  //   shape: "poly",
  //   name: "1306",
  //   fillColor: "#00ff194c",
  //   strokeColor: "#fff",
  //   lineWidth: 7,
  //   coords: [4186, 1049, 4220, 1043, 4261, 1093, 4228, 1100],
  // },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1306",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4222, 1042, 4256, 1036, 4297, 1086, 4264, 1093],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1307",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4254, 1037, 4288, 1031, 4329, 1081, 4296, 1088],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1308",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4287, 1030, 4321, 1024, 4362, 1074, 4329, 1081],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1309",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4321, 1023, 4355, 1017, 4396, 1067, 4363, 1074],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1310",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4353, 1017, 4387, 1011, 4428, 1061, 4395, 1068],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1311",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4386, 1011, 4420, 1005, 4461, 1055, 4428, 1062],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1312",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4418, 1004, 4452, 998, 4493, 1048, 4460, 1055],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1313",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4451, 997, 4485, 991, 4526, 1041, 4493, 1048],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1314",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4484, 991, 4518, 985, 4559, 1035, 4526, 1042],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1315",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4516, 985, 4550, 979, 4591, 1029, 4558, 1036],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1316",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4548, 979, 4582, 973, 4623, 1023, 4590, 1030],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1317",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4580, 973, 4614, 967, 4655, 1017, 4622, 1024],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1318",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4613, 967, 4647, 961, 4688, 1011, 4655, 1018],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1319",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4647, 961, 4681, 955, 4722, 1005, 4689, 1012],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1320",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4679, 955, 4713, 949, 4754, 999, 4721, 1006],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1321",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4713, 949, 4747, 943, 4788, 993, 4755, 1000],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1322",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4746, 943, 4780, 937, 4821, 987, 4788, 994],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1323",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4779, 937, 4813, 931, 4854, 981, 4821, 988],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1324",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4812, 931, 4846, 925, 4887, 975, 4854, 982],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1325",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4845, 924, 4879, 918, 4920, 968, 4887, 975],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1326",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4875, 910, 4909, 904, 4950, 954, 4917, 961],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1327",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4907, 904, 4941, 898, 4982, 948, 4949, 955],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1328",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4939, 897, 4973, 891, 5014, 941, 4981, 948],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1329",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [4974, 891, 5008, 885, 5049, 935, 5016, 942],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1330",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [5008, 885, 5042, 879, 5083, 929, 5050, 936],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1331",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [5041, 879, 5075, 873, 5116, 923, 5083, 930],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1332",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [5073, 873, 5107, 867, 5148, 917, 5115, 924],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1333",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [5106, 867, 5140, 861, 5181, 911, 5148, 918],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1334",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [5138, 861, 5172, 855, 5213, 905, 5180, 912],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1335",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [5203, 849, 5237, 843, 5278, 893, 5245, 900],
  },
  {
    id: `cc3c2799-${Math.random()}-4236-b4f6-${Math.random()}`,
    shape: "poly",
    name: "1336",
    fillColor: "#00ff194c",
    strokeColor: "#fff",
    lineWidth: 7,
    coords: [5236, 843, 5270, 837, 5311, 887, 5278, 894],
  },
];

// 4186, 1049, 4220, 1043, 4261, 1093, 4228, 1100
// 4863, 758, 4894, 752, 4930, 798, 4899, 803
