import { CircularProgress } from "@mui/material";
import { useGetWeatherStationDataQuery } from "../redux/service/weatherStation";
import { useRef, useState, useEffect } from "react";
import ApexChart from "../components/ApexChart";

import { RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import MinMaxDateRangePicker from "../components/DateRangePicker";
import moment from "moment";
import { checker } from "../hooks";
import { mergeArrays } from "../utils/helpers";

function WeatherStation() {
  // * Date range values
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [endDate, setEndDate] = useState(new Date());

  // * RTK Query hook
  const { data, isLoading, refetch } = useGetWeatherStationDataQuery({
    start_date: Math.floor(new Date(startDate).setHours(0, 0, 0, 0) / 1000),
    end_date: Math.floor(new Date(endDate).setHours(23, 59, 59, 999) / 1000),
  });

  const selectionRange = {
    startDate,
    endDate,
    key: "selection",
  };
  const handleSelect = (ranges: RangeKeyDict) => {
    setStartDate(ranges.selection.startDate as Date);
    setEndDate(ranges.selection.endDate as Date);
  };

  // * refetch when ever endDate change
  useEffect(() => {
    refetch();
  }, [endDate]);

  const ref = useRef<HTMLDivElement | null>(null);

  const data_dhi = mergeArrays(
    data?.chart_1.labels ?? [],
    data?.chart_1.datasets?.dhi?.data ?? []
  );
  const data_dni = mergeArrays(
    data?.chart_1.labels ?? [],
    data?.chart_1.datasets?.dni?.data ?? []
  );
  const data_ghi = mergeArrays(
    data?.chart_1.labels ?? [],
    data?.chart_1.datasets?.ghi?.data ?? []
  );
  const data_gti = mergeArrays(
    data?.chart_1.labels ?? [],
    data?.chart_1.datasets?.gti?.data ?? []
  );

  return (
    <div className="bg-[#F7F9F5]  max-w-[85%] mx-auto ">
      {/* Cards */}
      <div className="flex gap-2 justify-between flex-wrap py-5 px-4">
        <Card
          Icon={"/images/weatherStation-Icons/TempIcon.svg"}
          title="Temperature"
          degree={(checker(data?.data.air_temp.actual, "--") ?? "--") + " °C"}
          max={data?.data.air_temp.max ?? "--"}
          min={data?.data.air_temp.min ?? "--"}
          avg={data?.data.air_temp.avg ?? "--"}
          loading={isLoading}
        />
        <Card
          Icon={"/images/weatherStation-Icons/humidityIcon.svg"}
          title="Humidity"
          degree={(checker(data?.data.rh.actual, "--") ?? "--") + " %"}
          max={data?.data.rh.max ?? "--"}
          min={data?.data.rh.min ?? "--"}
          avg={data?.data.rh.avg ?? "--"}
          loading={isLoading}
        />
        <Card
          Icon={"/images/weatherStation-Icons/PressureIcon.svg"}
          title="Pressure"
          degree={(checker(data?.data.pressure.actual, "--") ?? "--") + " Pa"}
          max={data?.data.pressure.max ?? "--"}
          min={data?.data.pressure.min ?? "--"}
          avg={data?.data.pressure.avg ?? "--"}
          loading={isLoading}
        />
        <Card
          Icon={"/images/weatherStation-Icons/windspeedIcon.svg"}
          title="Wind speed"
          degree={
            (checker(data?.data.wind_speed.actual, "--") ?? "--") + " m/s"
          }
          max={data?.data.wind_speed.max ?? "--"}
          min={data?.data.wind_speed.min ?? "--"}
          avg={data?.data.wind_speed.avg ?? "--"}
          loading={isLoading}
        />
        <Card
          Icon={"/images/weatherStation-Icons/rainIcon.svg"}
          title="Rain"
          degree={(checker(data?.data.rain.actual, "--") ?? "--") + " mm"}
          max={data?.data.rain.max ?? "--"}
          min={data?.data.rain.min ?? "--"}
          avg={data?.data.rain.avg ?? "--"}
          loading={isLoading}
        />
        <Card
          Icon={"/images/weatherStation-Icons/ghiIcon.svg"}
          title="GHI"
          degree={(checker(data?.data.ghi.actual, "--") ?? "--") + " W/m²"}
          max={data?.data.ghi.max ?? "--"}
          min={data?.data.ghi.min ?? "--"}
          avg={data?.data.ghi.avg ?? "--"}
          loading={isLoading}
        />
        <Card
          Icon={"/images/weatherStation-Icons/dhiIcon.svg"}
          title="DHI"
          degree={(checker(data?.data.dhi.actual, "--") ?? "--") + " W/m²"}
          max={data?.data.dhi.max ?? "--"}
          min={data?.data.dhi.min ?? "--"}
          avg={data?.data.dhi.avg ?? "--"}
          loading={isLoading}
        />

        <Card
          Icon={"/images/weatherStation-Icons/dniIcon.svg"}
          title="DNI"
          degree={(checker(data?.data.dni.actual, "--") ?? "--") + " W/m²"}
          max={data?.data.dni.max ?? "--"}
          min={data?.data.dni.min ?? "--"}
          avg={data?.data.dni.avg ?? "--"}
          loading={isLoading}
        />
        <Card
          Icon={"/images/weatherStation-Icons/gtiIcon.svg"}
          title="GTI"
          degree={(checker((data?.data as any)?.gti?.actual) ?? "--") + " W/m²"}
          max={(data?.data as any)?.gti?.max ?? "--"}
          min={(data?.data as any)?.gti?.min ?? "--"}
          avg={(data?.data as any)?.gti?.avg ?? "--"}
          loading={isLoading}
        />
      </div>

      {/* ...Rest */}
      <div className=" py-6  mt-5">
        <div className="flex justify-between">
          <h2 className="text-2xl ml-2 !text-[#333] font-medium ">
            Weather Station
          </h2>
          <div className="flex flex-1 mr-52 flex-col items-center">
            <MinMaxDateRangePicker
              range={[selectionRange]}
              onChange={handleSelect}
            />
          </div>
        </div>
        {/* Chart */}
        <div ref={ref} className=" gap-2 mt-4">
          <div className=" px-4 flex-1  self-start pb-4 rounded-lg">
            {/* First Chart */}
            <div className=" flex flex-1 gap-2 items-start">
              <div className="flex-1 bg-white p-5 border ">
                <ApexChart
                  height={300}
                  series={[
                    // @ts-ignore
                    {
                      name: "Diffuse Horizontal Irradiance",
                      data: data_dhi,
                      // [15, 13, 50, 40, 23, 25, 14],
                    },
                    // @ts-ignore
                    {
                      name: "Direct Normal Irradiance",
                      data: data_dni,
                    },
                    // @ts-ignore
                    {
                      name: "Global Horizontal Irradiance",
                      data: data_ghi,
                    },
                    // @ts-ignore
                    {
                      name: "Global Titled Irradiance",
                      data: data_gti,
                    },
                  ]}
                  type="line"
                  options={{
                    // @ts-ignore
                    // labels: [...(data?.chart_1.labels ?? [])].map((v) =>
                    //   new Date(v).getTime()
                    // ),
                    chart: {
                      zoom: {
                        autoScaleYaxis: true,
                      },
                    },
                    stroke: {
                      curve: "smooth",
                      lineCap: "round",
                      width: 1.5,
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    yaxis: {
                      axisTicks: {
                        show: true,
                      },
                      axisBorder: {
                        show: true,
                      },
                      title: {
                        text: "Irradiance (W/m²)",
                        style: {
                          color: "#555",
                          fontFamily: '"Poppins", sans-serif',
                          fontWeight: 300,
                        },
                      },
                      labels: {
                        formatter(val, opts) {
                          return Number(val).toString();
                        },
                      },
                    },
                    // ...(startDate.getTime() - endDate.getTime() !== 0 && {
                    xaxis: {
                      type: "datetime",
                      tooltip: {
                        enabled: true,
                        formatter(value, opts) {
                          return moment(value).format("lll") ?? value;
                        },
                      },
                      // labels: {
                      //   // datetimeUTC: true,
                      //   datetimeFormatter: {
                      //     year: "yyyy",
                      //     month: "MMM 'yy",
                      //     day: "dd MMM",
                      //     hour: "HH:mm",
                      //   },
                      // },
                      min: new Date(startDate).setHours(0, 0, 0, 0),
                      // tickAmount: 6,
                      max: new Date(endDate).setHours(23, 59, 59, 999),
                    },
                    // }),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex-1 flex gap-2  p-3  items-start rounded-md ">
            {/* @ts-ignore */}
            <div className="flex-1 bg-white p-5   border">
              <ApexChart
                height={300}
                series={[
                  // @ts-ignore
                  {
                    name: "Temperature",
                    data: data?.chart_2?.datasets?.air_temp?.data,
                  },
                  // @ts-ignore
                  {
                    name: "Humidity",
                    data: data?.chart_2?.datasets?.rh?.data,
                  },
                  // @ts-ignore
                  {
                    name: "Wind",
                    data: data?.chart_2?.datasets?.wind_speed?.data,
                  },
                ]}
                type="line"
                options={{
                  // @ts-ignore
                  labels: [...(data?.chart_2.labels ?? [])]?.map((v) =>
                    new Date(v).getTime()
                  ),
                  yaxis: [
                    {
                      seriesName: "Temperature",
                      axisBorder: {
                        show: true,
                        color: "rgb(0, 143, 251)",
                      },
                      axisTicks: {
                        show: true,
                      },
                      labels: {
                        formatter(val, opts) {
                          return Number(val).toString();
                        },
                      },
                      title: {
                        text: "Temperature (°C)",
                        style: {
                          color: "rgb(0, 143, 251)",
                          fontFamily: '"Poppins", sans-serif',
                          fontWeight: 300,
                        },
                      },
                    },
                    {
                      seriesName: "Humidity",
                      opposite: true,
                      axisBorder: {
                        show: true,
                        color: "rgb(0, 227, 150)",
                      },
                      axisTicks: {
                        show: true,
                      },
                      labels: {
                        formatter(val, opts) {
                          return Number(val).toString();
                        },
                      },
                      title: {
                        text: "Humidity (%)",
                        style: {
                          color: "rgb(0, 227, 150)",
                          fontFamily: '"Poppins", sans-serif',
                          fontWeight: 300,
                        },
                      },
                    },
                    {
                      seriesName: "Wind",
                      opposite: true,
                      axisBorder: {
                        show: true,
                        color: "rgb(254, 176, 25)",
                      },
                      axisTicks: {
                        show: true,
                      },
                      labels: {
                        formatter(val, opts) {
                          return Number(val).toString();
                        },
                      },
                      title: {
                        text: "Wind speed (m/s)",
                        style: {
                          color: "rgb(254, 176, 25)",
                          fontFamily: '"Poppins", sans-serif',
                          fontWeight: 300,
                        },
                      },
                    },
                  ],
                  stroke: {
                    curve: "smooth",
                    lineCap: "round",
                    width: 1.5,
                  },
                  // tooltip: { shared: false, intersect: true },
                  // ...(startDate.getTime() - endDate.getTime() !== 0 && {
                  xaxis: {
                    type: "datetime",
                    tooltip: {
                      enabled: true,
                      formatter(value, opts) {
                        return (
                          moment(value).locale("en-ie").format("lll") ?? value
                        );
                      },
                    },
                    labels: {
                      datetimeUTC: true,
                      datetimeFormatter: {
                        year: "yyyy",
                        month: "MMM 'yy",
                        day: "dd MMM",
                        hour: "HH:mm",
                      },
                    },

                    min: startDate.getTime(),
                    // tickAmount: 6,
                    max: endDate.getTime(),
                  },
                  // }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Card({
  Icon,
  avg,
  degree,
  max,
  min,
  title,
  loading,
}: {
  Icon: string;
  title: string;
  degree: string;
  max: string;
  min: string;
  avg: string;
  loading?: boolean;
}) {
  return (
    <div className="bg-white flex flex-col items-center w-[130px] 3xl:w-[165px] space-y-3 shadow-lg py-4 px-1 rounded-lg ">
      {loading ? (
        <div className="p-5">
          <CircularProgress size={60} />
        </div>
      ) : (
        <>
          <h5 className="text-center uppercase  ">{title}</h5>
          <img src={Icon} className=" h-[72px] object-contain " alt="" />
          <p className="text-lg text-center">{degree ?? "null"}</p>
          <div className="flex gap-2">
            <div className="flex flex-col ">
              <span className="text-xs uppercase text-green-600 ">Max</span>
              <span className="text-[11px] text-gray-400">{max ?? "--"}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs uppercase text-green-600 ">Min</span>
              <span className="text-[11px] text-gray-400">{min ?? "--"}</span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs uppercase text-green-600 ">Avg</span>
              <span className="text-[11px] text-gray-400">{avg ?? "--"}</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WeatherStation;
