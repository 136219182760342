import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosError, AxiosResponse } from "axios";
import { API_URL } from "../../utils/helpers";
// import { User } from "../../types";
import { RootState } from "../store";

export interface User {
  status: string;
  token: string;
  user: {
    id: number;
    name: string;
    surname: string;
    username: string;
    email: string;
    is_activated: boolean;
    thumbnail: string;
    avatarUrl: string;
  };
}

interface InitialState {
  user: User | null;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  message: string;
}

const initialState: InitialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") as string)
    : null,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const login = createAsyncThunk(
  "auth/login",
  async (
    userData: Pick<User["user"], "email"> & { password: string },
    thunkApi
  ) => {
    try {
      const { data } = await axios.post<User>(
        `${API_URL}/api/v1/login`,
        JSON.stringify(userData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return data;
    } catch (err) {
      console.log(err);
      return thunkApi.rejectWithValue(err as any);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state: typeof initialState) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
    updateToken: (state, action: PayloadAction<string>) => {
      if (state.user?.token) {
        state.user.token = action.payload;
        localStorage.setItem("user", JSON.stringify(state.user));
      }
    },
  },
  extraReducers: (builder) => {
    // * Login
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
      state.message = "";
      localStorage.setItem("user", JSON.stringify(action.payload));
    });
    builder.addCase(login.rejected, (state, action) => {
      console.log(action);
      state.isLoading = false;
      state.isError = true;
      state.isSuccess = false;
      // @ts-ignore
      state.message = action.payload!.message!;
    });
  },
});

export const { reset, updateToken } = authSlice.actions;
export default authSlice.reducer;
