import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const baseUrl: string = `${process.env.REACT_APP_API_URL}/api/v1`;

export const alarmsReports = createApi({
  reducerPath: "alarmReports",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.user?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAlarmReportsData: builder.query<
      AlarmReports,
      {
        start_date: string;
      }
    >({
      query: ({ start_date }) => ({
        url: "/alarmreports",
        params: {
          start_date,
        },
      }),
    }),
  }),
});

export const { useLazyGetAlarmReportsDataQuery, useGetAlarmReportsDataQuery } =
  alarmsReports;
