import ProgressBar from "@ramonak/react-progress-bar";
import { memo } from "react";
import { generateNumber } from "../utils/helpers";
import ApexChart from "./ApexChart";
import { Props } from "react-apexcharts";

function Card({
  text_1,
  text_2,
  text_3,
  chart,
  title,
  number_1,
  number_2,
  number_3,
  max_1,
  max_2,
  max_3,
  data,
  unit_1,
  unit_2,
  unit_3,
  ...rest
}: {
  text_1?: React.ReactNode | string;
  text_2?: string;
  text_3?: string;
  number_1?: number;
  number_2?: number;
  number_3?: number;
  max_1?: number;
  max_2?: number;
  max_3?: number;
  chart?: boolean;
  title?: any;
  unit_1?: string;
  unit_2?: string;
  unit_3?: string;
} & Props) {
  return (
    <>
      <div className="border bg-white flex flex-col justify-between w-[320px] 3xl:w-[380px] shadow-lg rounded-lg px-5 py-3 ">
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium !text-[#333]">
            {title ?? "PV Plant KPIs"}
          </p>
        </div>
        {!chart ? (
          <div className="my-3 flex-grow flex flex-col justify-center space-y-4">
            <p className="grid grid-cols-2 items-end justify-between">
              <span className="text-xs text-gray-500">{text_1}</span>
              <div className="">
                <div className="flex justify-between">
                  <span className="text-xs text-gray-500">
                    {String(number_1)}
                  </span>
                  <span className="text-xs text-gray-500">{unit_1}</span>
                </div>
                <ProgressBar
                  className="flex-1 !rounded-md"
                  completed={number_1 ?? 0}
                  maxCompleted={max_1}
                  labelClassName="text-gray-700 block !pl-10"
                  customLabel={" "}
                  animateOnRender
                  bgColor={"#74AF4D"}
                />
              </div>
            </p>
            <p className="grid grid-cols-2 items-end justify-between">
              <span className="text-xs text-gray-500">{text_2}</span>
              <div className="">
                <div className="flex justify-between">
                  <span className="text-xs text-gray-500">
                    {String(number_2)}
                  </span>
                  <span className="text-xs text-gray-500">{unit_2}</span>
                </div>
                <ProgressBar
                  className="flex-1 space-x-2 "
                  completed={number_2 ?? 0}
                  maxCompleted={max_2}
                  customLabel={" "}
                  animateOnRender
                  bgColor={"#74AF4D"}
                />
              </div>
            </p>
            <p className="grid grid-cols-2 items-end justify-between">
              <span className="text-xs text-gray-500">{text_3}</span>
              <div className="">
                <div className="flex justify-between">
                  <span className="text-xs text-gray-500">
                    {String(number_3).replace(" ", "")}
                  </span>
                  <span className="text-xs text-gray-500">{unit_3}</span>
                </div>
                <ProgressBar
                  className="flex-1 space-x-2"
                  completed={String(number_3).replace(" ", "") ?? 0}
                  maxCompleted={max_3}
                  customLabel={" "}
                  animateOnRender
                  bgColor={"#74AF4D"}
                />
              </div>
            </p>
          </div>
        ) : (
          <div className="flex-1 flex items-center">
            {/* <Chart {...rest} data={data} /> */}
            <ApexChart NoToolbar {...rest} />
          </div>
        )}
      </div>
    </>
  );
}

export default memo(Card);
