import React, { Fragment, useMemo, useState } from "react";
import { MapAreas } from "react-img-mapper";

interface MapAreaWithName extends MapAreas {
  name: string;
}

// * Component
function Polygon({
  className,
  renderPoint,
  fill,
  elements,
  array,
  onClick,
}: {
  className: string;
  renderPoint: (_: number[], i: number, is: boolean) => React.ReactNode;
  fill: string;
  elements?: number;
  onClick?: () => void;
  array: MapAreaWithName[];
}) {
  // * Calculate the position of our ploygon
  const points = useMemo(() => array.map((item) => item.coords), [array]);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <polygon
        id="polygon_id"
        style={{
          pointerEvents: "all",
        }}
        className="fill-transparent group opacity-75 "
        // @ts-ignore
        points={points}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      {renderPoint
        ? points.map((_, i) => (
            <Fragment key={i}>{renderPoint(_, i, isHovered)}</Fragment>
          ))
        : ""}
    </>
  );
}

export default Polygon;
