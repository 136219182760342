import React, { useEffect, useLayoutEffect, useState } from "react";
import anime from "animejs";

// * react-circular-progressbar
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { useGetDashboardDataQuery } from "../redux/service/dashboardApi";
import { useRef } from "react";
import Footer from "../components/Footer";
import { checker, useViewPort } from "../hooks";
import millify from "millify";

declare module "animejs" {
  namespace anime {
    export interface AnimeAnimParams {
      keyframes?: readonly anime.AnimeAnimParams[] | undefined | boolean;
    }
  }
}

function animate(options: anime.AnimeParams) {
  return anime({
    // borderRadius: "50%",
    duration: 4000,
    easing: "linear",
    loop: true,
    ...options,
  });
}

function Dashboard() {
  const { data, isLoading, isFetching, isError } = useGetDashboardDataQuery();
  const { width } = useViewPort();

  const line_1 = useRef<HTMLDivElement>(null);
  const line_2 = useRef<HTMLDivElement>(null);
  const line_3 = useRef<HTMLDivElement>(null);
  const line_4 = useRef<HTMLDivElement>(null);
  const _line = useRef<HTMLSpanElement>(null);

  const ref_1 = useRef<HTMLDivElement | anime.AnimeInstance | boolean | null>(
    null
  );
  const ref_2 = useRef<HTMLDivElement | anime.AnimeInstance | boolean | null>(
    null
  );
  const ref_3 = useRef<HTMLDivElement | anime.AnimeInstance | boolean | null>(
    null
  );

  const ref_4 = useRef<HTMLDivElement | anime.AnimeInstance | boolean | null>(
    null
  );
  const ref_5 = useRef<HTMLDivElement | anime.AnimeInstance | boolean | null>(
    null
  );
  const ref_6 = useRef<HTMLDivElement | anime.AnimeInstance | boolean | null>(
    null
  );
  const ref_7 = useRef<HTMLDivElement | anime.AnimeInstance | boolean | null>(
    null
  );
  const ref_8 = useRef<HTMLDivElement | anime.AnimeInstance | boolean | null>(
    null
  );

  // * conditions
  let n__1 = Number(data?.data.home.instant);
  let n__2 = Number(data?.data.pv_realtime.instant);
  let n__3 = Number(data?.data.battery.instant);
  let n__4 = Number(data?.data.grid.instant);

  useEffect(() => {
    if (!isLoading && !isFetching && !isError) {
      // * 1st
      // ref_1.current = animate({
      //   targets: "#el_1",
      //   keyframes: [
      //     {
      //       translateX: Number(line_1.current?.getBoundingClientRect()?.width),
      //     },
      //     {
      //       translateY:
      //         -Number(_line.current?.getBoundingClientRect().height) / 2 - 2,
      //     },
      //     {
      //       translateX:
      //         Number(line_2.current?.getBoundingClientRect()?.width) * 2 + 5,
      //     },
      //   ],
      // });

      // * 2nd
      // ref_2.current = animate({
      //   targets: "#el_2",
      //   opacity: 0,
      //   keyframes: [
      //     {
      //       translateX:
      //         Number(line_1.current?.getBoundingClientRect()?.width) + 10,
      //     },
      //     {
      //       opacity: 1,
      //       translateX:
      //         Number(line_2.current?.getBoundingClientRect()?.width) * 2 + 15,
      //     },
      //   ],
      // });

      // * 3rd
      // ref_3.current = animate({
      //   targets: "#el_3",
      //   keyframes: [
      //     {
      //       translateX: Number(line_1.current?.getBoundingClientRect()?.width),
      //     },
      //     {
      //       translateY:
      //         Number(_line.current?.getBoundingClientRect().height) / 2,
      //     },
      //     {
      //       translateX:
      //         15 + Number(line_2.current?.getBoundingClientRect()?.width) * 2,
      //     },
      //   ],
      // });

      // * 4rd
      ref_4.current = animate({
        targets: "#el_4",
        keyframes: [
          // @ts-ignore
          n__2 > 0 && {
            translateX:
              -Number(line_1.current?.getBoundingClientRect()?.width) + 16,
          },
          // @ts-ignore
          n__2 > 0 && {
            translateY:
              Number(_line.current?.getBoundingClientRect().height) / 2,
          },
          // @ts-ignore
          n__1 > 0 && {
            translateX:
              -Number(line_2.current?.getBoundingClientRect()?.width) * 2 - 20,
          },
        ],
      });

      // * 5rd
      ref_5.current = animate({
        targets: "#el_5",
        keyframes: [
          // @ts-ignore
          n__3 > 0 && {
            translateX:
              -Number(line_3.current?.getBoundingClientRect()?.width) - 7,
            duration: 2570,
          },
          {
            duration: 1430,
          },
        ],
      });

      // * 6rd
      ref_6.current = animate({
        targets: "#el_6",
        keyframes: [
          {
            translateX:
              -Number(line_1.current?.getBoundingClientRect()?.width) + 15,
          },
          {
            translateY:
              -Number(_line.current?.getBoundingClientRect().height) / 2,
          },
          // @ts-ignore
          n__1 > 0 && {
            translateX:
              -Number(line_2.current?.getBoundingClientRect()?.width) * 2 - 20,
          },
        ],
      });

      // * 7th
      ref_7.current = animate({
        targets: "#el_7",
        duration: 1430,
        delay(element, index, length) {
          return 2570;
        },
        keyframes: [
          {
            translateY:
              Number(_line.current?.getBoundingClientRect().height) / 2,
          },
          {
            translateX: Number(line_2.current?.getBoundingClientRect()?.width),
          },
        ],
      });

      // * 8th
      ref_8.current = animate({
        targets: "#el_8",
        duration: 1430,
        delay(element, index, length) {
          return 2570;
        },
        keyframes: [
          {
            translateX: Number(line_3.current?.getBoundingClientRect()?.width),
          },
        ],
      });
    }
  }, [isLoading, isFetching]);

  console.log(Number(line_3.current?.getBoundingClientRect()?.width));

  return (
    <div className=" min-h-screen  2xl:h-full pt-10 ">
      <div
        style={{
          height: "calc(100% - 32px)",
        }}
        className=" max-w-[85%] mx-auto   flex flex-col  py-16 justify-around  ">
        <div className="flex gap-2 justify-between flex-wrap">
          <Card
            Icon={"/images/dashboard-Icons/PvCapacityIcon.svg"}
            title="PV capacity"
            degree={(data?.data.pv_capacity ?? "null") + " kWc"}
            loading={isLoading || isFetching}
          />
          <Card
            Icon={"/images/dashboard-Icons/totalPvProdIcon.svg"}
            title="Σ PV Production"
            degree={(data?.data.total_pv_production ?? "null") + " GWh"}
            loading={isLoading || isFetching}
          />
          <Card
            Icon={"/images/dashboard-Icons/co2SavingsIcon.svg"}
            title="CO2 savings"
            degree={(Number(data?.data.co2_savings) ?? "null") + " Tonnes"}
            loading={isLoading || isFetching}
          />

          <Card
            Icon={"/images/dashboard-Icons/BatteryCapIcon.svg"}
            title="Battery Capacity"
            degree={(data?.data.battery_caparcity ?? "null") + " kWh"}
            loading={isLoading || isFetching}
          />
          <Card
            Icon={"/images/dashboard-Icons/batterySocIcon.svg"}
            title="Battery SOC"
            degree={(data?.data.battery_soc ?? "null") + " %"}
            loading={isLoading || isFetching}
          />
          <Card
            Icon={"/images/dashboard-Icons/StoredEnergyIcon.svg"}
            title="Stored Energy"
            degree={(data?.data.stored_energy ?? "null") + " MWh"}
            loading={isLoading || isFetching}
          />

          <Card
            Icon={"/images/dashboard-Icons/totalGridIcon.svg"}
            title="Σ grid withdraw"
            degree={(data?.data.total_grid_withdraw ?? "null") + " GWh"}
            loading={isLoading || isFetching}
          />
          <Card
            Icon={"/images/dashboard-Icons/totalConsumption.svg"}
            title="Σ Consumption"
            degree={(data?.data.total_consumption ?? "null") + " GWh"}
            loading={isLoading || isFetching}
          />
        </div>
        <div className="flex flex-col lg:flex-row gap-x-5 gap-y-2">
          {/* Shema */}
          <div className="flex flex-1  bg-white py-5 px-5 mt-7 shadow-lg rounded-lg">
            <div className="flex relative flex-1 items-center  ">
              <div className="grid grid-cols-2 gap-2  w-[121.48px]">
                <p className="flex flex-col gap-1 ">
                  <span className="text-xs  text-green-600 uppercase ">
                    Inst
                  </span>
                  <span className="text-[11px] text-gray-500 whitespace-nowrap ">
                    {checker(data?.data.home.instant as string)} kW
                  </span>
                </p>
                <p className="flex flex-col gap-1">
                  <span className="text-xs  text-green-600 uppercase  ">
                    Max
                  </span>
                  <span className="text-[11px] text-gray-500 whitespace-nowrap">
                    {checker(data?.data.home.max)} kW
                  </span>
                </p>
                <p className="flex flex-col gap-1">
                  <span className="text-xs  text-green-600 uppercase  ">
                    Avg
                  </span>
                  <span className="text-[11px] text-gray-500  whitespace-nowrap">
                    {checker(data?.data.home.avg)} kW
                  </span>
                </p>
                <p className="flex flex-col gap-1">
                  <span className="text-xs  text-green-600 uppercase  ">
                    Min
                  </span>
                  <span className="text-[11px] text-gray-500  whitespace-nowrap">
                    {checker(data?.data.home.min)} kW
                  </span>
                </p>
              </div>
              <div className="bg-[#74AF4D] mx-2 h-[90px] w-[90px] flex justify-center items-center rounded-md ">
                <img
                  src="/images/dashboard-Icons/HomeIcon.svg"
                  className="text-white object-contain !text-5xl"
                  alt=""
                />
              </div>
              {/* Animation Point 1st */}
              <div className="relative !w-0 !h-0 top-[-16px] ">
                {/* <div
                  ref={ref_3 as React.RefObject<HTMLDivElement>}
                  id="el_3"
                  className={`w-3 ${
                    Number(data?.data.battery.instant) === 0 && "hidden"
                  } h-3 bg-blue-300 absolute !m-0 !mt-3`}
                />
                <div
                  ref={ref_2 as React.RefObject<HTMLDivElement>}
                  id="el_2"
                  className={`w-3 ${
                    Number(data?.data.battery.instant) === 0 && "hidden"
                  } h-3 bg-blue-300 absolute !m-0 !mt-3`}
                />
                <div
                  ref={ref_1 as React.RefObject<HTMLDivElement>}
                  id="el_1"
                  className={`w-3 ${
                    Number(data?.data.battery.instant) === 0 && "hidden"
                  } h-3 bg-blue-300 absolute !m-0 !mt-3`}
                /> */}
              </div>

              <div
                ref={line_1}
                className="border-dotted border-t-2 pr-[20px] z-50 bg-[#74AF4D] flex-1 shema__borders after:top-[-8px] "
              />
            </div>
            <span
              ref={_line}
              className="bg-[#74AF4D] border-dotted border-l-2 z-50 h-[340px] 3xl:h-[531px] mt-12 mr-[-2px] "></span>
            <div className=" space-y-20 3xl:space-y-44 flex-1 ">
              <div className="flex items-center gap-5">
                <div
                  ref={line_2}
                  className="border-dotted border-t-2 bg-[#74AF4D] z-50 flex-1 w-full after:top-[-10px] "
                />
                <div className="bg-[#74AF4D] h-[90px] w-[90px] flex justify-center items-center rounded-md p-3  relative shema__borders after:top-[38px] after:left-[-20px]">
                  <div className="relative !w-0 !h-0 top-[-45px] ">
                    <div
                      ref={ref_4 as React.RefObject<HTMLDivElement>}
                      id="el_4"
                      className={`w-3 h-3 top-7 absolute right-[20px] ${
                        n__2 <= 0 && "hidden"
                      } bg-blue-300 rounded-full !m-0 !mt-3`}
                    />
                  </div>
                  <img
                    src="/images/dashboard-Icons/PvSysIcon.svg"
                    className="text-white !text-5xl"
                    alt=""
                  />
                </div>
                <div className="grid grid-cols-2 gap-2 w-[121.48px] ">
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600  uppercase ">
                      Inst
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {checker(data?.data.pv_realtime.instant)} kW
                    </span>
                  </p>
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase ">
                      Max
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {checker(data?.data.pv_realtime.max)} kW
                    </span>
                  </p>
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase ">
                      Avg
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {checker(data?.data.pv_realtime.avg)} kW
                    </span>
                  </p>
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase ">
                      Min
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {" "}
                      {checker(data?.data.pv_realtime.min)} kW
                    </span>
                  </p>
                </div>
              </div>
              <div className=" flex items-center gap-5">
                <div
                  ref={line_3}
                  className="border-dotted border-t-2 bg-[#74AF4D] flex-1 shema__borders after:z-50 after:top-[-6px] z-50 after:left-[-5px] ">
                  <div className="relative !w-0 !h-0 top-[-45px] ">
                    <div
                      ref={ref_7 as React.RefObject<HTMLDivElement>}
                      id="el_7"
                      className={`w-3 h-3 top-7 z-[0] absolute rounded-full right-[-7px] ${
                        n__4 >= 0 && "hidden"
                      } bg-blue-300  !m-0 !mt-3`}
                    />
                    <div
                      ref={ref_8 as React.RefObject<HTMLDivElement>}
                      id="el_8"
                      className={`w-3 h-3 top-7 z-[0] absolute rounded-full right-[-7px] ${
                        n__3 >= 0 && "hidden"
                      } bg-blue-300  !m-0 !mt-3`}
                    />
                  </div>
                </div>
                <div className="bg-[#74AF4D] rounded-md h-[90px] w-[90px] flex justify-center after:z-50 items-center p-3 shema__borders after:top-[38px] after:left-[-20px] ">
                  <div className="relative !w-0 !h-0 z-[0] top-[-45px] ">
                    <div
                      ref={ref_5 as React.RefObject<HTMLDivElement>}
                      id="el_5"
                      className={`w-3 h-3 top-7 z-[0] absolute rounded-full right-[20px] ${
                        n__3 <= 0 && "hidden"
                      } bg-blue-300  !m-0 !mt-3`}
                    />
                  </div>
                  <img
                    src="/images/dashboard-Icons/BatteryIcon.svg"
                    className="text-white "
                    alt=""
                  />
                </div>
                <div className="grid grid-cols-2 gap-2 w-[121.48px]">
                  <p className="flex flex-col gap-1">
                    <span className="text-xs  text-green-600 uppercase ">
                      Inst
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {checker(data?.data.battery.instant)} kW
                    </span>
                  </p>
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase whitespace-nowrap ">
                      Max
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {checker(data?.data.battery.max)} kW
                    </span>
                  </p>
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase whitespace-nowrap">
                      Avg
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {checker(data?.data.battery.avg)} kW
                    </span>
                  </p>
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase whitespace-nowrap ">
                      Min
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {" "}
                      {checker(data?.data.battery.min)} kW
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex  items-center gap-5 ">
                <div
                  ref={line_4}
                  className="border-dotted border-t-2 bg-[#74AF4D] flex-1  after:top-[-11px] "
                />
                {/*  */}
                {/* <div
                  ref={ref_6 as React.RefObject<HTMLDivElement>}
                  id="el_6"
                  className={`w-3 h-3 top-7 absolute right-[53%] bg-blue-300 ${
                    n__4 <= 0 && "hidden"
                  } rounded-full !m-0 !mt-3`}
                /> */}
                <div className="bg-[#74AF4D] h-[90px] w-[90px]  flex items-center justify-center rounded-md shema__borders after:z-50 after:top-[38px] after:left-[-20px] p-3">
                  <div className="relative !w-0 z-[0] !h-0 top-[-45px] ">
                    <div
                      ref={ref_6 as React.RefObject<HTMLDivElement>}
                      id="el_6"
                      className={`w-3 h-3 top-7 absolute ${
                        n__4 <= 0 && "hidden"
                      } rounded-full right-[20px] bg-blue-300  !m-0 !mt-3`}
                    />
                  </div>
                  <img
                    src="/images/dashboard-Icons/GridIcon.svg"
                    className="text-white !text-5xl"
                    alt=""
                  />
                </div>
                <div className="grid grid-cols-2 gap-2 w-[121.48px]">
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase ">
                      Inst
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {checker(data?.data.grid.instant)} kW
                    </span>
                  </p>
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase ">
                      Max
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {checker(data?.data.grid.max)} kW
                    </span>
                  </p>
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase ">
                      Avg
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {checker(data?.data.grid.avg)} kW
                    </span>
                  </p>
                  <p className="flex flex-col gap-1">
                    <span className="text-xs text-green-600 uppercase ">
                      Min
                    </span>
                    <span className="text-[11px] text-gray-500 whitespace-nowrap">
                      {" "}
                      {checker(data?.data.grid.min)} kW
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 lg:max-w-xs 3xl:max-w-sm bg-white py-5 px-7 mt-7 flex flex-col justify-around space-y-10 shadow-lg rounded-lg">
            <div className="flex flex-col gap-2 space-y-4">
              <span className="text-gray-500 flex gap-2 items-center text-sm">
                <div className="h-3 w-3 bg-[#5ccffe] rounded-full " /> Self
                Consumption
              </span>

              <div className="flex justify-center">
                <CircularProgressbar
                  value={Number(data?.data.self_consumption) ?? 0}
                  text={`${data?.data.self_consumption ?? 0}%`}
                  strokeWidth={width >= 1920 ? 12 : 10}
                  styles={{
                    path: {
                      stroke: `#5ccffe`,
                      transition: "stroke-dashoffset 0.5s ease 0s",
                    },
                    text: {
                      fill: "#000",
                      fontSize: "20px ",
                      fontWeight: "500",
                    },
                    root: {
                      height: width >= 1920 ? 190 : 130,
                      width: width >= 1920 ? 190 : 130,
                    },
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 space-y-4 ">
              <span className="text-gray-500 flex gap-2 items-center text-sm">
                <div className="h-3 w-3 bg-[#fbba6d] rounded-full " /> Self
                Sufficiency
              </span>
              <div className="flex justify-center">
                <CircularProgressbar
                  value={Number(data?.data.self_sufficiency) ?? 0}
                  text={`${Number(data?.data.self_sufficiency ?? 0)}%`}
                  strokeWidth={width >= 1920 ? 12 : 10}
                  styles={{
                    path: {
                      stroke: `#fbba6d`,
                      transition: "stroke-dashoffset 0.5s ease 0s",
                    },
                    text: {
                      fill: "#000",
                      fontWeight: "500",
                      fontSize: "20px ",
                    },
                    root: {
                      height: width >= 1920 ? 190 : 130,
                      width: width >= 1920 ? 190 : 130,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function Card({
  Icon,
  degree,
  title,
  loading,
}: {
  Icon: string;
  title: string;
  degree: string;
  loading: boolean;
}) {
  return (
    <div className="bg-white w-[145px] 3xl:w-[191px] 3xl:h-[250px] flex flex-col items-center justify-between space-y-3 shadow-lg py-4 px-2 rounded-lg ">
      <h5 className="text-center capitalize whitespace-nowrap 3xl:text-xl font-medium text-sm">
        {title}
      </h5>
      <img
        src={Icon}
        className="text-[#74AF4D] !text-7xl h-[72px] w-[72px] 3xl:h-[90px] 3xl:w-[90px] object-contain"
        alt=""
      />
      <p className="text-xl text-center">{loading ? "Loading..." : degree}</p>
    </div>
  );
}

export default Dashboard;
