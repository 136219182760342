import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const baseUrl: string = `${process.env.REACT_APP_API_URL}/api/v1`;
type Params = { start_date?: string | Date; end_date?: string | Date };

export const weatherStation = createApi({
  reducerPath: "weatherStation",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.user?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getWeatherStationData: builder.query<
      WeatherStationData,
      { start_date: number | string; end_date: number | string }
    >({
      query: ({ start_date, end_date }) => ({
        url: `/weatherstation`,
        params: {
          start_date,
          end_date,
        },
      }),
    }),
  }),
});

export const { useGetWeatherStationDataQuery } = weatherStation;
