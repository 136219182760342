import Card from "../components/Card";
import { useEffect, useRef, useState } from "react";
import {
  useGetPvSystemsTableQuery,
  useGetReportingDataQuery,
  useLazyGetPvSystemReportingQuery,
} from "../redux/service/reportingApi";
import ProgressBar from "@ramonak/react-progress-bar";
import Table from "../components/Table";
import ApexChart from "../components/ApexChart";
import { useViewPort } from "../hooks";
import { PDFExport } from "@progress/kendo-react-pdf";
import PdfViewer from "./PdfViewer";
import CircularProgress from "@mui/material/CircularProgress";
import millify from "millify";

const options = [
  { text: "All" },
  { state: 0, text: "Offline" },
  { state: 1, text: "Started" },
  { state: 2, text: "Under Test" },
  { state: 3, text: "Broken down" },
  { state: 4, text: "Under maintenance" },
];

// const sleep = () => new Promise((resolve) => setTimeout(resolve, 3500));

export default function PVsystems() {
  // * Select Option status
  const [selected, setSelected] = useState(options[0]);
  const [page, setPage] = useState(1);
  const paper = useRef<PDFExport>(null);
  const [rowId, setRowId] = useState<string | null>(null);
  const { width } = useViewPort();

  // * RTK Query hooks
  const { data } = useGetReportingDataQuery();
  const {
    data: tableData,
    isLoading,
    isFetching,
  } = useGetPvSystemsTableQuery({ page, size: 7, state: selected.state });

  const [showChart, setShowChart] = useState(true);
  // useEffect(() => {
  //   const id = setTimeout(() => setShowChart(true), 5000);

  //   return () => clearTimeout(id);
  // }, []);

  // * Infinite Scroll
  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 50;
      if (scrolledToBottom && !isFetching) {
        console.log("Fetching more data...");
        if (page === tableData?.last_page) return;
        setPage(page + 1);
      }
    };

    document.addEventListener("scroll", onScroll);

    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [page, isFetching]);

  // * PDF Data
  const [
    fetchReporting,
    { data: reporting, isLoading: pdfLoading, isFetching: pdfFetching },
  ] = useLazyGetPvSystemReportingQuery({});

  // * Pdf export
  const exportPDFWithComponent = async () => {
    // await sleep();

    if (paper.current) {
      paper.current.save();
      // setRowId(null);
    }
  };
  return (
    <div
      style={{
        ...(width >= 1920 && { minHeight: "calc(100vh - 102px)" }),
      }}
      className=" py-0 mx-auto max-w-[85%] 3xl:max-w-[80%] ">
      {/* Cards */}
      <div className="flex justify-between gap-3 py-10 px-4">
        <Card
          title="PV plant KPIs"
          text_1="Performance Ratio"
          text_2="Specific Yield"
          text_3="Reference Yield"
          number_1={Number(data?.kpis.ratio_performance.toFixed(2)) ?? 0}
          number_2={Number(data?.kpis.specific_yield.toFixed(2)) ?? 0}
          number_3={Number(data?.kpis.irradiance.toFixed(2)) ?? 0}
          unit_1="%"
          unit_2="kWh/kWp"
          unit_3="kWh/kWp"
          max_1={100}
          max_2={10}
          max_3={10}
        />
        <Card
          title={
            <span>
              PV Production <small className="text-gray-500">(kWh)</small>{" "}
            </span>
          }
          text_1="Today"
          text_2="Yesterday"
          text_3="Last week"
          number_1={data?.pv_production.today ?? 0}
          number_2={data?.pv_production.yesterday ?? 0}
          number_3={data?.pv_production.forecast ?? 1}
          max_1={800}
          max_2={800}
          max_3={5600}
        />
        <Card
          title="Energy mix"
          chart
          height={300}
          width={width >= 1920 ? 340 : 300}
          series={Object.values(data?.energy_mix ?? {})}
          type="donut"
          options={{
            labels: Object.keys(data?.energy_mix ?? {}),
          }}
        />
        <Card
          title={
            <span>
              Total PV production <small className="text-gray-500">(kWh)</small>{" "}
            </span>
          }
          chart
          height={width >= 1920 ? 200 : 200}
          width={"100%"}
          series={[
            // @ts-ignore
            {
              data:
                data?.total_pv_production.data.map((v) => v.replace(" ", "")) ??
                [],
              name: "value",
            },
          ]}
          type="bar"
          options={{
            colors: ["#74af4d"],
            dataLabels: {
              enabled: false,
            },

            chart: { zoom: { enabled: false }, toolbar: { show: false } },
            // @ts-ignore
            labels:
              data?.total_pv_production.labels.map((v) =>
                new Date(v).getTime()
              ) ?? [],
            plotOptions: {
              bar: {
                columnWidth: "10px",
              },
            },
            yaxis: {
              show: true,
              axisBorder: {
                show: true,
              },
              axisTicks: { show: true },
              labels: {
                show: true,
                formatter(val, opts) {
                  return Number(val).toString();
                },
              },
            },
            xaxis: {
              type: "datetime",
              min: new Date().setDate(new Date().getDate() - 8),
              max: new Date().getTime(),
            },
          }}
        />
        {/* <Card title="Energie mix"  /> */}
      </div>
      {/* @ts-ignore */}
      <div className="pb-6  px-4">
        <Table
          // @ts-ignore
          title={
            <span className="!text-[#333] text-3xl font-medium">
              Pv Systems
            </span>
          }
          tableClassName="mt-7"
          theader={[
            "Subsystem",
            "Nominal Power",
            "Inverter",
            "Icatcher",
            "Output Power",
            "Performance Ratio",
            "Today Yield",
            "State",
            "Today production profile",
            "Reporting",
          ]}
          // ToggleIcon={ChartPieIcon}
          renderHeader={(theader) => (
            <thead
              className={`text-xs text-white rounded-md uppercase bg-[#74AF4D] `}>
              <tr className="rounded-t-md">
                {theader.map((header, index) => (
                  <th
                    scope="col"
                    key={index}
                    className="px-4 first:rounded-tl-lg font-normal last:rounded-tr-lg text-center capitalize  py-4">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          isInfiniteScroll={true}
          className="bg-transparent !shadow-none"
          titleClassName="!pb-2 !pt-0 bg-transparent"
          keyItem="name"
          hideSearch
          redirectKey="id"
          navigateOnClick={true}
          options={options}
          selected={selected}
          setSelected={setSelected}
          // setToggleSidebar={setToggleSidebar}
          tbody={
            tableData?.data.filter((v) =>
              selected.state !== undefined ? v.state === selected.state : true
            ) ?? []
          }
          // * Pagination
          pagination={false}
          pageCount={tableData?.last_page}
          page={page}
          setPage={setPage}
          // * ----
          loading={isLoading || isFetching}
          render={(item) => (
            <>
              <td className="px-4 py-4 truncate max-w-[120px] ">{item.name}</td>
              <th
                scope="row"
                className="px-4 py-4 font-medium !text-[#333] dark:text-white whitespace-nowrap">
                {item.power} kWp
              </th>
              <th
                scope="row"
                className="px-4 py-4 text-[12px] max-w-[130px] font-medium !text-[#333] dark:text-white ">
                <p className="whitespace-nowrap">STP {item.inverter_id} </p>
                <p className="whitespace-nowrap">
                  S/N: {item.inverter_address_id}{" "}
                </p>
              </th>
              <th
                scope="row"
                className="px-4 py-4 max-w-[60px]  font-medium !text-[#333] dark:text-white ">
                <p className="">{item.icatcher_address_id}</p>
              </th>
              <th
                scope="row"
                className="px-4 py-4 max-w-[80px] whitespace-nowrap text-xs font-medium !text-[#333] dark:text-white ">
                <p className="">{item.outputPower} kWp</p>
              </th>
              <th
                scope="row"
                className="px-4 py-4 font-medium max-w-[150px] !text-[#333] dark:text-white ">
                <p className="flex items-center text-xs">
                  {" "}
                  <ProgressBar
                    className="flex-1 space-x-2 mr-1 "
                    completed={item.performanceRatio}
                    customLabel={" "}
                    animateOnRender
                    bgColor={"#74af4d"}
                  />
                  {item.performanceRatio} %
                </p>
              </th>
              <th
                scope="row"
                className="px-4 py-4 font-medium whitespace-nowrap text-xs !text-[#333] dark:text-white ">
                <p className="">{item.todayYield} kWh</p>
              </th>
              <th
                scope="row"
                className="px-4 py-4 font-medium !text-[#333] dark:text-white ">
                <p
                  style={{
                    background:
                      item.stateColor === "red"
                        ? "#ffe8e7"
                        : item.stateColor === "orange"
                        ? "#faf1d7"
                        : item.stateColor === "green"
                        ? "#ddf7cd"
                        : item.stateColor === "blue"
                        ? "#dcedfc"
                        : item.stateColor === "yellow"
                        ? "#f7fcd7"
                        : "light" + item.stateColor,
                    color:
                      item.stateColor === "green"
                        ? "#40c702"
                        : item.stateColor === "red"
                        ? "#c70202"
                        : item.stateColor === "blue"
                        ? "#026bc7"
                        : item.stateColor === "yellow"
                        ? "#F6BE00"
                        : item.stateColor === "orange"
                        ? "#e3a702"
                        : "dark" + item.stateColor,
                  }}
                  className={`capitalize text-center mx-auto w-fit text-sm whitespace-nowrap px-4 py-2 rounded-full `}>
                  {item.stateAlias ?? "null"}
                </p>
              </th>

              <th
                scope="row"
                className="px-4 py-2 !pb-0 font-medium max-w-[280px] !text-[#333] dark:text-white ">
                {showChart && (
                  <ApexChart
                    NoToolbar
                    height={100}
                    width={"100%"}
                    series={[
                      // @ts-ignore
                      {
                        data: item.TodayProductionProfile,
                        name: "value",
                      },
                    ]}
                    type="area"
                    options={{
                      colors: ["#74af4d"],
                      responsive: [
                        {
                          breakpoint: 1920,
                          options: {
                            chart: {
                              toolbar: {
                                show: false,
                              },
                              width: "100%",
                            },
                          },
                        },
                      ],
                      yaxis: {
                        show: false,
                        labels: {
                          show: false,
                          formatter(val, opts) {
                            return val.toFixed(2);
                          },
                        },
                      },
                      chart: {
                        toolbar: {
                          show: false,
                        },
                        width: "100%",
                        events: {
                          mounted: (chart) => {
                            chart.windowResizeHandler();
                          },
                        },
                      },
                      dataLabels: { enabled: false },
                      stroke: {
                        curve: "smooth",
                        width: 2.5,
                      },
                      labels: [
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12",
                        "13",
                        "14",
                        "15",
                        "16",
                        "17",
                        "18",
                        "19",
                        "20",
                      ].slice(0, item.TodayProductionProfile.length),
                    }}
                  />
                )}
              </th>
              <th
                scope="row"
                style={{
                  pointerEvents: pdfLoading || pdfFetching ? "none" : "unset",
                }}
                onClick={async (e) => {
                  e.stopPropagation();
                  setRowId(item.id);
                  await fetchReporting({ id: item.id });
                  await exportPDFWithComponent();
                }}>
                {(pdfLoading || pdfFetching) && rowId === item.id ? (
                  <div className="h-8 w-8 mx-auto">
                    <CircularProgress size={30} className="" />
                  </div>
                ) : (
                  <img
                    className="h-8 mx-auto"
                    src="/images/pdf-viewer-icon.svg"
                    alt=""
                  />
                )}
              </th>
            </>
          )}
        />
      </div>
      <div className="opacity-0 h-0  overflow-hidden ">
        {/* @ts-ignore */}
        {rowId && <PdfViewer id={rowId} data={reporting} ref={paper} />}
      </div>
    </div>
  );
}
