import moment from "moment";
import React, { forwardRef } from "react";
import ApexChart from "../components/ApexChart";
import { getDates } from "../hooks";
import { useGetPvSystemReportingQuery } from "../redux/service/reportingApi";
import { PDFExport } from "@progress/kendo-react-pdf";
import Footer from "../components/Footer";

const labels = generateDatesForCurrentMonth();

function generateDatesForCurrentMonth() {
  // Get the current date
  var currentDate = new Date();

  // Get the current month and year
  var currentMonth = currentDate.getMonth();
  var currentYear = currentDate.getFullYear();

  // Get the number of days in the current month
  var daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  // Create an array to store the dates
  var dates = [];

  // Add the dates to the array
  for (var i = 1; i <= daysInCurrentMonth; i++) {
    var date = new Date(currentYear, currentMonth, i);
    dates.push(date);
  }

  // Return the array of dates
  return dates;
}

const PdfViewer = forwardRef<any, { id: string | number; data?: Reporting }>(
  ({ id, data }, ref) => {
    // const { data } = useGetPvSystemReportingQuery({ id: "Avc129" });
    // console.log(data);
    return (
      <PDFExport
        paperSize="auto"
        margin={0}
        fileName={`statistics__${new Date().getTime()}`}
        ref={ref}>
        <div className="max-w-[85%] space-y-2 pb-10 mx-auto">
          <div className="bg-[#74AF4D] h-14 flex items-center ">
            <img
              src="/images/Logo Scada/scada-logo-pdf.svg"
              alt=""
              className={`h-10 px-4  object-contain cursor-pointer  `}
            />
          </div>
          {/* Main */}
          <main className="px-10 space-y-4 py-20">
            <h2 className="uppercase text-3xl text-center tracking-wider font-medium ">
              Summary Report: <br />{" "}
              <span className="text-2xl">
                {data?.system_infos.system_name}{" "}
                {data?.system_infos.icatcher_address}
              </span>
            </h2>
            <p className="text-end">
              From{" "}
              {new Date(
                new Date().setDate(new Date().getDate() - 30)
              ).toLocaleDateString()}{" "}
              to {new Date().toLocaleDateString()}{" "}
            </p>

            {/* System info */}
            <fieldset className="border ml-3">
              <legend className="text-center w-[20%]  ">System info</legend>
              <div className="flex px-16 py-6 justify-evenly">
                <ul className=" list-disc ">
                  {/* <li className="whitespace-nowrap flex justify-between items-baseline">
                    <span className="w-52 pr-10">Total Dataloggers </span>

                    <span className=" flex-1 ">:</span>
                    <span className="text-end text-xs">
                      {data?.information.total_dataloggers ?? "null"}
                    </span>
                  </li> */}
                  <li className="">
                    <div className="whitespace-nowrap flex items-baseline">
                      <span className="w-44 pr-10">System name</span>{" "}
                      <span className=" pr-10">:</span>{" "}
                      <b className=" ">
                        {data?.system_infos?.system_name ?? "null"}
                      </b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex  items-baseline">
                      <span className="w-44 pr-10">I catcher address</span>{" "}
                      <span className=" pr-10 ">:</span>{" "}
                      <b>{data?.system_infos?.icatcher_address ?? "null"}</b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex  items-baseline">
                      <span className="w-44 pr-10">Inverter Address</span>{" "}
                      <span className=" pr-10  ">:</span>{" "}
                      <b>{data?.system_infos?.inverter_address ?? "null"}</b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex  items-baseline">
                      <span className="w-44 pr-10">Inverter type</span>{" "}
                      <span className=" pr-10  ">:</span>{" "}
                      <b>{data?.system_infos?.inverter_type ?? "null"}</b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex  items-baseline">
                      <span className="w-44 pr-10">Installed Power</span>
                      <span className="pr-10">:</span>{" "}
                      <b>{data?.system_infos?.installed_power} kWp</b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex  items-baseline">
                      <span className="w-44 pr-10">Module type</span>
                      <span className=" pr-10 ">:</span>{" "}
                      <b>{data?.system_infos?.module_type ?? "null"} Wp</b>
                    </div>
                  </li>
                </ul>
                <ul className="list-disc ">
                  <li>
                    <div className="whitespace-nowrap flex items-baseline">
                      <span className="w-56 pr-16">Number of modules</span>
                      <span className=" pr-10">:</span>{" "}
                      <b>{data?.system_infos?.modules_nb ?? "null"}</b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex items-baseline">
                      <span className="w-56 pr-16">Number of strings</span>{" "}
                      <span className=" pr-10 ">:</span>{" "}
                      <b>{data?.system_infos?.strings_nb ?? "null"}</b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex items-baseline">
                      <span className="w-56 pr-16">Number of modules/str</span>{" "}
                      <span className="pr-10">:</span>{" "}
                      <b>{data?.system_infos?.mod_str_nb ?? "null"}</b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex items-baseline">
                      <span className="w-56 pr-16">State</span>{" "}
                      <span className="pr-10">:</span>{" "}
                      <b>{data?.system_infos?.state ?? "null"}</b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex items-baseline">
                      <span className="w-56 pr-16">Installation Date</span>{" "}
                      <span className=" pr-10">:</span>{" "}
                      <b>
                        {data?.system_infos?.installation_date
                          ? new Date(
                              data?.system_infos?.installation_date as string
                            ).toLocaleDateString()
                          : "null"}
                      </b>
                    </div>
                  </li>
                  <li>
                    <div className="whitespace-nowrap flex items-baseline">
                      <span className="w-56 pr-16">Tilt & Orientation</span>{" "}
                      <span className=" pr-10">:</span>{" "}
                      <b>{data?.system_infos?.tilt_orientation ?? "null"}</b>
                    </div>
                  </li>
                </ul>
              </div>
            </fieldset>
            {/* Charts */}
            <div className="mt-6 flex mx-3 items-end gap-5">
              {/* <div className="flex-1">
                <ApexChart
                  height={300}
                  type="scatter"
                  // NoToolbar
                  options={{
                    colors: ["#145DA0"],
                    chart: {
                      toolbar: {
                        show: false,
                      },
                      animations: {
                        enabled: false,
                      },
                    },
                    yaxis: [
                      {
                        seriesName: "P_DC",
                        axisBorder: {
                          show: true,
                        },
                        axisTicks: {
                          show: true,
                        },
                        title: {
                          text: "P_DC",
                          style: {
                            color: "#555",
                            fontFamily: '"Poppins", sans-serif',
                            fontWeight: 400,
                          },
                        },
                      },
                    ],
                    legend: {
                      position: "top",
                      showForSingleSeries: true,
                    },
                    xaxis: {
                      // title: {
                      //   text: "GTI",
                      //   style: {
                      //     color: "#555",
                      //     fontFamily: '"Poppins", sans-serif',
                      //     fontWeight: 400,
                      //   },
                      // },
                      tickAmount: 10,
                      labels: {
                        rotate: 0,
                      },
                    },
                  }}
                  series={[
                    // @ts-ignore
                    {
                      name: "P_DC",
                      data: data?.scatter_chart?.map(({ x, y }) => [
                        parseInt(x),
                        parseInt(y),
                      ]),
                    },
                  ]}
                />
              </div> */}
              <div className="flex-1">
                <ApexChart
                  height={300}
                  NoToolbar
                  options={{
                    colors: ["#0C2D48"],
                    chart: {
                      toolbar: {
                        show: false,
                      },
                      animations: {
                        enabled: false,
                      },
                    },
                    plotOptions: {
                      bar: {
                        columnWidth: "10px",
                        dataLabels: {
                          hideOverflowingLabels: true,
                        },
                      },
                    },
                    title: {
                      align: "center",
                      text: "Monthly Energie Production DC (kWh)",
                    },
                    legend: {
                      position: "top",
                      showForSingleSeries: true,
                    },
                    yaxis: [
                      {
                        seriesName: "Energie Dc",
                        axisBorder: {
                          show: true,
                        },
                        title: {
                          text: "Energie output (kWh)",
                          style: {
                            color: "#555",
                            fontFamily: '"Poppins", sans-serif',
                            fontWeight: 300,
                          },
                        },
                        axisTicks: {
                          show: true,
                        },
                      },
                    ],
                    labels: data?.energy_production_chart?.labels,
                    xaxis: {
                      type: "datetime",
                      min: new Date(
                        new Date().setDate(new Date().getDate() - 30)
                      ).getTime(),
                      max: new Date().getTime(),
                      labels: {
                        datetimeUTC: true,
                        datetimeFormatter: {
                          year: "yyyy",
                          month: "MMM 'yy",
                          day: "dd MMM",
                          hour: "HH:mm",
                        },
                      },
                    },
                  }}
                  series={[
                    // @ts-ignore
                    {
                      name: "Energie Dc",
                      type: "bar",
                      data: data?.energy_production_chart?.datasets[0]?.data,
                    },
                  ]}
                />
              </div>
            </div>
            {data && (
              <ApexChart
                height={300}
                // width={"100%"}
                type="line"
                NoToolbar
                options={{
                  chart: {
                    width: "100%",
                    toolbar: {
                      show: false,
                    },
                    animations: {
                      enabled: false,
                    },
                  },
                  xaxis: {
                    tickAmount: 10,
                    labels: {
                      formatter(value, timestamp, opts) {
                        return moment(value).format("MMM D");
                      },
                      rotate: 0,
                    },
                    // },
                    categories: labels,
                  },
                  yaxis: [
                    {
                      seriesName: "Yr",
                      labels: {
                        formatter(val, opts) {
                          return val.toFixed(2);
                        },
                      },
                      axisBorder: {
                        show: true,
                      },
                      axisTicks: {
                        show: true,
                      },
                      title: {
                        text: "Yield (kWh/kWp)",
                        style: {
                          color: "#555",
                          fontFamily: '"Poppins", sans-serif',
                          fontWeight: 400,
                        },
                      },
                    },
                    {
                      seriesName: "Sy",
                      labels: {
                        formatter(val, opts) {
                          return val.toFixed(2);
                        },
                      },
                      show: false,
                    },
                    {
                      seriesName: "Tamb",
                      labels: {
                        formatter(val, opts) {
                          return val.toFixed(2);
                        },
                      },
                      opposite: true,
                      axisBorder: {
                        show: true,
                      },
                      axisTicks: {
                        show: true,
                      },
                      title: {
                        text: "Ambient temperature (C°)",
                        style: {
                          color: "#555",
                          fontFamily: '"Poppins", sans-serif',
                          fontWeight: 400,
                        },
                      },
                    },
                    {
                      seriesName: "Pr",
                      labels: {
                        formatter(val, opts) {
                          return val.toFixed(2);
                        },
                      },
                      opposite: true,
                      axisBorder: {
                        show: true,
                      },
                      axisTicks: {
                        show: true,
                      },
                      title: {
                        text: "Performance Ratio",
                        style: {
                          color: "#555",
                          fontFamily: '"Poppins", sans-serif',
                          fontWeight: 400,
                        },
                      },
                    },
                  ],
                }}
                // @ts-ignore
                series={[
                  {
                    name: "Yr",
                    type: "column",
                    data: data.weather_chart.find((e) => e.name === "yr")
                      ?.data ?? [
                      74, 15, 12, 31, 47, 74, 15, 12, 31, 47, 74, 15, 12, 31,
                      74, 15, 12, 31, 47, 74, 15, 12, 31, 47, 74, 15, 12, 31,
                    ],
                  },
                  {
                    name: "Sy",
                    type: "column",
                    data: data.weather_chart.find((e) => e.name === "sy")
                      ?.data ?? [
                      74, 15, 12, 31, 47, 74, 15, 12, 31, 47, 74, 15, 12, 31,
                    ],
                  },
                  {
                    name: "Tamb",
                    type: "line",
                    data: data.weather_chart.find((e) => e.name === "tamb")
                      ?.data ?? [
                      74, 15, 12, 31, 47, 74, 15, 12, 31, 47, 74, 15, 12, 31,
                    ],
                  },
                  {
                    name: "Pr",
                    type: "line",
                    data: data.weather_chart.find((e) => e.name === "pr")
                      ?.data ?? [74, 15, 12, 31, 47, 74, 15, 12, 31, 47, 74],
                  },
                ]}
              />
            )}
            {/* Table */}
            <table className="border-collapse border mx-5  pb-10 border-slate-400 ">
              <thead>
                <tr>
                  <th className="border border-slate-300 ">KPI</th>
                  <th className="border border-slate-300 py-4 px-1">
                    Total production (kWh)
                  </th>
                  <th className="border border-slate-300 ">
                    Reference Yield (kWh/kWp)
                  </th>
                  <th className="border border-slate-300 ">
                    Final Yield (kWh/kWp)
                  </th>
                  <th className="border border-slate-300 ">
                    Performance Ratio (%)
                  </th>
                  <th className="border border-slate-300 ">
                    Capture Losses (kWh/kWp)
                  </th>
                  <th className="border border-slate-300 ">
                    Module Efficiency (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-slate-300 p-5">Value</td>
                  <td className="border text-center border-slate-300 p-5">
                    {data?.kpis?.total_production ?? "null"}
                  </td>
                  <td className="border text-center border-slate-300 p-5">
                    {data?.kpis?.reference_yield ?? "null"}
                  </td>
                  <td className="border text-center border-slate-300 p-5">
                    {data?.kpis?.final_yield ?? "null"}
                  </td>
                  <td className="border text-center border-slate-300 p-5">
                    {data?.kpis?.performance_ratio ?? "null"}
                  </td>
                  <td className="border text-center border-slate-300 p-5">
                    {data?.kpis?.capture_losses ?? "null"}
                  </td>
                  <td className="border text-center border-slate-300 p-5">
                    {/* @ts-ignore */}
                    {data?.kpis?.module_efficiency ?? "null"}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className=" overflow-hidden text-[2px] ">.</div>
          </main>
          <Footer />
        </div>
      </PDFExport>
    );
  }
);

export default PdfViewer;
