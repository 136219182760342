import { Fragment, Key } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { classNames } from "../utils/helpers";

export default function SelectButton<T, K extends keyof T>({
  options,
  option,
  key,
  selected,
  setSelected,
  labelClassName,
  label,
  fullWidth,
  buttonClassName,
}: {
  options: T[];
  option: K;
  key: K;
  selected: T;
  setSelected: React.Dispatch<React.SetStateAction<ReturnType<() => T>>>;
  labelClassName?: React.HTMLAttributes<HTMLHeadingElement>["className"];
  label?: string;
  fullWidth?: boolean;
  buttonClassName?: string;
}) {
  return (
    <div className="flex-col">
      <h3
        className={`flex  mb-1 font-medium text-gray-100 text-sm ${labelClassName} `}>
        {label ?? <span className="text-black">State</span>}
      </h3>
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">
              Change published status
            </Listbox.Label>
            <div className="relative">
              <div
                className={`inline-flex shadow-sm divide-x divide-indigo-600 ${
                  fullWidth && "w-full"
                } `}>
                <div
                  className={`relative z-0 inline-flex rounded-md ${
                    fullWidth && "flex-1"
                  } `}>
                  <div
                    className={`relative inline-flex items-center w-56 xl:w-32 ${
                      fullWidth && "!w-full"
                    } px-4  bg-gray-100 py-3 border rounded-l-xl shadow-sm text-gray-800 ${buttonClassName} `}>
                    <p className="mr-2.5 font-medium whitespace-nowrap text-sm !capitalize flex align-start">
                      {selected[option] as unknown as string}
                    </p>
                  </div>
                  <Listbox.Button
                    className={`relative inline-flex items-center border bg-gray-100 py-1  px-4 rounded-l-none rounded-r-xl 
                    text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-1
                     focus:ring-offset-gray-50 focus:ring-gray-200 ${buttonClassName}`}>
                    <span className="sr-only">Change published status</span>
                    <ChevronDownIcon
                      className="h-7 text-green-500"
                      aria-hidden="true"
                    />
                  </Listbox.Button>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options className="origin-top-right absolute  max-h-[320px] overflow-y-auto z-50 right-0 mt-2 w-60 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {options.map((item) => (
                    <Listbox.Option
                      key={item[key] as unknown as Key}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-[#74af4d] " : "text-gray-900",
                          "cursor-pointer select-none relative p-4 text-sm z-50"
                        )
                      }
                      value={item}>
                      {({ selected, active }) => (
                        <div className="flex flex-col">
                          <div className="flex">
                            <p
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-2.5 text-sm self-center flex-grow text-left"
                              )}>
                              {item[option] as unknown as string}
                            </p>
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
