import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChartPieIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import { Button, CircularProgress, Pagination, Skeleton } from "@mui/material";
import { green } from "@mui/material/colors";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import SelectButton from "./SelectButton";
import { useNavigate } from "react-router-dom";

function Table<T, K extends keyof T>({
  theader,
  tbody,
  render,
  title,
  loading,
  count,
  pagination,
  keyItem,
  page,
  hideAdd,
  pageCount,
  hideSearch,
  setPage,
  navigateOnClick,
  setAddOpen,
  className,
  Icon,
  hideTitle,
  selected,
  setSelected,
  options,
  redirectKey,
  setToggleSidebar,
  titleClassName,
  hideSelect,
  ToggleIcon,
  height,
  messageTop,
  renderHeader,
  tableClassName,
  isInfiniteScroll,
}: {
  theader: string[];
  tbody: T[];
  keyItem?: K;
  title: string;
  loading?: boolean;
  pagination?: boolean;
  hideAdd?: boolean;
  render: (item: T) => React.ReactNode;
  navigateOnClick?: boolean;
  count?: number;
  page?: number;
  pageCount?: number;
  hideSearch?: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  setAddOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  Icon?: React.ElementType;
  hideTitle?: boolean;
  selected?: any;
  setSelected?: any;
  options?: any[];
  redirectKey?: K;
  setToggleSidebar?: React.Dispatch<React.SetStateAction<boolean>>;
  titleClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
  hideSelect?: boolean;
  ToggleIcon?: React.ElementType;
  height?: any;
  messageTop?: React.HTMLAttributes<HTMLDivElement>["className"];
  renderHeader?: (theader: string[]) => React.ReactNode;
  tableClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
  isInfiniteScroll?: boolean;
}) {
  const [search, setSearch] = useState("");
  const [data, setData] = useState(() => tbody);
  const navigate = useNavigate();

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  useEffect(() => {
    if (search) {
      setData(
        tbody.filter((item) =>
          (item[keyItem as K] as unknown as string)
            .toLowerCase()
            .includes(search.toLowerCase())
        )
      );
    } else {
      setData(tbody);
    }
  }, [search, keyItem, tbody]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage && setPage(value);
  };

  return (
    <div
      style={{
        ...(height && { height: height }),
      }}
      className={`relative w-full scrollbar-hide bg-white shadow-md sm:rounded-lg min-h-[300px] h-full transition-all duration-200 ${className} `}>
      <div className="">
        {!hideTitle && (
          <div
            className={` flex items-center bg-[#74AF4D] p-4  rounded-tl-md rounded-tr-md ${titleClassName} `}>
            <p className={`text-white flex items-center font-bold text-xl`}>
              {" "}
              {Icon && <Icon className="!h-5 !mr-2" />} {title}
            </p>
            {!hideAdd && (
              <div className="flex flex-1 items-center justify-end gap-3  ">
                {ToggleIcon && (
                  <div
                    onClick={() => setToggleSidebar && setToggleSidebar(true)}
                    className={`px-3 py-3 border cursor-pointer bg-gray-100 shadow-lg rounded-xl ${
                      !hideSelect && "mt-6"
                    } `}>
                    <ToggleIcon className="h-5 text-gray-600" />
                  </div>
                )}
                {!hideSelect && (
                  <SelectButton
                    options={options ?? []}
                    option="text"
                    key="state"
                    selected={selected}
                    setSelected={setSelected}
                    buttonClassName="py-4 !bg-white"
                  />
                )}
              </div>
            )}
          </div>
        )}
        <label htmlFor="table-search" className="sr-only">
          Search
        </label>
        <div className="flex justify-between">
          {!hideSearch && (
            <div className="relative mt-1 ">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <SearchIcon className="h-5 text-gray-700 " />
              </div>
              <input
                value={search}
                onChange={onChange}
                type="text"
                id="table-search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5 "
                placeholder="Chercher..."
              />
            </div>
          )}
        </div>
      </div>
      <table
        className={`w-full text-sm text-left  text-gray-500 shadow-sm ${tableClassName} `}>
        {renderHeader ? (
          <>{renderHeader(theader)}</>
        ) : (
          <thead className={`text-xs text-gray-700 uppercase bg-green-50 `}>
            <tr>
              {theader.map((header, index) => (
                <th
                  scope="col"
                  key={index}
                  className="px-4 text-center capitalize  py-3">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody className="h-full">
          {data?.length === 0 && !loading && (
            <h3
              className={`absolute top-[65%] left-[40%] text-2xl text-center ${messageTop}`}>
              No data found
            </h3>
          )}

          {loading && !isInfiniteScroll ? (
            <>
              {Array(7)
                .fill(0)
                .map(() => (
                  <tr className="my-2 ">
                    {Array.from({ length: theader.length }).map((_, index) => (
                      <td
                        key={index}
                        className="p-4 space-y-4 max-w-md rounded h-32 border-b bg-gray-200 border-gray-300 shadow animate-pulse "></td>
                    ))}
                  </tr>
                ))}
            </>
          ) : (
            <>
              {data?.map((item, index) => (
                <>
                  <tr
                    onClick={() =>
                      navigateOnClick &&
                      navigate(`/pvsystem/${item[redirectKey as unknown as K]}`)
                    }
                    key={index}
                    className={`bg-white border-b text-center ${
                      navigateOnClick && "cursor-pointer"
                    }`}>
                    {render(item)}
                  </tr>
                </>
              ))}
              {loading && isInfiniteScroll && (
                <>
                  <tr className="my-2 ">
                    {Array.from({ length: theader.length }).map((_, index) => (
                      <td
                        key={index}
                        className="p-4 space-y-4 max-w-md rounded h-32 border-b bg-gray-200 border-gray-300 shadow animate-pulse "></td>
                    ))}
                  </tr>
                </>
              )}
            </>
          )}
        </tbody>
      </table>
      {pagination && tbody.length !== 0 && (
        <div className="flex flex-col bg-white shadow-sm items-end justify-center px-20 py-4">
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChange}
            color="primary"
            shape="rounded"
          />
        </div>
      )}
    </div>
  );
}

export default Table;
