import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const baseUrl = process.env.REACT_APP_API_URL;

const overviewApi = createApi({
  reducerPath: "overview",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.user?.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOveriewData: builder.query<OverviewData, void>({
      query: () => "/api/v1/overview",
    }),
  }),
});

export const { useGetOveriewDataQuery } = overviewApi;
export default overviewApi;
