import { useEffect, useState } from "react";

export const API_URL = process.env.REACT_APP_API_URL;

export function generateNumber() {
  const arr = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const random = Math.floor(Math.random() * arr.length);
  return arr[random];
}

export function addZero(i: number | string) {
  // @ts-ignore
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export function checker<T>(e: T, unit: string) {
  return e ? `${e} ${unit}` : "null";
}

export function dateToTimestamp(dateString: string) {
  return new Date(dateString).getTime();
}

// Function to merge the two arrays into a 2D array
export function mergeArrays(datesArray: any[], numbersArray: any[]) {
  if (datesArray.length !== numbersArray.length) {
    throw new Error("Arrays must have the same length.");
  }

  const mergedArray = [];

  for (let i = 0; i < datesArray.length; i++) {
    const dateTimestamp = dateToTimestamp(datesArray[i]);
    const value = numbersArray[i];
    mergedArray.push([dateTimestamp, value]);
  }

  return mergedArray;
}

export function checkNumber(n: any) {
  return isNaN(n) ? 0 : n;
}

export const useScreenWidth = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  // * update current width
  useEffect(() => {
    const handler = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, []);

  return { width };
};
