import { MenuIcon, UserIcon, ViewGridIcon } from "@heroicons/react/outline";
import { Collapse } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { closeSidebar, openSidebar } from "../redux/slices/sidebar";
import { addZero } from "../utils/helpers";
import Dropdown from "./Dropdown";
import { useState } from "react";
import { useGetOveriewDataQuery } from "../redux/service/overviewApi";

function Header() {
  const location = useLocation();

  const [showDropdown, setShowDropdown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  // * redux selectors
  const { user } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();

  // * Date
  const date = new Date();
  const hour = addZero(date.getHours());
  const minute = addZero(date.getMinutes());
  const time = hour + ":" + minute;

  const { sidebarState } = useAppSelector((state) => state.sidebar);
  const dispatch = useAppDispatch();

  const { data } = useGetOveriewDataQuery();

  const navigation = [
    {
      text: "Overview",
      path: "/",
      onClick: () => navigate("/"),
      icon: "1.svg",
    },
    {
      text: "Dashboard",
      path: "/dashboard",
      onClick: () => navigate("/dashboard"),
      icon: "2.svg",
    },
    {
      text: "PV Systems",
      path: "/pvsystems",
      onClick: () => navigate("/pvsystems"),
      icon: "3.svg",
    },
    {
      text: "Weather Station",
      path: "/weatherstation",
      onClick: () => navigate("/weatherstation"),
      icon: "4.svg",
    },
    {
      text: "Alarms",
      path: "/alarms",
      onClick: () => navigate("/alarms"),
      icon: "Alarm icon.svg",
    },
  ];

  return (
    <>
      <header
        className={`px-4 xl:px-7 z-[100] py-2 h-[70px] relative ${
          (location.pathname === "/" || location.pathname === "/dashboard") &&
          "!fixed mb-[60px] !pl-6 overflow-visible "
        } w-full right-0 top-0 z-50 bg-white flex border-b items-center justify-between `}>
        {location.pathname === "/" && (
          <MenuIcon
            onClick={() =>
              sidebarState
                ? dispatch(closeSidebar(false))
                : dispatch(openSidebar(true))
            }
            className="absolute left-4 cursor-pointer h-5 outline-none "
          />
        )}
        <img
          src="/images/Logo Scada/Group 1.svg"
          onClick={() => navigate("/")}
          alt=""
          className={`h-10 ml-2 object-contain cursor-pointer ${
            location.pathname === "/" && "ml-3"
          } `}
        />

        <ul className="items-center hidden lg:flex gap-0 lg:gap-2 xl:gap-3">
          {/* Navigation */}
          {navigation.map((item) => (
            <li key={item.text} className="">
              <div>
                <>
                  {" "}
                  <Link
                    className={`flex items-center space-x-2 uppercase text-xs font-medium text-green-700  py-6 px-3 xl:px-5 " ${
                      location.pathname === item.path &&
                      "border-b-2 border-[#74AF4D]"
                    } `}
                    to={item.path}>
                    {item.icon && (
                      <embed
                        className="h-4 lg:h-5 object-contain text-[#74AF4D]"
                        src={`/images/header-icons/${item.icon}`}
                      />
                    )}{" "}
                    <span className="text-xs">{item.text}</span>
                  </Link>
                </>
              </div>
            </li>
          ))}
        </ul>
        <div className="flex gap-2 xl:gap-4 items-center">
          <div className="flex gap-4 items-center ">
            <img
              className="cursor-pointer"
              onClick={() => setShowDropdown(!showDropdown)}
              src="/images/header-icons/User_Icons.svg"
              alt=""
            />

            <Collapse
              in={showDropdown}
              orientation="horizontal"
              // unmountOnExit
              // mountOnEnter
            >
              <div className="">
                <Dropdown
                  text={
                    <>
                      {(
                        <div className="space-x-2 flex items-center">
                          <img
                            className="h-7 min-h-[28px] min-w-[28px] object-contain rounded-2xl"
                            src={user?.user?.thumbnail}
                            alt=""
                          />{" "}
                          <span className="whitespace-nowrap">
                            {user?.user?.name}
                          </span>{" "}
                        </div>
                      ) ?? "Chargement..."}
                    </>
                  }
                  items={[
                    {
                      text: "Déconnecter",
                      callback: () => {
                        localStorage.removeItem("user");
                        navigate("/login");
                      },
                    },
                  ]}
                  keyItem="text"
                  cb="callback"
                  className="inline-flex w-full justify-center rounded-xl border border-[#74AF4D] !px-4 !py-2 text-xs font-medium text-green-700 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                />
              </div>
            </Collapse>
          </div>
          <MenuIcon
            onClick={() => setShowMenu(!showMenu)}
            className="h-5 lg:hidden cursor-pointer text-[#5f8f3f] "
          />
        </div>
      </header>

      <Collapse orientation="vertical" in={showMenu}>
        <div className="bg-white z-50 fixed shadow-md w-full top-[70px] ">
          <div className=" flex flex-col w-full items-center flex-1 justify-between">
            <div className=" items-center w-full flex-1 justify-center  md:text-base">
              <div className=" flex flex-col px-5 py-2 flex-1 w-full lg:block">
                {navigation.map((item) => (
                  <Link
                    className={`flex items-center space-x-2 uppercase text-xs font-medium text-green-700  py-6 px-3 xl:px-5 " ${
                      location.pathname === item.path &&
                      "border-b-2 border-[#74AF4D]"
                    } `}
                    to={item.path}>
                    {item.icon && (
                      <embed
                        className="h-4 lg:h-5 object-contain text-[#74AF4D]"
                        src={`/images/header-icons/${item.icon}`}
                      />
                    )}{" "}
                    <span className="text-xs">{item.text}</span>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Collapse>

      {location.pathname === "/" && (
        <div
          className={`bg-white shadow-sm  ${
            sidebarState && "px-[360px] "
          }    w-full flex fixed transition-all justify-between py-4 z-40 ${"mt-[70px]"} `}>
          <p className="text-green-700 ml-2 text-lg font-semibold uppercase ">
            Benguerir, morocco
          </p>
          <p className="text-sm flex gap-3 text-gray-500 divide-x-2 space-x-3 ">
            <span className="text-green-600 font-medium ">
              {data?.information?.coordinates}
            </span>
            {/* <Divider orientation="vertical" flexItem /> */}
            <span className="px-3 text-[#74AF4D] ">
              <span>{date.toLocaleDateString()}</span> <span>{time}</span>
            </span>
          </p>
        </div>
      )}
    </>
  );
}

export default Header;
