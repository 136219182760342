import {
  AdjustmentsIcon,
  BellIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import Table from "../components/Table";
import Input from "../components/Input";
import { BsAlarm } from "react-icons/bs";
import {
  useGetAlarmsQuery,
  useLazyGetAlarmsQuery,
} from "../redux/service/alarmApi";
import { useState, useMemo } from "react";
import { useLazyGetAlarmReportsDataQuery } from "../redux/service/alarmReportsApi";
import { Fade, Slide } from "@mui/material";
import SelectButton from "../components/SelectButton";
import { useEffect } from "react";
import ApexChart from "../components/ApexChart";
import millify from "millify";

const labels = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const TYPES = [
  { text: "All" },
  { text: "Communication error", key: 1 },
  { text: "Low production", key: 2 },
  { text: "No production", key: 3 },
  { text: "Inverter fault", key: 4 },
  { text: "DC side fault", key: 5 },
];

const PRIORITIES = [
  { text: "All" },
  { text: "Urgent", key: 0 },
  { text: "High", key: 1 },
  { text: "Normal", key: 2 },
  { text: "Low", key: 3 },
];

function Alarms() {
  const [start_date, setStart_date] = useState<string>("");
  const [end_date, setEnd_date] = useState<string>("");
  const [selectedType, setSelectedType] = useState(TYPES[0]);
  const [selectedPriority, setSelectedPriority] = useState(PRIORITIES[0]);

  const [page, setPage] = useState(1);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const [triggerAlarms, { data, isLoading, isUninitialized, isError }] =
    useLazyGetAlarmsQuery();

  const [trigger, { data: alarmsReports, isError: isAlarmReportsError }] =
    useLazyGetAlarmReportsDataQuery();

  useEffect(() => {
    if (isUninitialized) {
      triggerAlarms(
        {
          start_date: start_date as string,
          end_date: end_date as string,
          priority: selectedPriority.key,
          type: selectedType.key,
        },
        true
      );
      trigger(
        {
          start_date,
        },
        true
      );
    }

    // * Refresh when there is an error
  }, [isAlarmReportsError, isError]);

  useEffect(() => {
    !start_date &&
      !end_date &&
      !selectedType.key &&
      !selectedPriority.key &&
      triggerFetchFilter();
  }, [start_date, end_date, selectedType.key, selectedPriority.key]);

  const triggerFetchFilter = async () => {
    await trigger({
      start_date,
    });
    await triggerAlarms({
      start_date: start_date as string,
      end_date: end_date as string,
      priority: selectedPriority.key,
      type: selectedType.key,
    });
  };

  return (
    <div className="p-5 grid gap-4 items-start grid-cols-4 max-w-[85%] mx-auto ">
      {/* ----- Filters ------ */}
      <Slide unmountOnExit mountOnEnter in={toggleSidebar} direction="left">
        <div
          className={`bg-[#F7F9F5] space-y-4 top-0 right-0 bottom-0 px-4 py-2 h-full max-w-[350px] shadow-md border-l !fixed z-50 w-full 
          `}>
          <Card
            title={"Filters"}
            Icon={AdjustmentsIcon}
            render={() => (
              <>
                <Input
                  value={start_date}
                  setValue={setStart_date}
                  label={"Start date"}
                  type="date"
                />
                <Input
                  value={end_date}
                  setValue={setEnd_date}
                  label={"End date"}
                  type="date"
                />
                <SelectButton
                  options={PRIORITIES}
                  labelClassName="!text-gray-800"
                  selected={selectedPriority}
                  label="Priority"
                  key="key"
                  option="text"
                  setSelected={setSelectedPriority}
                  fullWidth
                />
                <SelectButton
                  options={TYPES}
                  labelClassName="!text-gray-800"
                  selected={selectedType}
                  label="Type"
                  key="key"
                  option="text"
                  setSelected={setSelectedType}
                  fullWidth
                />
                <div className="flex gap-3 flex-grow justify-between self-end align-bottom items-center ">
                  <button
                    type="button"
                    onClick={() => {
                      setStart_date("");
                      setEnd_date("");
                      setSelectedPriority(PRIORITIES[0]);
                      setSelectedType(TYPES[0]);
                    }}
                    className="text-white flex items-center bg-[#74AF4D] focus:ring-4 
                focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    <RefreshIcon className="h-5 mr-2" />
                    Reset
                  </button>
                  <button
                    type="button"
                    onClick={triggerFetchFilter}
                    className="text-white flex items-center  hover:bg-gradient-to-br focus:ring-4 
                focus:outline-none bg-[#74AF4D] font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    <RefreshIcon className="h-5 mr-2" />
                    Apply
                  </button>
                </div>
              </>
            )}
          />
        </div>
      </Slide>
      <Fade in={toggleSidebar}>
        <div
          onClick={() => setToggleSidebar(false)}
          className="fixed inset-0 z-40 transition-all duration-300 bg-black bg-opacity-0 backdrop-blur-[0] "
        />
      </Fade>
      {/* ------- ---- Alarm Summary --------- */}
      <Table
        title="Alarm Summary"
        theader={["ID", "PV system", "Date time", "Priority", "Type", "Note"]}
        tbody={data?.data ?? []}
        hideSearch
        // hideAdd
        ToggleIcon={AdjustmentsIcon}
        hideSelect
        setToggleSidebar={setToggleSidebar}
        loading={isLoading}
        className="col-span-4"
        Icon={BellIcon}
        pageCount={data?.last_page}
        page={page}
        pagination={Number(data?.total) > 1}
        setPage={setPage}
        // * render prop
        render={(item) => (
          <>
            <th
              scope="row"
              className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
              {item.id}
            </th>
            <th
              scope="row"
              className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
              {(item.pvSystemName as string) ?? "null"}
            </th>
            <th
              scope="row"
              className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
              {(item.datetime as string) ?? "null"}
            </th>
            <th
              scope="row"
              className={`px-4 py-4 font-medium 
                  text-gray-900  whitespace-nowrap`}>
              <p
                style={{
                  background:
                    item.priorityColor === "red"
                      ? "#ffe8e7"
                      : item.priorityColor === "orange"
                      ? "#fcf2d7"
                      : item.priorityColor === "yellow"
                      ? "#f7fcd7"
                      : "light" + item.priorityColor,

                  color:
                    item.priorityColor === "yellow"
                      ? "#F6BE00"
                      : "dark" + item.priorityColor,
                }}
                className="capitalize text-center  px-4 py-2 rounded-full">
                {item.priorityAlias}
              </p>
            </th>
            <th scope="row" className="px-4 py-4 font-medium text-gray-900 ">
              {item.typeAlias}
            </th>
            <th scope="row" className="px-4 py-4 font-medium text-gray-900 ">
              {item.note}
            </th>
          </>
        )}
      />
      <Card
        title="Alarm KPIs"
        Icon={BsAlarm}
        render={() => (
          <>
            <table className={`w-full pb-10 text-sm text-left text-gray-500 `}>
              <thead className="text-xs text-gray-700 uppercase bg-green-50 ">
                <tr>
                  <th scope="col" className="px-4 py-3">
                    KPI
                  </th>
                  <th scope="col" className="px-4 whitespace-nowrap py-3">
                    KPI value
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Total Alarms */}
                <tr className="bg-white border-b cursor-pointer   hover:bg-gray-50 ">
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    Total Alarms
                  </th>
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    {alarmsReports?.data.alarm_kpis.total_alarms ?? "null"}
                  </th>
                </tr>
                {/* Total duration */}
                <tr className="bg-white border-b cursor-pointer   hover:bg-gray-50 ">
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    Total duration
                  </th>
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    {millify(
                      Number(alarmsReports?.data.alarm_kpis.total_duration),
                      {
                        space: true,
                      }
                    ) ?? "null"}
                  </th>
                </tr>
                {/* Communication error */}
                <tr className="bg-white border-b cursor-pointer   hover:bg-gray-50 ">
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    Communication error
                  </th>
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    {alarmsReports?.data.alarm_kpis.communication_error ??
                      "null"}
                  </th>
                </tr>
                {/* Low production */}
                <tr className="bg-white border-b cursor-pointer   hover:bg-gray-50 ">
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    Low production
                  </th>
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    {alarmsReports?.data.alarm_kpis.low_production ?? "null"}
                  </th>
                </tr>
                {/* No production */}
                <tr className="bg-white border-b cursor-pointer   hover:bg-gray-50 ">
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    No production
                  </th>
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    {alarmsReports?.data.alarm_kpis.no_production ?? "null"}
                  </th>
                </tr>
                {/* Inverter fault */}
                <tr className="bg-white border-b cursor-pointer   hover:bg-gray-50 ">
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    Inverter fault
                  </th>
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    {alarmsReports?.data.alarm_kpis.inverter_fault ?? "null"}
                  </th>
                </tr>
                {/* DC side fault */}
                <tr className="bg-white border-b cursor-pointer   hover:bg-gray-50 ">
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    DC side fault
                  </th>
                  <th className="px-4 py-4 font-medium text-gray-900  whitespace-nowrap">
                    {alarmsReports?.data.alarm_kpis.dc_side_fault ?? "null"}
                  </th>
                </tr>
              </tbody>
            </table>
          </>
        )}
      />
      <div className="col-span-3  ">
        <Card
          title="Alarm By Hours of day "
          className=""
          Icon={AdjustmentsIcon}
          render={() => (
            <ApexChart
              height={400}
              series={[
                // @ts-ignore
                {
                  data: Object.values(alarmsReports?.data.alarm_chart ?? {}),
                  name: "value",
                },
              ]}
              type="line"
              options={{
                colors: ["#74af4d"],
                labels,
                plotOptions: {
                  pie: {
                    customScale: 0.8,
                  },
                },
              }}
            />
          )}
        />
      </div>
    </div>
  );
}

function Card({
  title,
  Icon,
  render,
  className,
}: {
  title: string;
  Icon: React.ElementType;
  render: (props?: {}) => React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={` bg-white rounded-lg border  border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 ${className} `}>
      <div className=" bg-[#74AF4D] p-4  rounded-tl-md rounded-tr-md ">
        <p className="text-white font-bold text-lg flex items-center ">
          {" "}
          {Icon && <Icon className="!h-5 mr-2" />} {title}
        </p>
      </div>
      <div className="px-3  py-3 space-y-3">{render()}</div>
    </div>
  );
}

export default Alarms;
