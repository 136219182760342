import { useState, forwardRef } from "react";
import { Collapse } from "@mui/material";

// * icons
import { InformationCircleIcon } from "@heroicons/react/outline";
import { MdDeviceThermostat } from "react-icons/md";
import { BsSun, BsWind, BsWater, BsCloudRain } from "react-icons/bs";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { CgPerformance } from "react-icons/cg";
import { useGetOveriewDataQuery } from "../redux/service/overviewApi";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { useScreenWidth } from "../utils/helpers";

const Sidebar = forwardRef<HTMLDivElement, {}>((props, ref) => {
  const [informationChecked, setInformationChecked] = useState(true);
  const [weatherInfo, setWeatherInfo] = useState(true);
  const [performenceInfo, setPerformenceInfo] = useState(true);

  // * RTK Query hooks
  const { data } = useGetOveriewDataQuery();

  const { width } = useScreenWidth();
  return (
    <div
      ref={ref}
      className={`flex-1 max-w-[350px] pb-4 ${
        width >= 2560 && "!h-screen"
      } h-[calc(100vh-70px)] top-[70px] z-40 overflow-y-scroll scrollbar-hide bg-black bg-opacity-75 fixed w-full  `}>
      {/* ---- 1st Part ----- */}
      <div
        onClick={() => setInformationChecked((p) => !p)}
        className="bg-[#74AF4D] cursor-pointer text-white flex items-center space-x-2 py-5 px-4 border-l-8 border-green-100">
        <InformationCircleIcon className="h-5" />
        <div className="flex justify-between items-center flex-1">
          <span className="uppercase">General Information</span>
          <ChevronDownIcon
            className={`h-5 text-white transition-all ${
              informationChecked && "rotate-90"
            } `}
          />
        </div>
      </div>
      <Collapse
        unmountOnExit
        mountOnEnter
        in={informationChecked}
        timeout={"auto"}>
        <div className="p-2 py-6">
          <div className="w-[180px] m-3 corner__borders ">
            <p className="uppercase text-sm text-center text-yellow-100">
              Green Energy park
            </p>
            <p className="text-lg uppercase text-center text-white ">
              PV Test Platform
            </p>
          </div>
          <div className="pl-2 text-white">
            <ul className="pr-5 space-y-2">
              <li className="flex justify-between items-baseline">
                <span className="w-52 text-left pr-10">Coordinates</span>
                <span className="flex-1 ">:</span>
                <span className="text-end text-xs leading-[5px] ">
                  {data?.information.coordinates ?? "null"}{" "}
                </span>
              </li>
              <li className="flex justify-between items-baseline">
                <span className="whitespace-nowrap w-52 pr-10">
                  Total power installed{" "}
                </span>
                <span className=" flex-1 ">:</span>
                <span className="text-end text-xs">
                  {data?.information.total_power + " kWc" ?? "null"}{" "}
                </span>
              </li>
              <li className="flex justify-between items-baseline">
                <span className="whitespace-nowrap w-52 pr-10 text-left   ">
                  Total panels{" "}
                </span>
                <span className=" flex-1 ">:</span>
                <span className="text-end text-xs">
                  {data?.information.total_pannels ?? "null"}
                </span>
              </li>
              <li className="whitespace-nowrap flex justify-between items-baseline">
                <span className="w-52 pr-10">Total Dataloggers </span>

                <span className=" flex-1 ">:</span>
                <span className="text-end text-xs">
                  {data?.information.total_dataloggers ?? "null"}
                </span>
              </li>
              <li className="flex justify-between items-baseline">
                <span className="whitespace-nowrap w-52 pr-10">
                  Total individual systems{" "}
                </span>
                <span className=" flex-1 ">:</span>
                <span className="text-end text-xs">
                  {data?.information.total_systems ?? "null"}
                </span>
              </li>
              <li className="flex justify-between items-baseline">
                <span className="whitespace-nowrap w-52 pr-10">
                  Total area{" "}
                </span>
                <span className=" flex-1 ">:</span>

                <span className="text-end text-xs">
                  {data?.information.total_area + " Ha" ?? "null"}
                </span>
              </li>
              <li className="grid grid-cols-2 justify-between items-baseline !mt-4">
                <span className="whitespace-nowrap">PV technologies : </span>
                <span className="text-xs  col-span-2">
                  {data?.information.technologies.split(",").join(", ") ??
                    "null"}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </Collapse>
      {/* -------- 2nd Part -------- */}
      <div
        onClick={() => setWeatherInfo((p) => !p)}
        className="bg-[#74AF4D] text-white cursor-pointer flex items-center space-x-2 py-5 px-4 border-l-8 border-green-100">
        <TiWeatherPartlySunny className="h-5" />
        <div className="flex justify-between items-center flex-1">
          <span className="uppercase">Weather Information</span>
          <ChevronDownIcon
            className={`h-5 text-white transition-all ${
              weatherInfo && "rotate-90"
            } `}
          />
        </div>
      </div>
      <Collapse unmountOnExit mountOnEnter in={weatherInfo} timeout={"auto"}>
        <div className="text-white">
          <div className="py-4 px-2 gap-2 flex flex-wrap">
            <div className="border border-yellow-100 p-2 w-24 px-5 grid place-items-center rounded-md">
              <MdDeviceThermostat className="text-4xl" />
              <p className="whitespace-nowrap text-sm">
                {data?.weather.air_temp + " °C" ?? "null"}{" "}
              </p>
            </div>
            <div className="border border-yellow-100 p-2 w-24  grid place-items-center px-1 rounded-md">
              <TiWeatherPartlySunny className="text-4xl" />
              <p className="whitespace-nowrap text-sm">
                {data?.weather.ghi + " w/m²" ?? "null"}{" "}
              </p>
            </div>
            <div className="border border-yellow-100 p-2 w-24  px-1 grid place-items-center rounded-md">
              <BsWind className="text-4xl" />
              <p className="whitespace-nowrap text-sm">
                {Number(data?.weather.wind_speed).toFixed(2) + " m/s" ?? "null"}{" "}
              </p>
            </div>
            <div className="border border-yellow-100 p-2 w-24  px-1 grid place-items-center rounded-md">
              <BsWater className="text-4xl" />
              <p className="whitespace-nowrap text-sm">
                {data?.weather.rh + " %" ?? "null"}
              </p>
            </div>
            <div className="border border-yellow-100 p-2 w-24  px-5 grid place-items-center rounded-md">
              <BsCloudRain className="text-4xl" />
              <p className="whitespace-nowrap text-sm">
                {data?.weather.rain + " mm" ?? "null"}{" "}
              </p>
            </div>
            <div className="border border-yellow-100 p-2 w-24 px-1  grid place-items-center rounded-md">
              <img
                className="h-[40px] object-contain "
                src="/images/overview-Icons/PressureIcon.svg"
                alt=""
              />
              <p className="whitespace-nowrap text-sm">
                {/* @ts-ignore */}
                {data?.weather.pressure + " Pa" ?? "null"}{" "}
              </p>
            </div>
          </div>
        </div>
      </Collapse>
      {/* ------ 3rd Part ------- */}
      <div
        onClick={() => setPerformenceInfo((p) => !p)}
        className="bg-[#74AF4D] text-white cursor-pointer flex items-center space-x-2 py-5 px-4 border-l-8 border-green-100">
        <CgPerformance className="h-5" />
        <div className="flex justify-between items-center flex-1">
          <span className="uppercase">Performance Indicators</span>
          <ChevronDownIcon
            className={`h-5 text-white transition-all ${
              performenceInfo && "rotate-90"
            } `}
          />
        </div>
      </div>
      <Collapse unmountOnExit mountOnEnter in={performenceInfo} timeout="auto">
        <ul className="pr-2 pl-2 py-4 space-y-2 text-white">
          <li className="flex justify-between items-center grid-cols-2 ">
            <span className="whitespace-nowrap w-56 pr-20">
              Today's PV production
            </span>
            <span className="flex-1">:</span>
            <span className="text-end text-xs">
              {data?.performance.daily_production + " MWh" ?? "null"}{" "}
            </span>
          </li>

          <li className="flex justify-between items-center grid-cols-2">
            <span className="whitespace-nowrap w-56 pr-20">
              Total PV production{" "}
            </span>
            <span className="flex-1">:</span>

            <span className="text-end text-xs whitespace-nowrap">
              {data?.performance.total_production + " GWh" ?? "null"}
            </span>
          </li>
          <li className="flex justify-between items-center">
            <span className="whitespace-nowrap w-56 pr-20">
              Total CO2 avoided
            </span>
            <span className="flex-1">:</span>

            <span className="text-end text-xs">
              {data?.performance.total_co2_avoided + " T" ?? "null"}
            </span>
          </li>
          <li className="flex justify-between items-center">
            <span className="whitespace-nowrap w-56 pr-20">
              Standard coal saved{" "}
            </span>
            <span className="flex-1">:</span>

            <span className="text-end text-xs">
              {data?.performance.standard_coal_saved + " T" ?? "null"}
            </span>
          </li>
          <li className="flex justify-between items-center ">
            <span className="whitespace-nowrap w-56 pr-20">
              Equivalent trees planted{" "}
            </span>
            <span className="flex-1">:</span>
            <span className="text-end text-xs">
              {data?.performance.trees_planted ?? "null"}
            </span>
          </li>
        </ul>
      </Collapse>
    </div>
  );
});

export default Sidebar;
