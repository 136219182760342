import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  sidebarState: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    openSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebarState = true;
    },
    closeSidebar: (state, action: PayloadAction<boolean>) => {
      state.sidebarState = false;
    },
  },
});

export const { openSidebar, closeSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
