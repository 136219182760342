import { CircularProgress } from "@mui/material";
import {
  useForm,
  SubmitHandler,
  UseFormRegister,
  Validate,
  Path,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { login } from "../redux/slices/auth";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useEffect } from "react";

interface FormValues {
  email: string;
  password: string;
}

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const dispatch = useAppDispatch();
  const { user, isLoading, isError, message } = useAppSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();

  // * Check if jwt token is valid
  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/");
    }
  }, []);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await dispatch(login(data));
    navigate("/");
  };
  return (
    <div
      style={{
        backgroundImage: "url('/images/login-bg.jpg')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      className="min-h-screen grid place-items-center">
      {/* <img
        className="h-screen hidden md:inline-flex object-cover w-[50%] "
        src="/images/login-bg.jpg"
        alt=""
      /> */}
      <div className=" flex-1 flex flex-col justify-end px-7 py-6 space-y-6 bg-gray-100/80 shadow-lg rounded-2xl">
        <img
          className="h-[60px] object-contain self-end"
          src="/images/Logo Scada/Group 2.png"
          alt=""
        />

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="px-10 py-7 flex-grow flex flex-col justify-center">
          <p className="text-sm text-gray-500">Welcome</p>
          <h2 className="text-2xl mb-4">Login to your account</h2>
          {errors && (
            <div className="py-5 max-w-3xl mx-auto text-red-600">
              <p> {errors.email && "Email is required"} </p>
              <p> {errors.password && "Password is required"} </p>
            </div>
          )}
          {isError && (
            <div className="py-5 max-w-3xl mx-auto text-red-600">
              <p>{message}</p>
            </div>
          )}
          <Input label="Email" name="email" register={register} type="text" />
          <Input
            label="Password"
            name="password"
            register={register}
            type="password"
          />
          <div className="flex items-start mb-6">
            <div className="flex items-center h-5">
              <input
                id="remember"
                type="checkbox"
                value=""
                className="w-4 h-4 bg-gray-50 rounded border border-gray-300 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
              />
            </div>
            <label
              htmlFor="remember"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Remember me
            </label>
          </div>
          <button
            type="submit"
            className={`text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 
            font-medium rounded-lg text-sm w-full sm:w-auto flex items-center !space-x-3 justify-center px-5 py-2.5 text-center ${
              isLoading && "!bg-gray-300 !text-gray-400"
            } `}>
            {isLoading && (
              <CircularProgress size={22} color="inherit" sx={{ mr: "10px" }} />
            )}
            {isLoading ? "Loading..." : "Login now"}
          </button>
        </form>
      </div>
    </div>
  );
}

function Input<T extends object>({
  register,
  label,
  type,
  name,
  validate,
}: {
  register: UseFormRegister<T>;
  label: string;
  type: string;
  name: keyof T;
  validate?: Validate<string>;
}) {
  return (
    <div className="mb-6">
      <label
        htmlFor={name as string}
        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
        {label}
      </label>
      <input
        type={type}
        id={name as string}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder=" "
        {...register(name as unknown as Path<T>, {
          required: true,
          validate: validate,
        })}
      />
    </div>
  );
}

export default Login;
