import millify from "millify";
import moment from "moment";
import { useEffect, useState } from "react";

export function useViewPort() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWidth);
    return () => window.removeEventListener("resize", handleWidth);
  }, []);

  return { width };
}

// Date.prototype.addDays = function(days) {
//   var date = new Date(this.valueOf());
//   date.setDate(date.getDate() + days);
//   return date;
// }
// new Date(new Date().setDate(currentDate.getDate() - 2))
export function getDates(startDate: Date, stopDate: Date) {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate.getTime() <= stopDate.getTime()) {
    dateArray.push(new Date(currentDate));
    currentDate = new Date(new Date().setMonth(currentDate.getMonth() + 1));
  }
  return [...dateArray].map((v) => moment(v).format("lll"));
}

export function isValidDate(date: string) {
  return isNaN(Date.parse(date as string)) ? "Non Spécifiée" : date;
}

export function checker<T>(entity: T, ifNull: any = "null"): T | string {
  return entity
    ? millify(parseFloat((entity as string).replace(/\s/g, "")))
    : ifNull ?? "null";
}
