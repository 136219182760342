import React from "react";

function Footer() {
  return (
    <div className="bg-black py-1 h-[32px] text-center text-white  ">
      @COPYRIGHT - GEPSCADA
    </div>
  );
}

export default Footer;
