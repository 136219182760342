import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

export default function Dropdown<T, K extends keyof T>({
  text,
  items,
  keyItem,
  className,
  cb,
  render,
}: {
  items?: T[];
  keyItem?: K;
  cb?: K;
  text: string | React.ReactNode;
  className?: React.HTMLAttributes<HTMLButtonElement>["className"];
  render?: (item: T) => React.ReactNode;
}) {
  return (
    <div className=" ">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={
              "inline-flex w-full justify-center rounded-md items-center border px-4 py-2 " +
              className
            }>
            {text ?? "Options"}
            <ChevronDownIcon
              className="ml-2 -mr-1 min-h-[20px] min-w-[20px] h-5 w-5 transition-all duration-500 text-[#74AF4D] "
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {items?.map((item) => (
                <Menu.Item>
                  {({ active }) => (
                    <>
                      {render ? (
                        <> {render(item)} </>
                      ) : (
                        <>
                          <button
                            onClick={item[cb as K] as unknown as () => void}
                            className={`${
                              active
                                ? "bg-green-600 text-white"
                                : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                            {keyItem
                              ? (item[keyItem] as unknown as React.ReactNode)
                              : (item as unknown as React.ReactNode)}
                          </button>
                        </>
                      )}
                    </>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
