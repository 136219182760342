import { configureStore } from "@reduxjs/toolkit";
import { alarmsApi } from "./service/alarmApi";
import { alarmsReports } from "./service/alarmReportsApi";
import { dashboardApi } from "./service/dashboardApi";
import overviewApi from "./service/overviewApi";
import reportingApi from "./service/reportingApi";
import { weatherStation } from "./service/weatherStation";
import auth from "./slices/auth";
import sidebar from "./slices/sidebar";

// * I find this structure (redux folder) is good for me .
// * Thank you for telling me about this package "apisauce" , but i don't need it for now, i will manage
// * my API calls with RTK query which is powerful fetching library, and it comes with redux .
// * i did install a lot packages when i was testing , which i don't need, thank you for reminding me to delete them

export const store = configureStore({
  reducer: {
    auth,
    sidebar,
    [overviewApi.reducerPath]: overviewApi.reducer,
    [reportingApi.reducerPath]: reportingApi.reducer,
    [alarmsApi.reducerPath]: alarmsApi.reducer,
    [weatherStation.reducerPath]: weatherStation.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [alarmsReports.reducerPath]: alarmsReports.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      overviewApi.middleware,
      reportingApi.middleware,
      alarmsApi.middleware,
      dashboardApi.middleware,
      weatherStation.middleware,
      alarmsReports.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
